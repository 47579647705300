import agent from "../agent";
import Header from "./Header";
import Footer from "./Footer";

import React from "react";
import { connect } from "react-redux";
import { APP_LOAD, REDIRECT } from "../constants/actionTypes";
import { Route, Switch } from "react-router-dom";
import Article from "../components/Article";
import Editor from "../components/Editor";
import Home from "../components/Home";
import Login from "../components/Login";
import Profile from "../components/Profile";
import ProfileFavorites from "../components/ProfileFavorites";
import Register from "../components/Register";
import Settings from "../components/Settings";
import Detail from "../components/Home/Detail";
import PostJob from "../components/PostJob";
import ProfileUpdate from "../components/ProfileUpdate/ProfileUpdate";
import ExperienceUpdateView from "../components/ProfileUpdate/ExperienceUpdateView";
import ReferenceUpdateView from "../components/ProfileUpdate/ReferenceUpdateView";
import MyProfileView from "../components/MyProfile/MyProfileView";
import ProfileEditView from "../components/MyProfile/ProfileEditView";
import ExperienceEditView from "../components/MyProfile/ExperienceEditView";
import ReferenceEditView from "../components/MyProfile/ReferenceEditView";
import MyResumeEditView from "../components/MyProfile/MyResumeEditView";
import KycLanguage from "../components/MyProfile/KycLanguage";
import KycEditView from "../components/MyProfile/KycEditView";
import ConnectList from "../components/Connect/ConnectList";
import ConnectDetail from "../components/Connect/ConnectDetail";
import MyQuotesView from "../components/MyQuotes/MyQuotesView";
import MyQuoteDetailView from "../components/MyQuotes/MyQuoteDetailView";
import MyContractsView from "../components/MyContracts/MyContractsView";
import AwardedJobDetailView from "../components/MyContracts/AwardedJobDetailView";
import EscrowStatus from "../components/MyContracts/EscrowStatus";
import RequestListView from "../components/MyContracts/RequestListView";
import MyJobsView from "../components/MyJobs/MyJobsView";
import MyJobsDetailView from "../components/MyJobs/MyJobsDetailView";
import PaymentView from "../components/payment/PaymentView";
import PaymentWithdrawView from "../components/payment/PaymentWithdrawView";
import PaymentPayoutTypeView from "../components/payment/PaymentPayoutTypeView";
import PaymentAddAccount from "../components/payment/PaymentAddAccount";
import PaymentAddUPI from "../components/payment/PaymentAddUPI";
import PaymentBankList from "../components/payment/PaymentBankList";
import PaymentUPIList from "../components/payment/PaymentUPIList";
import PaymentUpdateAccount from "../components/payment/PaymentUpdateAccount";
import PaymentUpdateUPI from "../components/payment/PaymentUpdateUPI";
import DisputesView from "../components/Disputes/DisputesView";
import BusinessView from "../components/Business/BusinessView";
import PaymentScreen from "../components/MyContracts/PaymentScreen";
import VerifyUser from "./MyProfile/VerifyUser";
import Notifications from "./Notifications/Notifications";
import UserDetail from "./Connect/UserDetail";

import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import About from "./pages/About";

import { GoogleOAuthProvider } from '@react-oauth/google';


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "../store";
import { push } from "react-router-redux";


const mapStateToProps = (state) => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT }),
});

class App extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      // this.context.router.replace(nextProps.redirectTo);
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }

  componentWillMount() {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      agent.setToken(token);
    }

    this.props.onLoad(token ? agent.Auth.current() : null, token);
  }

  render() {
    if (this.props.appLoaded) {
      return (
        <div style={{ flex: 1, flexDirection: "column" }}>
          <GoogleOAuthProvider clientId="668464403161-rrt2gf7s6qr01qrma5ma8p3095gb6tg5.apps.googleusercontent.com">

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Header
            appName={this.props.appName}
            currentUser={this.props.currentUser}
          />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/editor/:slug" component={Editor} />
            <Route path="/editor" component={Editor} />
            <Route path="/article/:id" component={Article} />
            <Route path="/settings" component={Settings} />
            <Route path="/@:username/favorites" component={ProfileFavorites} />
            <Route path="/@:username" component={Profile} />
            <Route path="/job/:id" component={Detail} />
            <Route path="/postjob" component={PostJob} />
            <Route path="/profileUpdate" component={ProfileUpdate} />
            <Route path="/ExperienceUpdate" component={ExperienceUpdateView} />
            <Route path="/referenceUpdate" component={ReferenceUpdateView} />
            <Route path="/profile" component={MyProfileView} />
            <Route path="/connect" component={ConnectList} />            
            {/* <Route path="/connect-detail" component={ConnectDetail} /> */}
            <Route path="/connect-detail" render={(props) => <ConnectDetail {...props}/>} />

            <Route path="/profileEdit" component={ProfileEditView} />
            <Route path="/experienceEdit" component={ExperienceEditView} />
            <Route path="/referenceEdit" component={ReferenceEditView} />
            <Route path="/myresumeEdit" component={MyResumeEditView} />
            <Route path="/kycLanguage" component={KycLanguage} />
            <Route path="/verifyUser" component={VerifyUser} />
            {/* <Route path="/ekycEdit" component={KycEditView} /> */}
            <Route path="/ekycEdit" render={(props) => <KycEditView {...props}/>} />     

            <Route path="/notifications" component={Notifications} />       
            
            <Route path="/myquotes" component={MyQuotesView} />
            <Route path="/myquote/:id" component={MyQuoteDetailView} />
            <Route path="/mycontracts" component={MyContractsView} />
            <Route path="/awardedjob/:id" component={AwardedJobDetailView} />
            <Route path="/escrowStatus" component={EscrowStatus} />
            <Route exact path="/user-detail/:id" render={(props) => <UserDetail {...props} /> } />
            <Route path="/requestlist/:id" component={RequestListView} />
            <Route path="/myjobs" component={MyJobsView} />
            <Route path="/myjobsdetail/:id" component={MyJobsDetailView} />
            <Route path="/payment" component={PaymentView} />
            <Route path="/withdraw" component={PaymentWithdrawView} />
            <Route path="/payout" component={PaymentPayoutTypeView} />
            <Route path="/addbank" component={PaymentAddAccount} />
            <Route path="/addUPI" component={PaymentAddUPI} />
            <Route path="/UPIList" component={PaymentUPIList} />
            <Route path="/bankList" component={PaymentBankList} />
            <Route path="/updateAccount" component={PaymentUpdateAccount} /> 
            <Route path="/updateUPI" component={PaymentUpdateUPI} />
            <Route path="/disputes" component={DisputesView} />
            <Route path="/business" component={BusinessView} />
            <Route path="/paymentScreen" component={PaymentScreen} />

            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/about" component={About} />

          </Switch>
          <Footer />
          </GoogleOAuthProvider>
        </div>
      );
    }
    return (
      <div>
        <Header
          appName={this.props.appName}
          currentUser={this.props.currentUser}
        />
      </div>
    );
  }
}

// App.contextTypes = {
//   router: PropTypes.object.isRequired
// };

export default connect(mapStateToProps, mapDispatchToProps)(App);
