import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../../constants/actionTypes";
import { JobService } from "../../../Network/services/JobService";
import  ShareJob  from "../../ShareJob";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const DetailView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [selected, setSelected] = useState("By Terms");
  const [description, setDescrption] = useState("");
  const [amount, setAmount] = useState("");
  const [scopefile, setScopefile] = useState({ });
  const [scopeValidation, setScopeValidation] = useState("");
  const [BidAmount, setBidAmount] = useState("");
  const [scope, setScope] = useState("");
  const [loading, setLoading] = useState(false);
  const [openShareJob, setOpenSharejob] = useState(false);

  const [milestoneView, setMilestoneView] = useState([]);
  const [milestoneDes, setMilestoneDes] = useState([]);
  const [milestoneAmount, setMilestoneAmount] = useState([]);
  useEffect(() => {});
  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };
  function onFilesChange(event) {
    let file = event.target.files[0];

    console.log("onfilechange    ====",event.target.files[0])
    if (file.size > 10e6) {
      //setFilevalidation("File Size issue");
      toast.success("File size must be less than 2MB", {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
      return;
    }
    setScopefile(event.target.files[0]);
  }

  const onFilesError = (error, file) => {
    setScopeValidation(error.message);
  };

  const toggleModel = () =>{
    setOpenSharejob(prevCheck => !prevCheck);
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }





  function humanFileSize(size) {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  }
  const onCompletion = (e) => {
    e.preventDefault();
    setSelected("By Completion");
  };
  const onbyTerms = (e) => {
    e.preventDefault();
    setSelected("By Terms");
  };
  const onMilestone = (e) => {
    e.preventDefault();
    setSelected("By Milestone");
  };

  const submitquote = () => {
    console.log(milestoneDes.join(","));
    console.log(milestoneAmount.join(","));
    setLoading(true);
    const form = new FormData();
    form.append("quote_id", location.state.job.id);
    form.append("job_bid_type", selected);
    form.append("bid_payment_terms", "");
    form.append(
      "milestone_descriction[]",
      selected == "By Milestone"
        ? description == ""
          ? milestoneDes.join(",")
          : description + "," + milestoneDes.join(",")
        : ""
    );
    form.append("work_scope", scope);
    form.append("amount", BidAmount);
    form.append(
      "milestone_amount[]",
      selected == "By Milestone"
        ? amount == ""
          ? milestoneAmount.join(",")
          : amount + "," + milestoneAmount.join(",")
        : ""
    );

    if (scopefile.name != null) {
      form.append("attach_portfolio", scopefile);
    }

    JobService.submitquote(form, false)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="explore explore1">
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <div onClick={back}>
                      <a href="#">
                        <i className="bx bx-chevron-left" /> Back to all jobs
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <a href="#" className="btn" onClick={(e)=>{
                    e.preventDefault();

                    toggleModel();

              }}>
                Share this Job <img src="/assets/images/share.png" />
              </a>
            </div>
          </div>
        </div>
        <div className="explore-details">
          <div className="row">
            <div className="col-md-7">
              <p className="jobid">
                Job#{" "}
                <span>
                  {location.state.job.job_unique_id.substr(
                    location.state.job.job_unique_id.indexOf(" ") + 1
                  )}
                </span>
              </p>
              <ul className="list-one">
                <li>
                  <a href="#!">
                    {location.state.job.payment_term} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!" />
                  {location.state.job.timestamp} <span>|</span>
                </li>
                <li>
                  <a href="#!">
                  Targeted close date {location.state.job.target_closed_date}{" "}
                    <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.job.total_quotes_received} Quotes Received
                  </a>
                </li>
              </ul>
              <h1 className="title">{location.state.job.job_title}</h1>
              <ul className="list-two">
                <li>
                  <a href="#!">
                    {location.state.job.job_location} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.job.payment_term} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.job.budjet}{" "}
                    <img src="/assets/images/rupee.png" />{" "}
                    <span className="amount">{location.state.job.amount}</span>
                  </a>
                </li>
              </ul>
              <ul className="category-list">
                {location.state.job.skills.split(",").map((skill) => (
                  <li>
                    <a href="#">{skill}</a>
                  </li>
                ))}
              </ul>
              <p>{location.state.job.job_description}</p>
             {location.state.job.attach_file!=null?(
                               

                  <div className="files" onClick={() => openInNewTab(location.state.job.attach_file)}>
                            <label htmlFor="file">
                              <img src="/assets/images/attachments.png" />
                             Attachement
                            </label>
                          </div> ):null}
            </div>

            <div className="col-md-5">
              <div className="sidebar">
                <LoadingOverlay>
                  <h1 className="title">Submit Quote to John Doe</h1>
                  <h6>Amount you would like to quote for this job?</h6>
                  <div className="col-md-6" style={{ paddingRight: 0 }}>
                    <input
                      type="text"
                      name
                      value={BidAmount}
                      onChange={(e) => {
                        console.log(e);
                        setBidAmount(e.target.value);
                      }}
                      placeholder="Biding Amount"
                    />
                  </div>

                  <h6>
                    Choose a Payment term{" "}
                    <img src="/assets/images/exclamation.png" />
                  </h6>
                  <ul className="payment">
                    <li>
                      <Link
                        to="#"
                        style={{
                          borderColor:
                            selected === "By Terms" ? "#F85100" : "#cbcbcb",
                        }}
                        onClick={onbyTerms}
                      >
                        <img src="/assets/images/terms.png" />
                        <span>By GigFWS Term</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        style={{
                          borderColor:
                            selected === "By Milestone" ? "#F85100" : "#cbcbcb",
                        }}
                        onClick={onMilestone}
                      >
                        <img src="/assets/images/milestone.png" />
                        <span>By Milestone</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        style={{
                          borderColor:
                            selected === "By Completion"
                              ? "#F85100"
                              : "#cbcbcb",
                        }}
                        onClick={onCompletion}
                      >
                        <img src="/assets/images/completion.png" />
                        <span>By Completion</span>
                      </Link>
                    </li>
                  </ul>
                  {selected === "By Milestone" ? (
                    <div>
                      <p>
                        You can divide the project into different small
                        milestones and paid up after completing each one of
                        them.
                      </p>
                      <div className="milestones">
                        <form>
                          <div className="row">
                            <div
                              className="col-md-6"
                              style={{ paddingRight: 0 }}
                            >
                              <input
                                type="text"
                                name
                                value={description}
                                onChange={(e) => {
                                  console.log(e);
                                  setDescrption(e.target.value);
                                }}
                                placeholder="Details about Milestone"
                              />
                            </div>
                            <div
                              className="col-md-2"
                              style={{ paddingRight: 0 }}
                            >
                              <input
                                type="text"
                                name
                                value={amount}
                                onChange={(text) => {
                                  setAmount(text.target.value);
                                }}
                                placeholder="Amount in Rupees"
                              />
                            </div>
                            <div
                              className="col-md-4"
                              style={{ paddingRight: 0 }}
                            >
                              <button
                                className="btn send"
                                style={{
                                  fontSize: 13,
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setMilestoneView([
                                    ...milestoneView,
                                    milestoneView.length,
                                  ]);
                                }}
                              >
                                Add Milestone
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {milestoneView.map((value, index) => (
                        <div className="milestones">
                          <form>
                            <div className="row">
                              <div
                                className="col-md-6"
                                style={{ paddingRight: 0 }}
                              >
                                <input
                                  type="text"
                                  name
                                  value={
                                    milestoneDes.length > index
                                      ? milestoneDes[index]
                                      : ""
                                  }
                                  onChange={(text) => {
                                    let arr = milestoneDes;
                                    // arr.splice(index,1,text)
                                    setMilestoneDes([
                                      ...milestoneDes.slice(0, index),
                                      text.target.value,
                                      ...milestoneDes.slice(index + 1),
                                    ]);
                                  }}
                                  placeholder="Details about Milestone"
                                />
                              </div>
                              <div
                                className="col-md-2"
                                style={{ paddingRight: 0 }}
                              >
                                <input
                                  type="text"
                                  name
                                  value={
                                    milestoneAmount.length > index
                                      ? milestoneAmount[index]
                                      : ""
                                  }
                                  onChange={(text) => {
                                    let arr = milestoneAmount;
                                    // arr.splice(index,1,text)
                                    setMilestoneAmount([
                                      ...milestoneAmount.slice(0, index),
                                      text.target.value,
                                      ...milestoneAmount.slice(index + 1),
                                    ]);
                                  }}
                                  placeholder="Amount in Rupees"
                                />
                              </div>
                              <div
                                className="col-md-4"
                                style={{ paddingRight: 0 }}
                              >
                                <button
                                  className="btn send"
                                  style={{
                                    fontSize: 13,
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();

                                    console.log(index);

                                    console.log(milestoneView);
                                    setMilestoneView(
                                      milestoneView.filter(
                                        (item, indexid) => indexid !== index
                                      )
                                    );
                                    setMilestoneDes(
                                      milestoneDes.filter(
                                        (item, indexid) => indexid !== index
                                      )
                                    );
                                    setMilestoneAmount(
                                      milestoneAmount.filter(
                                        (item, indexid) => indexid !== index
                                      )
                                    );
                                  }}
                                >
                                  Remove Milestone
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      ))}
                    </div>
                  ) : selected === "By Terms" ? (
                    <ul className="payment-list">
                      <li>20 % Payment @ Intial</li>
                      <li>60 % Payment @ After Service Done</li>
                      <li>20 % Payment @ After Reviewing the entire service</li>
                    </ul>
                  ) : null}
                  <form>
                    <label>Scope of Work</label>
                    <textarea
                      type="text"
                      name
                      value={scope}
                      onChange={(e) => {
                        console.log(e);
                        setScope(e.target.value);
                      }}
                      placeholder="You can ask Additional Enquiries here"
                      defaultValue={""}
                    />
                    <p>
                      Material costs, transportation and all other additional
                      resource costs may/ may not be added. Please ask any
                      questions upfront and get it clarified. You can mention
                      any of your queries in the above box.
                    </p>
                    <div className="files">
                    
                        <p className="file">
                        <label htmlFor="file">

                   <input
                     className="FileUpload1"
                     id="FileInput"
                     name="booking_attachment"
                     type="file"
                     onChange={onFilesChange}
                     onError={onFilesError}
                     accept="image/*,.pdf"
                     accepts={["image/png", "image/jpg",".pdf"]}
                     maxFileSize={1800000}
                     minFileSize={0}
                     clickable
               
                   />
                            <img src="/assets/images/attachments.png" />
                            {scopefile.name==null?"Attach your Proposal and Portfolio":scopefile.name}
                          </label>
                        </p>
                      <label className="error">{scopeValidation}</label>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-md-6 text-center">
                      <button className="btn cancel">Cancel</button>
                    </div>
                    <div className="col-md-6 text-center">
                      <button className="btn send" onClick={submitquote}>
                        Submit Quote
                      </button>
                    </div>
                  </div>
                  <Loader loading={loading} />
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareJob isOpen={openShareJob} toggle={toggleModel} jobId={location.state.job.id}/>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailView);
