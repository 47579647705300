import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { commonService } from "../../Network/services/commonService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const PaymentView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [profile, setProfile] = useState({});
  const [name, setName] = useState("");
  const [invoiceList, setIvoiceList] = useState([]);
  const [walletAmount, setWalletAmount] = useState("");

  const [loading, setLoading] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);

  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));

    getWalletAmount();
    getQuoteList();
    setName(user.first_name + " " + user.last_name);
    let personal_preference_name1_array = user.personal_preference_name1.split(
      ","
    );
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const redirect = () => {
    history.push("/ExperienceUpdate");
  };
  const getWalletAmount = () => {
    setWalletLoading(true)
    const FormData = require("form-data");

    const form = new FormData();

    commonService
      .CommonService("wallet", form)
      .then(async (res) => {
        if (res.status == 200) {
          setWalletAmount(res.wallet)
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setWalletLoading(false)
      });
  };
  const getQuoteList = () => {
    setLoading(true);

    const FormData = require("form-data");

    const form = new FormData();

    commonService
      .CommonService("withdraw_payment_history", form)
      .then(async (res) => {
        if (res.status == 200) {
          setIvoiceList(res.data);
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);

      });
  };

  
  return (
   
    <section className="post">
    <div className="container">
      <div className="banner paddimg-bottom">
        <div className="row">
          <div className="col-md-12">
            <div className="breadcrumps">
              <ul>
                <li><a href="#" onClick={(e)=>{
                   e.preventDefault();
                   history.goBack();
                }}><i className="bx bx-chevron-left" /> Back to all jobs</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="quotes payments">
        <h1 className="title">Payments</h1>
        {/*----------------------- List Start  ---------------------*/}
        <div className="post-list earn">
          <div className="row">
            <div className="col-md-3 text-center">
              <img src="assets/images/payment.png" />
            </div>
            <div className="col-md-6 paddimg-top-20">
              <h5>Earnings</h5>
              {walletLoading?<Loader loading={walletLoading}/>:<h1><img src="assets/images/rupee-blue.png" className="rupee" /> {walletAmount}</h1>}
              
              
              <p>as on {(new Date().toLocaleString() + "")}</p>
            </div>
            <div className="col-md-3 text-center" onClick={()=>{
               history.push("/withdraw");
            }}>
              <button>Get paid now</button>
            </div>
          </div>
        </div>
        {/*----------------------- List End  ---------------------*/}
        <h1 className="title">Invoices</h1>
        {/*----------------------- List Start  ---------------------*/}
        <LoadingOverlay>
                  {invoiceList.map((item) => (
        <div className="post-list invoices">
          <div className="count">
            <ul>
              <li><img src="assets/images/job-id.png" className="job-id" /> <span>{item.invoice_id}</span></li>
              {/* <li className="line"><span>{item.invoice_id}</span></li> */}
            </ul>
          </div>
          <h1> {item.job_title}</h1>
          <div className="details">
            <ul>
              <li className="font-normal">Amount <img src="assets/images/rupee.png" className="rupee" /> <span>{item.amount}</span></li>
              <li className="line font-normal">Paid on  <span>{item.created_at}</span></li>
              <li className="eidt"><i className="fa fa-download" aria-hidden="true" /> 
              
<a className="message" href = {item.pdf} target = "_blank">Download Pdf</a>
</li>
            </ul>
          </div>
        </div>
                          ))}
                                            <Loader loading={loading} />

</LoadingOverlay>
       
      </div>
    </div>
  </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentView);
