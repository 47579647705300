import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { profileUpdateService } from "../../Network/services/profileUpdateService";

import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  // onTabClick: (tab, pager, payload) =>
  //   dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}
const ExperienceUpdateView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  const forceUpdate = useForceUpdate();
  console.log("props++++++++++++++++++++++", location);
  const [isYes, setIsYes] = useState(true);

  const [experienceView, setExperienceView] = useState([]);
  const [expyear, setExpyear] = useState([]);
  const [expcompany, setExpcompany] = useState([]);
  const [expfrom, setExpfrom] = useState([]);
  const [expto, setExpto] = useState([]);
  const [expyear1, setExpyear1] = useState("");
  const [expcompany1, setExpcompany1] = useState("");
  const [expfrom1, setExpfrom1] = useState(null);
  const [expto1, setExpto1] = useState(null);
  const [readforproject, setReadforproject] = useState(null);
  const [projectrate, setProjectrate] = useState("");
  const [projectratetype, setProjectratetype] = useState("Hourly Rate");
  const [toyearValidation, setToyearValidation] = useState("");
  const [fromyearValidation, setFromyearValidation] = useState("");
  const [companyValidation, setCompanyValidation] = useState("");
  const [totalyearValidation, setTotalyearValidation] = useState("");
  const [projectrateValidation, setProjectrateValidation] = useState("");
  const [conditions, setAdditionalConditions] = useState("");

  const [pickerValue, setPickerValue] = useState(-1);
  const [isFrom, setIsFrom] = useState(true);

  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);
    setFirstname(user.first_name);
    setLastname(user.last_name);
    setName(user.first_name + " " + user.last_name);
    setExpyear1(user.total_experience);
    setExpfrom1(
      user.working_from_date.split(",")[0] == ""
        ? null
        : moment(user.working_from_date.split(",")[0], "MM-DD-yyyy").toDate()
    );
    setExpto1(
      user.working_to_date.split(",")[0] == ""
        ? null
        : moment(user.working_to_date.split(",")[0], "MM-DD-yyyy").toDate()
    );
    setExpcompany1(user.working_company_name.split(",")[0]);
    setExpcompany(user.working_company_name.split(",").slice(1));
    setExperienceView(user.working_company_name.split(",").slice(1));
    const setexpfromNew = user.working_from_date
      .split(",")
      .slice(1)
      .map((answer) => {
        return moment(answer, "MM-DD-yyyy").toDate();
      });
    const setexptoNew = user.working_to_date
      .split(",")
      .slice(1)
      .map((answer) => {
        return moment(answer, "MM-DD-yyyy").toDate();
      });
    setExpfrom(setexpfromNew);
    setExpto(setexptoNew);
    setProjectrate(user.project_rate);
    setProjectratetype(user.project_rate_type);
    setReadforproject(
      user.ready_for_project == "No"
        ? null
        : moment(user.ready_for_project, "MM-DD-yyyy").toDate()
    );
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const resetValidation = () => {
    setTotalyearValidation("");
    setCompanyValidation("");
    setFromyearValidation("");
    setToyearValidation("");
    setProjectrateValidation("");
  };
  expfrom.map((item) => {
    console.log("experienceView++++++++++++++++++++" + item);
  });
  console.log("expfrom lenght++++++++++++++++++++" + expfrom.length);

  const profileupdate = () => {
    resetValidation();
    if (expyear1 == "") {
      setTotalyearValidation("Please enter Total year");
      toast.success("Please enter Total year", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });

      return;
    }
    if (expcompany1 == "") {
      setCompanyValidation("Please enter Company Name");
      toast.success("Please enter Company Name", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }

    if (expfrom1 == "") {
      setFromyearValidation("Please enter From year");
      toast.success("Please enter From year", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }

    if (expto1 == "") {
      setToyearValidation("Please enter To year");
      toast.success("Please enter To year", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
    if (projectrate == "") {
      setProjectrateValidation("Please enter projectrate amount");
      toast.success("Please enter projectrate amount", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }

    const expfromNew = expfrom.map((answer) => {
      return moment(answer).format("MM-DD-yyyy");
    });
    const exptoNew = expto.map((answer) => {
      return moment(answer).format("MM-DD-yyyy");
    });
    console.log("exptoNew", exptoNew);

    let expcompanyString = expcompany.map(({ value }) => `${value}`);
    let expyearString = expyear.map(({ value }) => `${value}`);
    let expfromString = expfromNew.map(({ value }) => `${value}`);
    let exptoString = exptoNew.map(({ value }) => `${value}`);

    if (expcompany.length > 0) {
      console.log(expcompany1 + "," + expcompany.join(","));
      expcompanyString = expcompany1 + "," + expcompany.join(",");
      expyearString = expyear1;
      expfromString =
        moment(expfrom1).format("MM-DD-yyyy") + "," + expfromNew.join(",");
      exptoString =
        moment(new Date(expto1)).format("MM-DD-yyyy") +
        "," +
        exptoNew.join(",");
    } else {
      console.log(expcompany1);
      expcompanyString = expcompany1;
      expyearString = expyear1;
      expfromString = moment(new Date(expfrom1)).format("MM-DD-yyyy");
      exptoString = moment(new Date(expto1)).format("MM-DD-yyyy");
    }
    console.log("expcompanyString", expcompanyString);

    const FormData = require("form-data");

    const form = new FormData();
    setLoading(true);

    form.append("total_experience[]", expyearString);
    form.append("working_company_name[]", expcompanyString);
    form.append("working_from_date[]", expfromString);
    form.append("working_to_date[]", exptoString);

    form.append(
      "ready_for_project",
      isYes ? moment(readforproject).format("MM-DD-yyyy") : "No"
    );
    form.append("project_rate", projectrate);
    form.append("project_rate_type", projectratetype);
    form.append("additional_condition", conditions);

    console.log(
      "readforproject....",
      moment(readforproject).format("MM-DD-yyyy")
    );
    console.log("working_from_date....", expfromString);
    console.log("working_to_date....", exptoString);

    profileUpdateService
      .profileUpdatecall(form)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
          window.localStorage.setItem("auth_token", res.data[0].auth_token);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          redirect();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const redirect = () => {
    history.push("/referenceUpdate");
  };
  const addexp = (index) => {
    resetValidation();
    if (expyear1 == "") {
      setTotalyearValidation("Please enter Total year");
      return;
    }
    if (expcompany1 == "") {
      setCompanyValidation("Please enter Company Name");
      return;
    }

    if (expfrom1 == "") {
      setFromyearValidation("Please enter From year");
      return;
    }

    if (expto1 == "") {
      setToyearValidation("Please enter To year");
      return;
    }

    setExperienceView([...experienceView, { id: index }]);
  };
  // const addexpyear = (index, text) => {
  //   expyear[index] = {...expyear[index], id: index, value: text};
  //   setExpyear(expyear);
  // };
  const addexpcompany = (index, text) => {
    console.log("addexpcompany  index", index);
    console.log("addexpcompany  text", text);

    expcompany[index] = text;
    setExpcompany(expcompany);
    console.log("addexpcompany     ", expcompany[index]);
  };
  const addexpfrom = (index, text) => {
    console.log("addexpfrom  index", index);
    console.log("addexpfrom  text", text);
    expfrom[index] = text;
    setExpfrom(expfrom);
    forceUpdate();
  };
  const addexpto = (index, text) => {
    expto[index] = text;
    setExpto(expto);
    forceUpdate();
  };

  const removeExp = (value, index) => {
    console.log("value.id" + value.id);
    console.log("index" + index);
    console.log("experienceView.length" + experienceView.length);

    setExperienceView(
      experienceView.filter((item, index1) => index1 !== index)
    );
    // setExpyear(
    //   expyear.filter((item) => item.id !== value.id),
    // );
    setExpcompany(expcompany.filter((item, index1) => index1 !== index));
    setExpfrom(expfrom.filter((item, index1) => index1 !== index));
    setExpto(expto.filter((item, index1) => index1 !== index));
  };

  return (
    <section className="explore explore1">
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="#!">
                      <i className="bx bx-chevron-left" /> Back to all jobs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <a href="#!" className="btn">
                Share this Job <img src="assets/images/share.png" />
              </a>
            </div>
          </div>
        </div>
        <section className="onbord-section">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="title">
                John Doe George,tell us a bit more about yourself
              </h1>
              <p className="subtitle">
                Future Workforce Solutions needs the below information to help
                you better.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="board-box board-box1">
                <div className="one">
                  <div className="row">
                    <div className="col-md-12">
                      <p>Tell us about your Experience</p>
                      <div className="form-group">
                        <label>Total Experience</label>
                        <input
                          type="text"
                          name
                          className="form-control"
                          required="required"
                          placeholder="Total Experience"
                          value={expyear1}
                          onChange={(e) => {
                            setExpyear1(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Company</label>
                        <input
                          type="text"
                          name
                          className="form-control"
                          required="required"
                          placeholder="Company"
                          value={expcompany1}
                          onChange={(e) => {
                            setExpcompany1(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>From</label>

                        <DatePicker
                          dateFormat="MM-dd-yyyy"
                          className="form-control"
                          selected={expfrom1}
                          onChange={(date) => setExpfrom1(date)}
                          placeholderText="From"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>To</label>
                        <DatePicker
                          dateFormat="MM-dd-yyyy"
                          className="form-control"
                          selected={expto1}
                          onChange={(date) => setExpto1(date)}
                          placeholderText="From"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button onClick={() => addexp(experienceView.length)}>
                      Add Experience
                    </button>
                  </div>
                </div>
                <hr />

                {experienceView.map((value, index) => (
                  <div>
                    <div className="two">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name
                              className="form-control"
                              required="required"
                              placeholder="Company"
                              value={expcompany[index]}
                              onChange={(e) => {
                                addexpcompany(index, e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <DatePicker
                              dateFormat="MM-dd-yyyy"
                              className="form-control"
                              selected={
                                typeof expfrom[index] != "undefined"
                                  ? expfrom[index]
                                  : null
                              }
                              onChange={(date) => addexpfrom(index, date)}
                              placeholderText="From"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <DatePicker
                              dateFormat="MM-dd-yyyy"
                              className="form-control"
                              selected={
                                typeof expto[index] != "undefined"
                                  ? moment(expto[index], "MM-dd-yyyy").toDate()
                                  : null
                              }
                              onChange={(date) => addexpto(index, date)}
                              placeholderText="To"
                            />
                          </div>
                        </div>
                      </div>
                      <button onClick={() => removeExp(value, index)}>
                        Remove Experience
                      </button>
                    </div>
                    <hr />
                  </div>
                ))}
                <div className="three">
                  <div className="row">
                    <div className="col-md-12">
                      <p>Ready for Projects?</p>
                    </div>
                    <div className="col-md-12">
                      <button
                        className={`btn ${isYes ? "yes" : null}`}
                        onClick={() => setIsYes(true)}
                      >
                        Yes
                      </button>
                      <button
                        className={`btn ${isYes ? null : "yes"}`}
                        onClick={() => setIsYes(false)}
                      >
                        No
                      </button>
                    </div>
                    {isYes ? (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>If yes,</label>

                          <DatePicker
                            dateFormat="MM-dd-yyyy"
                            className="form-control"
                            selected={readforproject}
                            onChange={(date) => setReadforproject(date)}
                            placeholderText="When"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <hr />
                <div className="four">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <p>Rate</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>₹ INR</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          name
                          className="form-control"
                          required="required"
                          placeholder="Amount"
                          value={projectrate}
                          onChange={(e) => {
                            setProjectrate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Select
                          className="form-control"
                          style={{ display: "flex" }}
                          options={[
                            {
                              label: "Hourly Rate",
                              value: "Hourly Rate",
                            },
                            {
                              label: "Day Rate",
                              value: "Day Rate",
                            },
                            { label: "Weekly Rate", value: "Weekly Rate" },
                            {
                              label: "Fixed Charges",
                              value: "Fixed Charges",
                            },
                          ]}
                          placeholder={projectratetype}
                          onChange={(values) => {
                            console.log(values);
                            setProjectratetype(values[0].value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="textarea">About Yourself</label>
                        <textarea
                          name
                          className="form-control"
                          placeholder="Provide more details about the Job."
                          defaultValue={""}
                          value={conditions}
                          onChange={(e) => {
                            setAdditionalConditions(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button onClick={profileupdate}>Update</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExperienceUpdateView);
