import { addToken } from "../APIKit";
import { API_URL } from "../constants";
import APIKit from "../APIKit";

function profileUpdatecall(payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }
    APIKit.post("/update_profile", payload, {
      headers: {
        "content-type": "multipart/form-data", // do not forget this
      },
    })
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err======111=====" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err=====222======" + err);

        reject(err);
      });
  });
}

function addCertificate(payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }
    APIKit.post("/add_certificates", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function changepasswordCall(payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

    APIKit.post("/change_password", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function removeCertificate(payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

    APIKit.post("/remove_certificates", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function kyc(payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

    APIKit.post("/upload_id_proof", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function kycPanGstUpload(payload,idprooftype) {
  console.log("payload==========" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

    let kycurl = "/kyc_verification";
    if(idprooftype==3){
      //kycurl = "/generate_aadhar_verify_url"; //old one
      kycurl = "/aadhar_verify"; //new one
    }
    

    APIKit.post(kycurl, payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function verifyKycStatus(payload,idprooftype) {
  console.log("payload==========" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

    let kycurl = "/verify_aadhar_status";
   
    APIKit.post(kycurl, payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function kycAadharCaptcha(payload) {
  console.log("payload==========" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }
    
    APIKit.post("/aadhar_captcha", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function kycAadharVerifyOTP(payload) {
  console.log("payload==========" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

   
    let kycurl = "/aadhar_verify_otp";
    

    APIKit.post(kycurl, payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function kycSampleVideo(payload) {
  console.log("payload==========" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }
    
    APIKit.post("/get_sample_kyc_videos", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function emailPhone(payload,api_url) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

    APIKit.post(api_url, payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function profile(payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

    APIKit.post("/user_detailed_history", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function profileData(payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

    APIKit.post("/profile_list", payload, {
      headers: { "content-type": "multipart/form-data" },
    })
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

export const profileUpdateService = {
  profileUpdatecall,
  changepasswordCall,
  addCertificate,
  removeCertificate,
  kyc,
  kycPanGstUpload,
  verifyKycStatus,
  kycAadharVerifyOTP,
  kycSampleVideo,
  kycAadharCaptcha,
  emailPhone,
  profile,
  profileData,
};
