import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { getNotificationReadStatus } from "../../Network/services/notificationInfoService";

//import images and css
import "../../assets/css/alert.css";

const NotificationBtn = () => {

    //notification read status count
    const [notificationReadStatusCount, setNotificationReadStatusCount] = useState(0);
    const [notificationsCount, setNotificationsCount] = useState(0);

    useEffect(() => {        
    
        //get notification status 
        const notification_read_status = new FormData();      
        getNotificationReadStatus(notification_read_status)
        .then(async (res) => {
        if (res.status == 200) {
            console.log("result status:"+res.status);

            setNotificationReadStatusCount(res.read_status)
            setNotificationsCount(res.notification_count)
            console.log(res.read_status)
            console.log(res.notification_count)         
            
            // toast.success(res.message, {
            //   position: "top-right",
            //   type: "success",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: 0,
            // });
            //history.goBack();
        } else {
            toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            });
        }
        })
        .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
        });
        })
        .finally(() => {
        // setLoading(false);
        });
    
      }, []);

    return(
        <span className="notify-icon">
            <Link className="btn btn-default" to="/notifications">
                <i class="fa fa-bell" aria-hidden="true"></i> 
                <sup>{notificationsCount}</sup>
            </Link>
        </span>
    )

}

export default NotificationBtn;

