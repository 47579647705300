import React from "react";


const customStyles = {
  fontWeight: "400"
};
const PrivacyPolicy = () => {
    return (        
      <section class="trems-sec">
	      <div class="container">
		      <h3>GigFWS (FUTUREWORKFORCE SOLUTIONS) PRIVACY POLICY</h3>

          <h4>About GigFWS (FUTUREWORKFORCE SOLUTIONS)</h4>

		      <span style={customStyles}>"GigFWS" in this privacy policy statement refers to FUTUREWORKFORCE SOLUTIONS LLP (“FWS”). GigFWS is a limited liability partnership firm.</span>

		<h4>The Human Resource Freelance Marketplace as an Application on the Web, Playstore (Android) and App store (iOS)</h4>

		<span style={customStyles}>These terms apply to your use of the GigFWS Web application (<a href="http://www.gigfws.com"></a>) and GigFWS applications on play store (For android) and app store (For iOS)</span>

<h4>Purpose</h4>

<span style={customStyles}>GigFWS is committed to respect and protect your privacy. GigFWS value you as an esteemed customer and take your personal privacy very seriously. In the course of registration with us, we may collect certain personal information that we understand may be considered confidential. </span>

<span style={customStyles}>This Privacy Policy is intended to describe the practices which GigFWS follows in relation to the GigFWS application on web (<a href="http://www.gigfws.com">www.gigfws.com</a>) and on play store (Android app store) and on App store (iOS app store) with respect to the privacy of all individuals (here in refers to all GigFWS registered users) whose personal data is processed and stored in the application.</span>

<h4>Who manages the Application and Data?</h4>

<span style={customStyles}>The entity that is acting as data controller by providing this application on which your personal data will be processed and stored is FUTUREWORKFORCE SOLUTIONS LLP.</span>

<span style={customStyles}>The System/ application will be hosted on a Digital Ocean server that is located in India.</span>

<h4>Why do GigFWS need your information?</h4>

<span style={customStyles}>The purpose of the application is to help GigFWS users to connect with potential gig workers and gig opportunities. </span>

<span style={customStyles}>Your personal data will be used to assess, validate the user (YOU) and onboard YOU into GigFWS platform to continuously support YOU with the best suitable opportunities.</span>

<span style={customStyles}>GigFWS relies on the following basis to legitimize the processing of your personal data in the application: processing is necessary for the purposes of the legitimate interests pursued by the data controller or by a third party (agreed by GigFWS), except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data. The specific legitimate interests pursued are freelancing opportunities and create gig platform</span>

<span style={customStyles}>The provision of your personal data to GigFWS is optional, however, please be aware that if you do not provide us with all or part of your personal data, we may not be able to help/ support you with FWS’s full features of the application.</span>

<h4>Type of the data processed in the application</h4>


<span style={customStyles}>The application processes the personal information of the users (freelancer/ gigworkers) who upload their resumes into the application, including: name (first, last); email address; phone number; address; photo (optional); education details; previous occupation; previous employer; skills; desired rate/fee; certifications and awards credentials; current location; work location preference; availability for next role; Indian Govt. ID proofs for the purpose of user validation and e-kyc, Bank account details for financial transactions (such as credit card, debit card or other financial account information, and billing address - The information is collected via authorized payment gateways and e-wallets), Additional service-related notes/ fees (which may be populated by GigFWS); video (mostly recorded); Reference details for personal and professional (details of other persons (may or may not the GigFWS users), Demographic information and any other information which the users provide voluntarily for the purposes of an application.</span>


<span style={customStyles}>General Audience: GigFWS Service is for general audience and intended for users aged 18 and above. We do not knowingly collect Personal Information from anyone younger than age 18. If we become aware that a user is younger than 18 and has provided us with Personal Information, we will take reasonable efforts to delete any such information from our records.</span>


<span style={customStyles}>Data from Social networks: When you register through your social network account (Facebook or Google), we access to basic information from your social network account, such as your name, birthdate, profile picture and friends' list, as well as information you made publicly available on such account.</span>

<h4>Sensitive Data</h4>


<span style={customStyles}>GigFWS only collects the sensitive personal data (Personal ID proof like Aadhaar) and processed in the application/ secured third party tool for the purpose of user validation and to check/ confirm their personal identity.</span>


<span style={customStyles}>GigFWS is not collecting any other specific sensitive data directly. However, the user may share their data (in some cases) to the job poster in order to stand out from the crowd and showcase his/her job profile. </span>


<span style={customStyles}>Sensitive personal data is data revealing your age, gender, racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, data concerning health or data concerning sex life or sexual orientation.</span>


<span style={customStyles}>GigFWS recommends that it’s users should not include any other sensitive personal data until otherwise it is highly required for the purpose of the application only.</span>

<h4>Who can access your data?</h4>


<span style={customStyles}>Your data can be accessed by the GigFWS admin users for the user validation purpose, GigFWS users who posts a job and evaluate your biddings (if you submit), GigFWS users who would like to connect with you, Third party services who has signed the contract with GigFWS for user background checks, Application development team, Dispute resolutions team and by you - you will be able to access your own information.</span>

<span style={customStyles}>GigFWS may disclose your data in order to comply with our legal, regulatory obligations or professional standards, in response to a non-mandatory request for information made by a governmental or state body, or as part of an investigation of unlawful activity.</span>

<h4>Data Retention</h4>


<span style={customStyles}>All data submitted in the application, unless deleted earlier at written request, will be retained for a period expiring on the later to occur of: (a) 5 years following the expiration of the term of your gig role on the application, and (b) 5 years following the last date of activity related to your account on the application. </span>

<span style={customStyles}>Please email us at </span><a href="mailto:support@gigfws.com"><span style={customStyles}>support@gigfws.com</span></a><span style={customStyles}>,</span><span style={customStyles}> if you want your personal data to be deleted from the application. GigFWS will comply with your request without undue delay, unless we have a legal or regulatory reason to keep your personal data.</span>



<span style={customStyles}>After the end of the data retention period specified in the policies and/or procedures set out at above with GigFWS, your personal data will be deleted.</span>

<h4>Security</h4>


<span style={customStyles}>GigFWS is highly committed to making sure that your personal data is kept secure. In order to prevent unauthorized access or disclosure at any given point of time.</span>


<h4>Rectification, erasure or restriction of processing</h4>


<span style={customStyles}>GigFWS supports you with the ability to make sure your personal data is accurate, correct and up to date. You can request rectification, erasure or restriction of processing of your personal data by sending an e-mail to </span><a href="mailto:support@gigfws.com"><span style={customStyles}>support@gigfws.com</span></a><span style={customStyles}> </span>

<h4>Testimonials</h4>


<span style={customStyles}>GigFWS display personal testimonials of satisfied customers on our Services, in addition to other endorsements. With your consent GigFWS may post your testimonial along with your name on GigFWS application and on GigFWS official social media profiles. If you wish to update or delete your testimonial, you can contact us at </span><a href="mailto:support@gigfws.com"><span style={customStyles}>support@gigfws.com</span></a>


<h4>Job contracts listings</h4>


<span style={customStyles}>If you choose to post a job contract on GigFWS application as a user (client), the contents of such contract will be viewable publicly. Job contract includes information such as budget, location, expected timeline, nature of the job, feedback and rating information</span>

<h4>Consent</h4>

<ul>
 	<li style={customStyles} aria-level="1"><span style={customStyles}>By using GigFWS application, it is understood that you have given your consent to the collection and use of this information by GigFWS and its partners, affiliates and third Parties (Only those who have signed a contract with GigFWS)</span></li>
 	<li style={customStyles} aria-level="1"><span style={customStyles}>The USER hereby gives Consent to GigFWS to have all access to the Data, Statistics, Contents, Correspondences and materials shared by USER to any other person, in case, GigFWS apprehends or notice any breach or violation of any terms and condition or privacy policy or any activity in violation of any corresponding law</span></li>
 	<li style={customStyles} aria-level="1"><span style={customStyles}>GigFWS use third party payment gateway and e-wallets to process deposit and withdrawals and related transactions. By accepting this Privacy Policy, You clearly agree to use your Personal Information where necessary for the processing of transactions. GigFWS take steps to ensure that our contracts, engagements with these parties safeguard your privacy</span></li>
 	<li style={customStyles} aria-level="1"><span style={customStyles}>By filling your details with us, you give us consent to use communicate with you via email and mobile at any time for information, update on promotions, news &amp; events conducting on at GigFWS and partners</span></li>
 	<li style={customStyles} aria-level="1"><span style={customStyles}>GigFWS at any point of time can review or authenticate your identity for security measures and conduct a validation check by using your personal information</span></li>
</ul>
<span style={customStyles}>GigFWS India may update this Privacy Policy at any time and any changes will be effective upon posting.  </span>

<span style={customStyles}>Your use of our application both on web, Android and iOS constitutes that you accept the terms &amp; conditions of our website &amp; agree to the Privacy policy.</span>

<h4>Complaints and contact</h4>

<span style={customStyles}>For any complaints and concerns, reach out to us at </span><a href="mailto:support@gigfws.com"><span style={customStyles}>support@gigfws.com</span></a>


	</div> 
  </section>


      );
}

export default PrivacyPolicy;

