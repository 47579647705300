import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { getNotificationList, clearNotifications } from "../../Network/services/notificationInfoService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";

import Modal from "react-modal";

//import images and css
import "../../assets/css/alert.css";
import alertIcon from "../../assets/images/aler-tcon.png";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const NotificationList = (props) => {
    
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [notificationList, setNotificationList] = useState("");
  
  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0)
    console.log(      
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);
    
    const form = new FormData();

    getNotificationList(form)
    .then(async (res) => {
      if (res.status == 200) {
        setNotificationList(res.data)
        console.log(res.data)
      } else {
        
      }
    })
    .catch((err) => {
      toast.success("Something went wrong! please try again later!!", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    })
    .finally(() => {
      setLoading(false);
    });
 
  }, []);


  //clear notifications
  const clearNotification = (e) =>{
    e.preventDefault();
    setLoading(true);
         
    const clear_noti_form = new FormData();        
    clearNotifications(clear_noti_form)
    .then(async (res) => {
      if (res.status == 200) {
        console.log("result status:"+res.status);

        setNotificationList('')        
        
        // toast.success(res.message, {
        //   position: "top-right",
        //   type: "success",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: 0,
        // });
        window.location.reload(false);
        //history.goBack();
      } else {
        toast.success(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      }
    })
    .catch((err) => {
      toast.success("Something went wrong! please try again later!!", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    })
    .finally(() => {
      setLoading(false);
    });


  }

    
  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  let connectDetail = (userConnectInfo) =>{
    history.push({ 
      pathname: '/connect-detail',
      state: { userConnectInfo: userConnectInfo }
     });
  }
 
  return (
    <section className="explore explore1">
    <LoadingOverlay>
      <div className="container">

        <div className="col-md-12 alert-clear-all">
            <h5><span style={{cursor:"pointer"}} onClick={clearNotification}>clear all</span></h5>
        </div>

        {notificationList ?
            notificationList.map((post) => (

                <div key={post.id} className={"alert-box "+ (post.read_status==0 ? 'read' : '')}>
                    <div className="row">
                        <div className="col-md-1">
                            <img src={alertIcon} />
                        </div>
                        <div className="col-md-11">
                            <h3>{post.type} </h3>
                            <h6>Dear {name},</h6>
                            <p>{post.notification}</p>
                            <h5>{post.created}</h5>

                        </div>
                    </div>
                </div>            
        )) : ( <p className="text-center alert alert-warning">There are no notifictions to display!</p> )}

       
      </div>
      <Loader loading={loading} />
    </LoadingOverlay>
    
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
