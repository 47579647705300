import {
  LOGIN,
  REGISTER,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  ASYNC_START,
  ASYNC_END,
  UPDATE_FIELD_AUTH,
} from "../constants/actionTypes";

export default (state = { errorMessage: "", isSuccess: false }, action) => {
  switch (action.type) {
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null,
      };
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      if (action.subtype === LOGIN || action.subtype === REGISTER) {
        console.log("async started++++++++++++++++++++");
        return { ...state, inProgress: true };
      }
      break;
    case ASYNC_END:
      if (action.subtype === LOGIN || action.subtype === REGISTER) {
        console.log("async ended++++++++++++++++++++", action);
        if (action.data.status == 200) {
          return {
            ...state,
            inProgress: false,
            isSuccess: true,
            errorMessage: action.data.message,
          };
        } else {
          return {
            ...state,
            inProgress: false,
            isSuccess: true,
            errorMessage: action.data.message,
          };
        }
      }
      break;
    case UPDATE_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};
