import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { JobService } from "../../Network/services/JobService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MyQuoteDetailView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [selected, setSelected] = useState(location.state.item.payment_terms);
  const [description, setDescrption] = useState("");
  const [amount, setAmount] = useState("");
  const [scopefile, setScopefile] = useState({  });
  const [scopeValidation, setScopeValidation] = useState("");
  const [BidAmount, setBidAmount] = useState(location.state.item.bid_amount);
  const [scope, setScope] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isCancelModelopen, setIsCancelModelopen] = useState(false);
  const [comment, setComment] = useState("");

  const [milestoneView, setMilestoneView] = useState([]);
  const [milestoneDes, setMilestoneDes] = useState([]);
  const [milestoneAmount, setMilestoneAmount] = useState([]);
  const [addedscope, setAddedscope] = useState("");
  const [attachement, setAttachement] = useState({});

  useEffect(() => {
    if (location.state.item.payment_milestone != null) {
      if (location.state.item.payment_milestone.length > 0) {
        setDescrption(
          location.state.item.payment_milestone[0].milestone_descriction
        );
        //setMilestone(item.payment_milestone.slice(1));
        setMilestoneView(location.state.item.payment_milestone.slice(1));
        setAmount(location.state.item.payment_milestone[0].milestone_amount);
        // setMilestoneAmount(item.payment_milestone.slice(1));
        // item.payment_milestone.slice(1).map(function (item) {
        //   setMilestone([...milestone, item.milestone_descriction]);
        //   setMilestoneAmount([...milestoneAmount, item.milestone_amount]);
        // });

        location.state.item.payment_milestone.slice(1).forEach((item, i) => {
          console.log("loop", item);
          setMilestoneDes((milestone) => [
            ...milestone,
            item.milestone_descriction,
          ]);
          setMilestoneAmount((milestoneAmount) => [
            ...milestoneAmount,
            item.milestone_amount,
          ]);
        });
      }
    }
    if (location.state.item.work_scope != null) {
      setScope(location.state.item.work_scope);
    }
    if (location.state.item.attach_portfolio != null) {
      setAddedscope(location.state.item.attach_portfolio);
    }
  }, []);
  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };
  // const onFilesChange = (files) => {
  //   let file = files[0];

  //   setScopefile({
  //     name: file.name,
  //     uri: file.preview.url,
  //     type: file.type,
  //   });
  // };

  let cancelModelClose = () => {
    setIsCancelModelopen(false);
  };


  function onFilesChange(event) {
    let file = event.target.files[0];

    console.log("onfilechange    ====",event.target.files[0])
    if (file.size > 10e6) {
      //setFilevalidation("File Size issue");
      toast.success("File size must be less than 2MB", {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
      return;
    }
    setScopefile(event.target.files[0]);
  }

 
  const onFilesError = (error, file) => {
    console.log("file")

    setScopeValidation(error.message);
  };
  function humanFileSize(size) {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  }
  const onCompletion = (e) => {
    e.preventDefault();
    setSelected("By Completion");
  };
  const onbyTerms = (e) => {
    e.preventDefault();
    setSelected("By Terms");
  };
  const onMilestone = (e) => {
    e.preventDefault();
    setSelected("By Milestone");
  };
  const cancelClick = (e) => {
    e.preventDefault();
    setIsCancelModelopen(true);
  };


  const cancelQuote = () => {
  
    setLoading(true);
    const form = new FormData();
    form.append("quote_id", location.state.item.quote_id);
    form.append("cancel_comment", comment);
    

    JobService.cancelQuote(form, true)
      .then(async (res) => {
        if (res.status == 200) {
          history.goBack();
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          history.goBack();

          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };



  const submitquote = () => {
    console.log(milestoneDes.join(","));
    console.log(milestoneAmount.join(","));
    setLoading(true);
    const form = new FormData();
    form.append("quote_id", location.state.item.quote_id);
    form.append("job_bid_type", selected);
    form.append("bid_payment_terms", "");
    form.append(
      "milestone_descriction[]",
      selected == "By Milestone"
        ? description == ""
          ? milestoneDes.join(",")
          : description + "," + milestoneDes.join(",")
        : ""
    );
    form.append("work_scope", scope);
    form.append("amount", BidAmount);
    form.append(
      "milestone_amount[]",
      selected == "By Milestone"
        ? amount == ""
          ? milestoneAmount.join(",")
          : amount + "," + milestoneAmount.join(",")
        : ""
    );

    if (scopefile.name != null) {
      form.append("attach_portfolio", scopefile);
    }

    JobService.submitquote(form, true)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="explore explore1">
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <div onClick={back}>
                      <a href="#">
                        <i className="bx bx-chevron-left" /> Back to view applied jobs
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              {/* <a href="#!" className="btn">
                Share this Job <img src="/assets/images/share.png" />
              </a> */}
            </div>
          </div>
        </div>
        <div className="explore-details">
          <div className="row">
            <div className="col-md-7">
              <p className="jobid">
                Job#{" "}
                <span>
                  {" "}
                  {location.state.item.job_unique_id.substr(
                    location.state.item.job_unique_id.indexOf(" ") + 1
                  )}
                </span>
              </p>
              <ul className="list-one">
                <li>
                  <a href="#!">
                    {location.state.item.payment_terms} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!" />
                  {location.state.item.timestamp} <span>|</span>
                </li>
                <li>
                  <a href="#!">
                  Targeted close Date {location.state.item.target_closed_date}{" "}
                    <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.item.total_quotes_received} Quotes Received
                  </a>
                </li>
              </ul>
              <h1 className="title">{location.state.item.job_title}</h1>
              <ul className="list-two">
                <li>
                  <a href="#!">
                    {location.state.item.job_location} <span>|</span>
                  </a>
                </li>
                {/* <li>
                  <a href="#!">
                    GigFWS Term <span>|</span>
                  </a>
                </li> */}
                <li>
                  <a href="#!">
                    Budget <img src="/assets/images/rupee.png" />{" "}
                    <span className="amount">{location.state.item.amount}</span>
                  </a>
                </li>
              </ul>
              {/* <ul className="category-list">
                {location.state.item.skills.split(",").map((skill) => (
                  <li>
                    <a href="#">{skill}</a>
                  </li>
                ))}
              </ul> */}
              <p>{location.state.item.job_description}</p>

              {/* <a href="#!" className="btn quote edit">
                Edit Quote
              </a> */}
            </div>
            {isEdit ? (
              <div className="col-md-5">
                <div className="sidebar">
                  <LoadingOverlay>
                    <h1 className="title">Edit Quote</h1>
                    <h6>Amount you would like to quote for this job?</h6>
                    <div className="col-md-6" style={{ paddingRight: 0 }}>
                      <input
                        type="text"
                        name
                        value={BidAmount}
                        onChange={(e) => {
                          console.log(e);
                          setBidAmount(e.target.value);
                        }}
                        placeholder="Biding Amount"
                      />
                    </div>

                    <h6>
                      Choose a Payment term{" "}
                      <img src="/assets/images/exclamation.png" />
                    </h6>
                    <ul className="payment">
                      <li>
                        <Link
                          to="#"
                          style={{
                            borderColor:
                              selected === "By Terms" ? "#F85100" : "#cbcbcb",
                          }}
                          onClick={onbyTerms}
                        >
                          <img src="/assets/images/terms.png" />
                          <span>By GigFWS Term</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          style={{
                            borderColor:
                              selected === "By Milestone"
                                ? "#F85100"
                                : "#cbcbcb",
                          }}
                          onClick={onMilestone}
                        >
                          <img src="/assets/images/milestone.png" />
                          <span>By Milestone</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          style={{
                            borderColor:
                              selected === "By Completion"
                                ? "#F85100"
                                : "#cbcbcb",
                          }}
                          onClick={onCompletion}
                        >
                          <img src="/assets/images/completion.png" />
                          <span>By Completion</span>
                        </Link>
                      </li>
                    </ul>
                    {selected === "By Milestone" ? (
                      <div>
                        <p>
                          You can divide the project into different small
                          milestones and paid up after completing each one of
                          them.
                        </p>
                        <div className="milestones">
                          <form>
                            <div className="row">
                              <div
                                className="col-md-6"
                                style={{ paddingRight: 0 }}
                              >
                                <input
                                  type="text"
                                  name
                                  value={description}
                                  onChange={(e) => {
                                    console.log(e);
                                    setDescrption(e.target.value);
                                  }}
                                  placeholder="Details about Milestone"
                                />
                              </div>
                              <div
                                className="col-md-2"
                                style={{ paddingRight: 0 }}
                              >
                                <input
                                  type="text"
                                  name
                                  value={amount}
                                  onChange={(text) => {
                                    setAmount(text.target.value);
                                  }}
                                  placeholder="Amount in Rupees"
                                />
                              </div>
                              <div
                                className="col-md-4"
                                style={{ paddingRight: 0 }}
                              >
                                <button
                                  className="btn send"
                                  style={{
                                    fontSize: 13,
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setMilestoneView([
                                      ...milestoneView,
                                      milestoneView.length,
                                    ]);
                                  }}
                                >
                                  Add Milestone
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        {milestoneView.map((value, index) => (
                          <div className="milestones">
                            <form>
                              <div className="row">
                                <div
                                  className="col-md-6"
                                  style={{ paddingRight: 0 }}
                                >
                                  <input
                                    type="text"
                                    name
                                    value={
                                      milestoneDes.length > index
                                        ? milestoneDes[index]
                                        : ""
                                    }
                                    onChange={(text) => {
                                      let arr = milestoneDes;
                                      // arr.splice(index,1,text)
                                      setMilestoneDes([
                                        ...milestoneDes.slice(0, index),
                                        text.target.value,
                                        ...milestoneDes.slice(index + 1),
                                      ]);
                                    }}
                                    placeholder="Details about Milestone"
                                  />
                                </div>
                                <div
                                  className="col-md-2"
                                  style={{ paddingRight: 0 }}
                                >
                                  <input
                                    type="text"
                                    name
                                    value={
                                      milestoneAmount.length > index
                                        ? milestoneAmount[index]
                                        : ""
                                    }
                                    onChange={(text) => {
                                      let arr = milestoneAmount;
                                      // arr.splice(index,1,text)
                                      setMilestoneAmount([
                                        ...milestoneAmount.slice(0, index),
                                        text.target.value,
                                        ...milestoneAmount.slice(index + 1),
                                      ]);
                                    }}
                                    placeholder="Amount in Rupees"
                                  />
                                </div>
                                <div
                                  className="col-md-4"
                                  style={{ paddingRight: 0 }}
                                >
                                  <button
                                    className="btn send"
                                    style={{
                                      fontSize: 13,
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();

                                      console.log(index);

                                      console.log(milestoneView);
                                      setMilestoneView(
                                        milestoneView.filter(
                                          (item, indexid) => indexid !== index
                                        )
                                      );
                                      setMilestoneDes(
                                        milestoneDes.filter(
                                          (item, indexid) => indexid !== index
                                        )
                                      );
                                      setMilestoneAmount(
                                        milestoneAmount.filter(
                                          (item, indexid) => indexid !== index
                                        )
                                      );
                                    }}
                                  >
                                    Remove Milestone
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        ))}
                      </div>
                    ) : selected === "By Terms" ? (
                      <ul className="payment-list">
                        <li>20 % Payment @ Intial</li>
                        <li>60 % Payment @ After Service Done</li>
                        <li>
                          20 % Payment @ After Reviewing the entire service
                        </li>
                      </ul>
                    ) : null}
                    <form>
                      <label>Scope of Work</label>
                      <textarea
                        type="text"
                        name
                        value={scope}
                        onChange={(e) => {
                          console.log(e);
                          setScope(e.target.value);
                        }}
                        placeholder="You can ask Additional Enquiries here"
                        defaultValue={""}
                      />
                      <p>
                        Material costs, transportation and all other additional
                        resource costs may/ may not be added. Please ask any
                        questions upfront and get it clarified. You can mention
                        any of your queries in the above box.
                      </p>
                      {/* <div className="files">
                       
                          <p className="file">
                          <input
                        className="FileUpload1"
                        id="FileInput"
                        name="booking_attachment"
                        type="file"
                        onChange={onFilesChange}
                        onError={onFilesError}
                        accept="image/*,.pdf"
                        accepts={["image/png", "image/jpg",".pdf"]}
                        maxFileSize={1800000}
                        minFileSize={0}
                        clickable
                  
                      />
                            <label htmlFor="file">
                              <img src="/assets/images/attachments.png" />
                              {scopefile.name==null?"Attach your Proposal and Portfolio":scopefile.name}
                            
                            </label>
                          </p>
                        <label className="error">{scopeValidation}</label>
                      </div> */}
                      <div className="form-group">
                      <img src="/assets/images/attachments.png" />
                      {scopefile.name==null?"Attach your Proposal and Portfolio":scopefile.name}
                 
                      <label htmlFor="file">

<input
  className="FileUpload1"
  id="FileInput"
  name="booking_attachment"
  type="file"
  onChange={onFilesChange}
  onError={onFilesError}
  accept="image/*,.pdf"
  accepts={["image/png", "image/jpg",".pdf"]}
  maxFileSize={1800000}
  minFileSize={0}
  clickable

/>
         
       </label>
                 {/* <label className="error">{attachement.name}</label> */}

               <label className="error">{scopeValidation}</label>
             </div>
                    </form>
                    <div className="row">
                      <div className="col-md-6 text-center">
                        <button className="btn cancel" onClick={cancelClick} >Cancel</button>
                      </div>
                      <div className="col-md-6 text-center">
                        <button className="btn send" onClick={submitquote}>
                          Submit Quote
                        </button>
                      </div>
                    </div>
                    <Loader loading={loading} />
                  </LoadingOverlay>
                </div>
              </div>
            ) : (
              <div className="col-md-5">
                <section className="profile you-quoted">
                  <div className="container">
                    <h3>You Quoted</h3>
                    <div className="profile-detail yorself">
                      <div className="row">
                        <div className="col-md-1 padding-0">
                          <a href="#!" className="profile-avatar">
                            <img src="/assets/images/you-quoted.png" />
                          </a>
                        </div>
                        <div className="col-md-11 padding-0">
                          <ul>
                            <li>
                              <h6>{location.state.item.payment_terms}</h6>
                              <h2>
                                <img
                                  src="/assets/images/rupee-blue.png"
                                  className="rupee"
                                />{" "}
                                <span>{location.state.item.bid_amount}/-</span>
                              </h2>
                              <p>Budget</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <a href="#" className="message">
                    <img src="/assets/images/message.png" /> Message
                  </a> */}
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
          isOpen={isCancelModelopen}
          onRequestClose={cancelModelClose}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <div className="modal-dialog loginpopup reset-password-sec" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Cancel</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={cancelModelClose}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <label  style={{
                                    margin:25,
                                  }} >Are you sure you want to cancel the application?</label>

              <div className="passowrd-reset-omdal modal-body">
                <div className="terms-condition">
                  <div className="col-md-12">
                    <div className="form-group cp-old-password">
                    <textarea
                        className="form-control"
                        required="required"
                        placeholder="Type reason..."
                        style={{height:100}}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      />
                     
                    </div>

                   
                    <button className="btn btn-default" onClick={cancelQuote}>Submit</button>
                  </div>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </Modal>
    </section>
  );
};
 //style for model
 const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 100 },
};

export default connect(mapStateToProps, mapDispatchToProps)(MyQuoteDetailView);
