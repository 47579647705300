import React from "react";

const Banner = ({ appName, token }) => {
  if (token) {
    return null;
  }
  return (
    <div className="main-banner-area">
      <div className="main-banner-item">
        <div className="container">
          <div className="main-banner-content"></div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
