import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { commonService } from "../../Network/services/commonService";
import { profileUpdateService } from "../../Network/services/profileUpdateService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";
import ReactPasswordToggleIcon from 'react-password-toggle-icon';

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MyProfileView = (props) => {

  const oldPassword1 = useRef(null);
  const newPassword1 = useRef(null);
  const confirmNewPassword = useRef(null);

  const showIcon = () => <i class="fa fa-eye" aria-hidden="true"></i>;
  const hideIcon = () => <i class="fa fa-eye-slash" aria-hidden="true"></i>


  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  // console.log("props++++++++++++++++++++++", location);
  const [profile, setProfile] = useState({});
  const [name, setName] = useState("");
  const [rating, setRating] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailEdit, setEmailEdit] = useState("");
  const [phoneEdit, setPhoneEdit] = useState("");
  const [mailOTP, setMailOTP] = useState("");
  const [phoneOTP, setPhoneOTP] = useState("");
  const [connectCount, setConnectCount] = useState(0);
  const [isEmail, setIsEmail] = useState(false);
  const [fetchprofile, setFetchprofile] = useState(true);

  const [total, setTotal] = useState(0);
  const [project_rate, setproject_rate] = useState("");
  const [project_rate_type, setproject_rate_type] = useState("");
  const [image, setImage] = useState("");
  const [skills, setSkills] = useState([]);
  const [projects_completed, setprojects_completed] = useState([]);
  const [total_inprogress_project, settotal_inprogress_project] = useState([]);

  const [personalname, setPersonalname] = useState([]);
  const [personalprofession, setPersonalprofession] = useState([]);
  const [professionalName, setProfessionalName] = useState([]);
  const [professionalprofession, setProfessionalprofession] = useState([]);
  const [isMobileVerified, setIsMobileVerified] = useState("");
  const [isEmailVerified, SetIsEmailVerified] = useState("");
  const [loginType, SetLoginType] = useState("");
  const [profilePercentage, SetProfilePercentage] = useState(0);

  const [iskycFilled, setIsKycFilled] = useState("");
  const [webLink, setWebLink] = useState("");

  const [loading, setLoading] = useState(false);

  //state for change password
  const [isChangePasswordModelOpen, setIsChangePasswordModelOpen] = useState(false);  
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //state for email and mobile
  const [isEmailMobileModelOpen, setIsEmailMobileModelOpen] = useState(false);

  const [mobileEmailEditTitle, setMobileEmailEditTitle] = useState("Edit Mobile Number");
  
  const [updateEmail, setUpdateEmail] = useState("");
  const [updateMobile, setUpdateMobile] = useState("");
  const [showMobileField, setShowMobileField] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);
  const [showMobileOTPField, setShowMobileOTPField] = useState(false);
  const [mobileOTP, setMobileOTP] = useState("");
  const [showEmailOTPField, setShowEmailOTPField] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  

  useEffect(() => {
   
    let guser = JSON.parse(window.localStorage.getItem("UserData"));
    const getUserDetail = new FormData();
    getUserDetail.append( "user_id", guser.id );
    let user = [];
    profileUpdateService
    .profile(getUserDetail)
    .then(async (res) => {
      if (res.status == 200) {
        //window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
        user = res.data[0];
        // console.log("---------------- userinfo ----------------")
        // console.log(user)

        //user_detailed_history
        setImage(user.profile_image);

        setProfile(user);

        setName(user.first_name + " " + user.last_name);
        let personal_preference_name1_array = user.personal_preference_name2.split(
          ","
        );
        setPersonalname(personal_preference_name1_array);
        let personal_preference_profession1_array = user.personal_preference_profession2.split(
          ","
        );
        setPersonalprofession(personal_preference_profession1_array);
        let personal_preference_name2_array = user.personal_preference_name1.split(
          ","
        );
        setProfessionalName(personal_preference_name2_array);
        let personal_preference_profession2_array = user.personal_preference_profession1.split(
          ","
        );
        setProfessionalprofession(personal_preference_profession2_array);
        setproject_rate(user.project_rate);
        setproject_rate_type(user.project_rate_type);
        setprojects_completed(user.total_completed_project);
        settotal_inprogress_project(user.total_inprogress_project);
        setEmail(user.email_id);
        setPhone(user.mobile_number);
        setRating(user.user_rating);
        setIsMobileVerified(user.verify_mobile);
        SetIsEmailVerified(user.verify_email);
        setSkills(user.skills.split(","));
        SetLoginType(user.login_type);
        setIsKycFilled(user.is_kyc_updated);
        setWebLink(user.web_link);
        setConnectCount(user.total_connects);
        
        SetProfilePercentage(user.profile_percentage);
        let total_experience_array = user.total_experience.split(",");
        let sum = total_experience_array.reduce(function(prev, current) {
          return prev + +current;
        }, 0);
        setTotal(sum);



      } else {
        
      }
    })
    .catch((err) => {
      toast.success("Something went wrong! please try again later!!", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    })
    .finally(() => {
      //setLoading(false);
    });

    if(iskycFilled==3){
      
      let keyToken = window.localStorage.getItem("kyctoken");
      
      //================ check is aathar verified ====================
      const checkKycStatusForm = new FormData();  
      checkKycStatusForm.append('reference_id', keyToken);

      profileUpdateService
      .verifyKycStatus(checkKycStatusForm)
      .then(async (res) => {
        if (res.status == 200) {
             
          setIsKycFilled(1);
         
          // console.log("ky status:"+ res.data[0].token_status)
                  
          // // await setAuthAsyncStorage(res);
          // window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
          // window.localStorage.setItem("auth_token", res.data[0].auth_token);
          // toast.success(res.message, {            
          //   position: "top-right",
          //   type: "success",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: 0,
          // });
        
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
      //================ /check is aathar verified ====================

    }
     

  }, [iskycFilled]);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const redirect = () => {
    history.push("/ExperienceUpdate");
  };

  //update profile
  const updateProfile = (e) => {
    e.preventDefault();
    setLoading(true);

    const FormData = require("form-data");

    const form = new FormData();

    profileUpdateService
      .profileUpdatecall(form)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
          window.localStorage.setItem("auth_token", res.data[0].auth_token);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //change password
  const changePassword = (e) => {
    e.preventDefault();
    setLoading(true);

    let changePasswordValidationError = "no";

    if(oldPassword==""){
      changePasswordValidationError = "yes";
      toast.success("Please enter old password", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }

    if(newPassword==""){
      changePasswordValidationError = "yes";
      toast.success("Please enter new password", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }
    else{
      //const regEx =  "/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/";
      const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$');

      if(!validPassword.test(newPassword)){
        changePasswordValidationError = "yes";
               
        toast.success("Password Must Contain 8 Characters, Letters and Numbers only", {
          position: "top-right",
          type: "error",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
        
      }
      setLoading(false);
    }

    if(confirmPassword==""){
      changePasswordValidationError = "yes";
      toast.success("Please enter confirm password", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }

    
    if(changePasswordValidationError=="no")
      {
         // change password
        const change_password_form = new FormData();
        change_password_form.append('old_password', oldPassword);
        change_password_form.append('new_password', newPassword);
        change_password_form.append('confrm_password', confirmPassword);
        profileUpdateService
        .changepasswordCall(change_password_form)
        .then(async (res) => {
          if (res.status == 200) {

            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });

            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setIsChangePasswordModelOpen(false);
            // history.goBack();
          } else {
            toast.success(res.message, {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          toast.success("Something went wrong! please try again later!!", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        })
        .finally(() => {
          setLoading(false);
        });
      }
   
   
  }

  //email update
  const emailSubmit = (e) =>{
    e.preventDefault();
    setLoading(true); 
    
    let updateEmailValidationError = "no";

    if(updateEmail==""){
      updateEmailValidationError = "yes";
      toast.success("Please enter valid Email", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }
  
    if(updateEmailValidationError=="no")
      {
         // update email
        const update_email_form = new FormData();
        update_email_form.append('email_id', updateEmail);
        
        profileUpdateService
        .emailPhone(update_email_form,"/update_email")
        .then(async (res) => {
          if (res.status == 200) {
            
            setMobileEmailEditTitle('Verify your Email')
            setShowEmailField(false);
            setShowEmailOTPField(true);
           
            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            //history.goBack();
          } else {
            toast.success(res.message, {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          toast.success("Something went wrong! please try again later!!", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        })
        .finally(() => {
          setLoading(false);
        });
      }


  }

  //resend email otp
  const sendEmailOTP = () => {
    setLoading(true); 

    const FormData = require("form-data");
  
    const form = new FormData();
    form.append("email_id",  updateEmail);
    //form.append('device_token',token);
 
    commonService
      .CommonService("send_otp_email",form)
      .then(async (res) => {
        if (res.status == 200) {
          //this.props.onSubmit(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success(err.toString(), {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false); 
      });
  };

  const emailotpSubmit = (e) =>{
    e.preventDefault();
    setLoading(true); 
    
    let updateEmailOTPValidationError = "no";

    if(emailOTP==""){
      updateEmailOTPValidationError = "yes";
      toast.success("Please enter one time password", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }
  
    if(updateEmailOTPValidationError=="no")
      {
         // update email
        const update_emailotp_form = new FormData();
        update_emailotp_form.append('email_id', updateEmail);
        update_emailotp_form.append('otp', emailOTP);
        
        profileUpdateService
        .emailPhone(update_emailotp_form,"/verify_email")
        .then(async (res) => {
          if (res.status == 200) {
            //console.log("result status:"+res.status);
            setEmail(updateEmail);

            setShowEmailField(false);
            setShowEmailOTPField(false);
           
            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            setIsEmailMobileModelOpen(false);
          } else {
            toast.success(res.message, {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          toast.success("Something went wrong! please try again later!!", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        })
        .finally(() => {
          setLoading(false);
          
        });
      }


  }
   

  //mobile update
  const mobileSubmit = (e) =>{
    e.preventDefault();
    setLoading(true); 
    
    let updateMobileValidationError = "no";

    if(updateMobile==""){
      updateMobileValidationError = "yes";
      toast.success("Please enter valid Mobile Number", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }
  
    if(updateMobileValidationError=="no")
      {
         // update email
        const update_mobile_form = new FormData();
        update_mobile_form.append('mobile_number', updateMobile);
        
        profileUpdateService
        .emailPhone(update_mobile_form,"/update_mobile")
        .then(async (res) => {
          if (res.status == 200) {
            //console.log("result status:"+res.status);
            // await setAuthAsyncStorage(res);

            setMobileEmailEditTitle('Verify your Phone Number')
            setShowMobileField(false);
            setShowMobileOTPField(true);
           
            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            //history.goBack();
          } else {
            toast.success(res.message, {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          toast.success("Something went wrong! please try again later!!", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        })
        .finally(() => {
          setLoading(false);
        });
      }


  }

  //resend mobile otp
  const sendMobileOTP = () => {
    setLoading(true);

    const FormData = require("form-data");
  
    const form = new FormData();
    form.append("mobile_number",  updateMobile);
    //form.append('device_token',token);

    commonService
      .CommonService("send_otp_mobile",form)
      .then(async (res) => {
        if (res.status == 200) {
          //this.props.onSubmit(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success(err.toString(), {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mobileotpSubmit = (e) =>{
    e.preventDefault();
    setLoading(true); 
    
    let updateMobileOTPValidationError = "no";

    if(mobileOTP==""){
      updateMobileOTPValidationError = "yes";
      toast.success("Please enter one time password", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }
  
    if(updateMobileOTPValidationError=="no")
      {
         // update email
        const update_mobileotp_form = new FormData();
        update_mobileotp_form.append('mobile_number', updateMobile);
        update_mobileotp_form.append('otp', mobileOTP);
        
        profileUpdateService
        .emailPhone(update_mobileotp_form,"/verify_mobile")
        .then(async (res) => {
          if (res.status == 200) {
            
            setPhone(updateMobile);

            setShowMobileField(false);
            setShowMobileOTPField(false);
           
            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            
            setIsEmailMobileModelOpen(false);
          } else {
            toast.success(res.message, {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          toast.success("Something went wrong! please try again later!!", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        })
        .finally(() => {
          setLoading(false);
        });
      }


  }
   
  //style for model
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "100%",
      background: "transparent",
    },
    overlay: { zIndex: 100 },
  };
  
  //change password model
  let openChangePasswordmodel = () => {
    setIsChangePasswordModelOpen(true);
  };
  let closeChangePasswordmodel = () => {
    setIsChangePasswordModelOpen(false);
  };

  //email edit and mobile edit model
  let openEmailMobileUpdatemodel = (contactType) => {
    setIsEmailMobileModelOpen(true);
    
    setShowMobileField(false);
    setShowEmailField(false);
    setShowEmailOTPField(false);
    setShowMobileOTPField(false);

    if(contactType=="mobile"){
      setMobileEmailEditTitle('Edit Mobile Number')
      setShowMobileField(true);
      setShowEmailField(false);
    }
    else if(contactType=="email"){
      setMobileEmailEditTitle('Edit Email')
      setShowMobileField(false);
      setShowEmailField(true);
     }

  };
  let closeEmailMobileUpdatemodel = () => {
    setIsEmailMobileModelOpen(false);
    setShowMobileField(false);
    setShowEmailField(false);
    setShowEmailOTPField(false);
    setShowMobileOTPField(false);
  };
  
  const openWebLink = () => {
    const weLinkUrl = removeHttp(webLink);
    // console.log("---weLinkUrl--");
    // console.log(weLinkUrl);
    window.open("//"+weLinkUrl, '_blank');
  }

  function removeHttp(url) {
    if (url.startsWith('https://')) {
      const https = 'https://';
      return url.slice(https.length);
    }
  
    if (url.startsWith('http://')) {
      const http = 'http://';
      return url.slice(http.length);
    }
  
    return url;
  }

  return (
    <section className="profile">
      <LoadingOverlay>
        <div className="container">
          {/* <div className="row">
            <div className="col-md-12">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="#!">
                      <i className="bx bx-chevron-left" /> Back to all jobs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-12">
              <h1 className="title">My Profile</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">              
              <a className="change-password" style={{cursor:"pointer"}} onClick={openChangePasswordmodel}>
                <i className="bx bx-lock" /> Change Password
              </a>
            </div>
          </div>

          <div className="profile-detail margin-left my-profile-sec-first">
            <div className="row">
              <div className="col-md-1 padding-0">
                <a
                  href="#!"
                  style={{ height: 100, width: 100 }}
                  className="profile-avatar photo"
                >
                  {image ? (
                    <img src={image} width="50" height="50" />
                  ) : (
                    <img src="assets/images/profile-avatar.png" />
                  )}
                  {iskycFilled == "1" ? (
                    <span>
                      Verified by <span>GigFWS</span>
                    </span>
                  ) : null}
                </a>
              </div>
              <div className="col-md-11 padding-0">
                <ul>
                  <li>
                    <a>
                      {name}
                      <span>{profile.native_location}</span>

                      {webLink != null ? (

                        <span style={{ display: "table-cell", cursor: "pointer" }} onClick={openWebLink}><span className="weblink-sec">(Web Link)</span></span>
                       
                      ) : null}
                    </a>
                  </li>
                  <li className="line">
                    <a href="#!">
                      {connectCount}<span>Connects</span>
                    </a>
                  </li>
                  <li className="line">
                    <a href="#!">
                      {rating} out of 5<span>Review</span>
                    </a>
                  </li>
                  <li>
                  
                    <Link className="btn btn-default" to="/connect">Connect Now</Link>
                  </li>
                  <li className="edit">
                    <Link className="edit" to="/profileEdit">Edit</Link>
                    {/* <a
                      href="#"
                      className="edit"
                      onClick={() => {
                        history.push("/profileEdit");
                      }}
                    >
                      Edit
                    </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="profile-detail qualification" style={{padding: "3.5em 2em"}}>
            <div className="row">

              <div className="col-md-1 padding-0">
                <a href="#!" className="profile-avatar"><img src="assets/images/qualification.png" /><span>Contacts</span></a>
              </div>

           <div className="col-md-11 padding-0">
                <ul>
                  <li className="one">
                  <div className="row">
                   <li className="edit three">
                   { isMobileVerified == 1 && 
                    <a href="#!" className="edit mobile-edit-myprofile" onClick={() => openEmailMobileUpdatemodel("mobile")}>Edit Mobile</a>
                   }

                
                  </li>
                  </div>
                    <div className="row">
                      <div className="col-md-2 padding-0">
                    <img src="assets/images/graduation-cap.png" />
                    </div>
                    <div className="col-md-10 padding-0">
                      <p>Phone Number {(isMobileVerified==1) ? <img src="assets/images/verified.png" className="verified" /> : '' }<span>{phone}</span> </p>
                    </div>
                    </div>
                    
                  </li>
                  <li className="line two">
                  <div className="row">
                   <li className="edit three">
                   { isEmailVerified == 1 && 
                    <a href="#!" className="edit edit-email-sec" onClick={() => openEmailMobileUpdatemodel("email")}>Edit Email</a>
                   }
                  
                  </li>
                  </div>
                    <div className="row">
                      <div className="col-md-2 padding-0">
                    <img src="assets/images/graduation-cap.png" />
                    </div>
                    <div className="col-md-10 padding-0">
                    
                      <p>Email Id {(isEmailVerified == 1) ? <img src="assets/images/verified.png" className="verified" /> : '' }<span>{email}</span></p>
                    </div>
                    </div>
                    
                  </li>
                 
                </ul>
              </div>

          </div>
          </div>

          <div className="profile-detail qualification" style={{padding: "3.5em 2em"}}>
            <div className="row">

              <div className="col-md-1 padding-0">
                <a href="#!" className="profile-avatar"><img src="assets/images/qualification.png" /><span>E-KYC</span></a>
            </div>

            <div className="col-md-11 padding-0">
              {
                iskycFilled == "1" ? (
                <span>
                <br/>
                  Verified <img src="assets/images/verified.png" className="verified" />
                </span>
              ) : 
              ( <ul>
                
                <li className="one">
                <br/><br/>
                                    
                </li>
                
                <li className="edit"><Link className="edit" to="/kycLanguage">Edit</Link></li>
                
              </ul>
              )
              }

              <ul>
                
                
                
              </ul>
              
            </div>

          </div>
        </div>

          <div className="profile-detail yorself">
            <div className="row">
              <div className="col-md-1 padding-0">
                <a href="#!" className="profile-avatar">
                  <img src="assets/images/yourself.png" />
                  <span>About Yourself</span>
                </a>
              </div>
              <div className="col-md-11 padding-0">
                <ul>
                  <li>
                    <img src="assets/images/noun_job.png" />
                    <a href="#!">
                      {skills.length > 0 ? skills[0] : ""},
                      <span> {total} Years Expert</span>
                    </a>
                  </li>
                  <li className="line">
                    <img src="assets/images/salary.png" />
                    <a href="#!">
                      <img src="assets/images/rupee.png" className="rupee" />{" "}
                      {project_rate}
                      <span>{project_rate_type}</span>
                    </a>
                  </li>
                  <li className="line">
                    <img src="assets/images/arrows.png" />
                    <a href="#!">
                    {total_inprogress_project}<span>Projects In Progress</span>
                    </a>
                  </li>
                  <li className="line">
                    <img src="assets/images/start-up.png" />
                    <a href="#!">
                      {projects_completed}<span>Projects Completed</span>
                    </a>
                  </li>
                  <li className="edit">
                    <Link className="edit" to="/experienceEdit">Edit</Link>
                    {/* <a
                      href="#"
                      className="edit"
                      onClick={() => {
                        history.push("/experienceEdit");
                      }}
                    >
                      Edit
                    </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="profile-detail yorself resume">
            <div className="row">
              <div className="col-md-1 padding-0">
                <a href="#!" className="profile-avatar">
                  <img src="assets/images/resume.png" />
                  <span>My Resume</span>
                </a>
              </div>
              <div className="col-md-11 padding-0">
                <ul>
                  <li>
                  <br/><br/><br/><br/><br/>
                    {/* Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry’s
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum. */}
                  </li>
                  <li className="edit">
                    <Link className="edit" to="/myresumeEdit">Edit</Link>
                    {/* <a href="#!" className="edit">
                      Edit
                    </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          

          {/* <div className="profile-detail qualification">
            <div className="row">
              <div className="col-md-1 padding-0">
                <a href="#!" className="profile-avatar">
                  <img src="assets/images/qualification.png" />
                  <span>Qualifications</span>
                </a>
              </div>
              <div className="col-md-11 padding-0">
                <ul>
                  <li className="one">
                    <div className="row">
                      <div className="col-md-2 padding-0">
                        <img src="assets/images/graduation-cap.png" />
                      </div>
                      <div className="col-md-10 padding-0">
                        <p>
                          Diploma in Plumbing <span>XXXX University</span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 padding-0">
                        <img src="assets/images/graduation-cap.png" />
                      </div>
                      <div className="col-md-10 padding-0">
                        <p>
                          Diploma in Plumbing <span>XXXX University</span>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="line two">
                    <div className="row">
                      <div className="col-md-2 padding-0">
                        <img src="assets/images/graduation-cap.png" />
                      </div>
                      <div className="col-md-10 padding-0">
                        <p>
                          Diploma in Plumbing <span>XXXX University</span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 padding-0">
                        <img src="assets/images/graduation-cap.png" />
                      </div>
                      <div className="col-md-10 padding-0">
                        <p>
                          Diploma in Plumbing <span>XXXX University</span>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="edit three">
                    <Link className="edit" to="/referenceEdit">Edit</Link>
                  
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="profile-detail qualification referance personal-qualification">
            <div className="row">
              <div className="col-md-1 padding-0">
                <a href="#!" className="profile-avatar">
                  <img src="assets/images/referance.png" />
                  <span>References</span>
                </a>
              </div>
              <div className="col-md-11 padding-0">
                <ul>
                  <li className="one">
                    <p className="stripe">Professional</p>
                    <div className="row">
                      <div className="col-md-2 padding-0">
                        <img src="assets/images/graduation-cap.png" />
                      </div>
                      <div className="col-md-10 padding-0">
                        {professionalName.map((item, index) => (
                          <p>
                            {item}
                            <span> {professionalprofession[index]}</span>
                          </p>
                        ))}
                      </div>
                    </div>
                  </li>
                  <li className="line two">
                    <p className="stripe">Personal</p>
                    <div className="row">
                      <div className="col-md-2 padding-0">
                        <img src="assets/images/graduation-cap.png" />
                      </div>
                      <div className="col-md-10 padding-0">
                        {personalname.map((item, index) => (
                          <p>
                            {item}
                            <span> {personalprofession[index]}</span>
                          </p>
                        ))}
                      </div>
                    </div>
                  </li>
                  <li className="edit">
                    <Link className="edit" to="/referenceEdit">Edit</Link>
                    {/* <a
                      href="#!"
                      className="edit"
                      onClick={() => {
                        history.push("/referenceEdit");
                      }}
                    >
                      Edit
                    </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>


        {/*   =======================Model section======================   */}

        {/* Change password model */}
        <Modal
          isOpen={isChangePasswordModelOpen}
          onRequestClose={closeChangePasswordmodel}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <div className="modal-dialog loginpopup reset-password-sec" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Password</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeChangePasswordmodel}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="passowrd-reset-omdal modal-body">
                <div className="terms-condition">
                  <div className="col-md-12">
                    <div className="form-group cp-old-password">
                      <input
                        type="password"
                        className="form-control"
                        required="required"
                        placeholder="*Old Password"
                        
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                        }}
                        ref = {oldPassword1}
                      />
                      <ReactPasswordToggleIcon 
                            inputRef={oldPassword1} 
                            showIcon={hideIcon}
                            hideIcon={showIcon}
                          /> 
                    </div>

                    <div className="form-group cp-new-password">
                      <input
                        type="password"
                        className="form-control"
                        required="required"
                        placeholder="*New Password"
                        
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                        ref = {newPassword1}
                      />
                      <ReactPasswordToggleIcon 
                            inputRef={newPassword1} 
                            showIcon={hideIcon}
                            hideIcon={showIcon}
                          /> 
                    </div>

                    <div className="form-group cp-confirm-new-password">
                      <input
                        type="password"
                        className="form-control"
                        required="required"
                        placeholder="*Confirm Password"
                        
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        ref = {confirmNewPassword}
                      />
                      <ReactPasswordToggleIcon 
                            inputRef={confirmNewPassword} 
                            showIcon={hideIcon}
                            hideIcon={showIcon}
                          /> 
                    </div>

                    <button className="btn btn-default" onClick={changePassword}>Update</button>
                  </div>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </Modal>
        
        {/* email mobile update model */}
        <Modal
          isOpen={isEmailMobileModelOpen}
          onRequestClose={closeEmailMobileUpdatemodel}
          style={customStyles}
          contentLabel="Update Contact"
          scrollable={true}
        >
          <div className="modal-dialog loginpopup" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{mobileEmailEditTitle} </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeEmailMobileUpdatemodel}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="my-profile-edit-email terms-condition">
                  <div className="col-md-12">                    

                    {showEmailField==true &&
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          required="required"
                          placeholder="*Email Address"
                          
                          onChange={(e) => {
                            setUpdateEmail(e.target.value);
                          }}
                        />
                        <button className="btn btn-default" onClick={emailSubmit}>Update</button>
                      </div>                        
                    }

                    {showEmailOTPField==true &&

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required="required"
                          placeholder="Enter one time passeword"
                          
                          onChange={(e) => {
                            setEmailOTP(e.target.value);
                          }}
                        />
                        <p style={{textAlign:"right"}}><span style={{cursor:"pointer"}} onClick={sendEmailOTP}>Resend OTP</span></p>
                        <button className="btn btn-default" onClick={emailotpSubmit}>Verify</button>
                      </div>                      
                    }

                    {showMobileField==true &&

                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          required="required"
                          placeholder="*Mobile Number"
                          
                          onChange={(e) => {
                            setUpdateMobile(e.target.value);
                          }}
                        />
                        <button className="btn btn-default" onClick={mobileSubmit}>Update</button>
                      </div>                      
                    }
                    
                    {showMobileOTPField==true &&

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required="required"
                          placeholder="Enter one time passeword"
                          
                          onChange={(e) => {
                            setMobileOTP(e.target.value);
                          }}
                        />
                        <p style={{textAlign:"right"}}><span style={{cursor:"pointer"}} onClick={sendMobileOTP}>Resend OTP</span></p>
                        <button className="btn btn-default" onClick={mobileotpSubmit}>Verify</button>
                        
                      </div>                      
                    }

                  </div>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/*   =======================Model section======================   */}

        </div>
        

        <Loader loading={loading} />
      </LoadingOverlay>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileView);
