import React, { useState, useCallback } from "react";
import './ImageCrop.css'

import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { profileUpdateService } from "../../../Network/services/profileUpdateService";

// import { generateDownload } from "./utils/cropImage";
import getCroppedImgDataUrl, { uploadFile } from "./utils/cropImage";

const CropProfileImage = (props) => {

	const [loading, setLoading] = useState(false);

  	const inputRef = React.useRef();

	const triggerFileSelectPopup = () => inputRef.current.click();

	const [image, setImage] = React.useState(null);
	const [croppedArea, setCroppedArea] = React.useState(null);
	const [crop, setCrop] = React.useState({ x: 0, y: 0 });
	const [zoom, setZoom] = React.useState(1);

	const [croppedImage, setCroppedImage] = useState(null)


	const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
		setCroppedArea(croppedAreaPixels);
	};

	const onSelectFile = (event) => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.addEventListener("load", () => {
				setImage(reader.result);
			});
		}
	};

	// const onDownload = () => {
	// 	generateDownload(image, croppedArea);
	// };
	
	const onDownload = () => {
		let outputfile= uploadFile(image, croppedArea);
		console.log('output file')
		console.log(outputfile)

		const form = new FormData();		
		if (uploadFile != "") {
			form.append("profile_image", uploadFile);
		}
		else{
			form.append("profile_image", "");
		}

		profileUpdateService
		.profileUpdatecall(form)
		.then(async (res) => {
			if (res.status == 200) {
			// await setAuthAsyncStorage(res);
			window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
			window.localStorage.setItem("auth_token", res.data[0].auth_token);
			toast.success(res.message, {
				position: "top-right",
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: 0,
			});
			// history.goBack();
			} else {
			toast.success(res.message, {
				position: "top-right",
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: 0,
			});
			}
		})
		.catch((err) => {
			toast.success("Something went wrong! please try again later!!", {
			position: "top-right",
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: 0,
			});
		})
		.finally(() => {
			setLoading(false);
		});
	};



	const uploadCroppedImage = useCallback(async () => {
		// try {
		//   const croppedImage = await getCroppedImgDataUrl(
		// 	image,
		// 	crop
		//   )
		//   console.log('donee', { croppedImage })
		//   setCroppedImage(croppedImage)
		// } catch (e) {
		//   console.error(e)
		// }

		const canvas = await getCroppedImgDataUrl(image, croppedArea);
		//console.log(canvas);
		
		let outputfile = dataURLtoFile(canvas,"profile_picture.jpg");
		console.log('output file')
		console.log(outputfile)

		const form = new FormData();		
		if (outputfile != "") {
			form.append("profile_image", outputfile);
		}
		else{
			form.append("profile_image", "");
		}

		profileUpdateService
		.profileUpdatecall(form)
		.then(async (res) => {
			if (res.status == 200) {
			// await setAuthAsyncStorage(res);
			window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
			window.localStorage.setItem("auth_token", res.data[0].auth_token);
			toast.success(res.message, {
				position: "top-right",
				type: "success",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: 0,
			});
			// history.goBack();
			window.location.reload(false);
			} else {
			toast.success(res.message, {
				position: "top-right",
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: 0,
			});
			}
		})
		.catch((err) => {
			toast.success("Something went wrong! please try again later!!", {
			position: "top-right",
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: 0,
			});
		})
		.finally(() => {
			setLoading(false);
		});



	  }, [croppedArea])
	
	  const onClose = useCallback(() => {
		setCroppedImage(null)
	  }, [])


	    // To convert dataUrl (which we get from our blob) to a a file object
		const dataURLtoFile = (dataurl, filename) => {
			const arr = dataurl.split(",");
			const mime = arr[0].match(/:(.*?);/)[1];
			const bstr = atob(arr[1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);

			while (n--) u8arr[n] = bstr.charCodeAt(n);

			return new File([u8arr], filename, { type: mime });
		};

	return (
		<div className="cimage">
			<div className={"crop-container "+(image ? 'crop-container-height' : '' )}>
				<div className='crop-container-cropper'>
					{image ? (
						<>
							<div className='cropper'>
								<Cropper
									image={image}
									crop={crop}
									zoom={zoom}
									aspect={1}
									onCropChange={setCrop}
									onZoomChange={setZoom}
									onCropComplete={onCropComplete}
								/>
							</div>

							<div className='slider'>
								<Slider
									min={1}
									max={3}
									step={0.1}
									value={zoom}
									onChange={(e, zoom) => setZoom(zoom)}
								/>
							</div>
						</>
					) : null}
				</div>

				<div className='container-buttons'>
					<input
						type='file'
						accept='image/*'
						ref={inputRef}
						onChange={onSelectFile}
						style={{ display: "none" }}
					/>
					<Button
						variant='contained'
						color='primary'
						onClick={triggerFileSelectPopup}
						style={{ marginRight: "10px" }}
					>
						Choose
					</Button>
					
					<Button variant='contained' color='secondary' onClick={uploadCroppedImage}>
						Upload
					</Button>
				</div>
			</div>
		</div>
	);
}

export default CropProfileImage;