import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { profileUpdateService } from "../../Network/services/profileUpdateService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const KycEditView = (props) => {
  
  let location = useLocation();
  let history = useHistory();
  // console.log("props++++++++++++++++++++++", location);
  
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [idNumberShow, setIdNumberShow] = useState(true);
  const [idprooftype, setIdprooftype] = useState();
  const [idproofnumber, setIdproofnumber] = useState();
  const [capchaVideoFile, setCapchaVideoFile] = useState();
  const [capchaInfo, setCapchaInfo] = useState();

  const [kycSubmitBtn,setKycSubmitBtn] = useState("ADD KYC")

  const [aadharCaptcha, setAadharCaptcha] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [captchaImage, setCaptchaImage] = useState("");
  const [verifyotp, setverifyotp] = useState(false);

  //aadhar form fields
  const [captcha, setcaptcha] = useState("");
  const [uid_no, setuid_no] = useState("");
  
  const [otp, setotp] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [email_id, setemail_id] = useState("");
  

  useEffect(() => {
    window.scrollTo(0, 0)

    // console.log("video ",props.location.state.userInfo);
    setCapchaVideoFile(props.location.state.userInfo[0]);
    setCapchaInfo(props.location.state.userInfo[1]);

    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);

    // ============== get captcha for aadhar verification ==============/
    const form = new FormData();  

    profileUpdateService
      .kycAadharCaptcha(form)
      .then(async (res) => {
        if (res.status == 200) {
          setAadharCaptcha(res.data);
          console.log("=================== AadharCaptcha ====================");
          console.log(res.data);
          setSessionId(res.data.session_id);
          setCaptchaImage(res.data.image_captcha);
        
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        //setLoading(false);
      });
    // ============== /get captcha for aadhar verification ==============/

    
 
  }, []);


  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const reCaptcha = (e) => {
    e.preventDefault();
    setLoading(true);
    
    // ============== get captcha for aadhar verification ==============/
    const form = new FormData();  

    profileUpdateService
      .kycAadharCaptcha(form)
      .then(async (res) => {
        if (res.status == 200) {
          setAadharCaptcha(res.data);
          console.log("=================== AadharCaptcha ====================");
          console.log(res.data);
          setSessionId(res.data.session_id);
          setCaptchaImage(res.data.image_captcha);
        
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    // ============== /get captcha for aadhar verification ==============/
   
  };

  const profileupdate = (e) => {
    e.preventDefault();
    setLoading(true);
    
    const userCaptchaVideoInfo = capchaVideoFile;
    const tdate = new Date().getTime();
    let videoFileName = "KycUser"+tdate;
    
    // userCaptchaVideoInfo.lastModified = new Date();
    // userCaptchaVideoInfo.name = videoFileName;

    let userVideoFile = new File([userCaptchaVideoInfo], videoFileName);
    const myFile = new File([userVideoFile], videoFileName, {
        type: "video/mp4",
    });      
         

    const form = new FormData();
    form.append('id_proof_type', idprooftype);
    form.append('id_proof_number', idproofnumber);
    form.append('file', myFile);
    form.append('kyc_captcha_code', capchaInfo);
    if(idprooftype==3){        
        form.append('kyc_current_location', "chennai");
        form.append('captcha', captcha);
        form.append('uid_no', uid_no);
        form.append('session_id', sessionId);
    }
    

    profileUpdateService
      .kycPanGstUpload(form,idprooftype)
      .then(async (res) => {
        if (res.status == 200) {
          //console.log(res.token_url);
          if(idprooftype==3){
            // window.localStorage.setItem("kyctoken", res.reference_id);
            // window.location.href = res.token_url; 

            setverifyotp(true);
            setSessionId(res.data.session_id);
            toast.success(res.message, {            
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
          else{
            history.push("/profile");
            // await setAuthAsyncStorage(res);
            window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
            window.localStorage.setItem("auth_token", res.data[0].auth_token);
            toast.success(res.message, {            
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }         
        
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const aadhar_verify_otp = (e) => {
    e.preventDefault();
    setLoading(true);
    
 
    const form = new FormData();
    form.append('otp', otp);
    form.append('session_id', sessionId);
    form.append('phone_number', phone_number);
    form.append('email_id', email_id);       

    profileUpdateService
      .kycAadharVerifyOTP(form)
      .then(async (res) => {
        if (res.status == 200) {
          
          history.push("/profile");
          // await setAuthAsyncStorage(res);
          window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
          window.localStorage.setItem("auth_token", res.data[0].auth_token);
          toast.success(res.message, {            
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });   
        
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const redirect = () => {
    // history.push("/ekycEdit");
  };

  function resumeChange(e) {
    setCapchaVideoFile(e.target.files[0])
    // console.log("resume file=============");
    // console.log(e.target.files[0]);
  }

  function idDropdownChange(e) {
    setIdprooftype(e.target.value)
    if(e.target.value==3){
        setKycSubmitBtn("Next")
        setIdNumberShow(false);
    }
    else{
        setKycSubmitBtn("ADD KYC")
        setIdNumberShow(true);
    }
  }

  return (
    <section className="explore explore1">
    <LoadingOverlay>
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="#!" onClick={(e) => back(e)}>
                      <i className="bx bx-chevron-left" /> Back to Verify user
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="onbord-section">
      
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="title">Fill eKYC</h1>
              <p className="subtitle"></p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              
              <div className="board-box board-box2 post-new-job">

                <form>

                  { verifyotp == false &&
                    <div className="form-group">
                      <select className="form-control" value={idprooftype} onChange={idDropdownChange}>
                          <option >*ID Proof Type</option>
                          <option value="3">Aadhaar(Prefered)</option>
                          <option value="1">PAN Card</option>
                          <option value="2">Company GSTIN</option>
                      </select>
                    </div>
                  }
                  

                  {(idNumberShow && verifyotp == false) && 
                    <>
                        <div className="form-group margin-bottom2">
                        <input type="text" className="form-control" required="required" placeholder="ID Number" onChange={(e) => { setIdproofnumber(e.target.value); }} />
                        </div>
                        
                    </>
                    
                  }

                  {(idNumberShow == false && verifyotp == false) &&
                    <>
                      <div className="form-group">
                        <input type="hidden" name="session_id" className="form-control" required="required" value={sessionId}/>
                        <input type="text" name="uid_no" className="form-control" required="required" placeholder="Aadhar No" value={uid_no} onChange={(e) => { setuid_no(e.target.value); }}/>
                      </div>
                      <div className="form-group margin-bottom2">
                        <input type="text" name="captcha" className="form-control" required="required" placeholder="Captcha" value={captcha} onChange={(e) => { setcaptcha(e.target.value); }}/>
                      </div>
                      <div className="form-group">                      
                        <label className="filelabel">
                          <img src={"data:image/png;base64,"+captchaImage} alt="Please try again" />
                        </label>
                      </div>
                    </>
                    
                  }

                  { verifyotp == false &&
                    <>
                    <button onClick={profileupdate}>{kycSubmitBtn}</button>&nbsp;

                    {(idNumberShow == false && verifyotp == false) && 
                    <button onClick={reCaptcha}>ReCaptcha</button>
                    }
                    </>
                  }

                  { verifyotp == true &&
                    <>
                      <div className="form-group">
                        <input type="hidden" name="session_id" className="form-control" required="required" value={sessionId}/>
                        <input type="text" name="otp" className="form-control" required="required" placeholder="Please enter your OTP" value={otp} onChange={(e) => { setotp(e.target.value); }}/>
                      </div>

                      <div className="form-group margin-bottom2">
                        <input type="text" name="phone_number" className="form-control" placeholder="(Optional) 10-digit mobile number linked to Aadhaar number." value={phone_number} onChange={(e) => { setphone_number(e.target.value); }}/>
                      </div>

                      <div className="form-group margin-bottom2">
                        <input type="text" name="email_id" className="form-control" placeholder="(Optional) Email address linked to Aadhaar number." value={email_id} onChange={(e) => { setemail_id(e.target.value); }}/>
                      </div>

                      <button onClick={aadhar_verify_otp}>Submit</button>
                    </>
                    
                  }


                  {/* <div className="form-group margin-bottom2">                  
                    <input type="file" className="custom-file-input" onChange={resumeChange} />
                  </div> */}

                    {/* <div className="form-group">
                        <p className="subtitle"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Your KYC Verification is in Progress</p>
                        <p className="subtitle"><i className="fa fa-check-circle" aria-hidden="true"></i> Your KYC Was Verified</p>
                    </div>


                    <div className="form-group">
                        <label className="filelabel">
                            <i className="fa fa-paperclip" aria-hidden="true"></i>
                            <span className="title">
                                Attache a File
                            </span>
                            <input className="FileUpload1" id="FileInput" name="booking_attachment" type="file"/>
                        </label>
                    </div>  */}

                  
                </form>


              </div>

            </div>
          </div>

        </section>
      </div>
      <Loader loading={loading} />
    </LoadingOverlay>
    
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KycEditView);
