import axios from 'axios';
import { API_URL } from '../constants';
import APIKit from '../APIKit';

  export const getNotificationList = (payload) => {
      
    return new Promise(async (resolve, reject) => {
      try {
        payload.append("auth_token", window.localStorage.getItem("auth_token"));
      } catch (e) {
        // console.log("failed===========" + e);
      }
      
      APIKit.post("/notification_list", payload)
        .then(async (response) => {
          try {
            resolve(response.data);
          } catch (e) {
            // console.log("err===========" + e);
  
            reject(e);
          }
        })
        .catch((err) => {
          // console.log("err===========" + err);
  
          reject(err);
        });
    });
  }

  export const getNotificationReadStatus = (payload) => {
      
    return new Promise(async (resolve, reject) => {
      try {
        payload.append("auth_token", window.localStorage.getItem("auth_token"));
      } catch (e) {
        // console.log("failed===========" + e);
      }
      
      APIKit.post("/notification_read_status", payload)
        .then(async (response) => {
          try {
            resolve(response.data);
          } catch (e) {
            // console.log("err===========" + e);
  
            reject(e);
          }
        })
        .catch((err) => {
          // console.log("err===========" + err);
  
          reject(err);
        });
    });
  }

  export const clearNotifications = (payload) => {
      
    return new Promise(async (resolve, reject) => {
      try {
        payload.append("auth_token", window.localStorage.getItem("auth_token"));
      } catch (e) {
        // console.log("failed===========" + e);
      }
      
      APIKit.post("/clear_notification", payload)
        .then(async (response) => {
          try {
            resolve(response.data);
          } catch (e) {
            // console.log("err===========" + e);
  
            reject(e);
          }
        })
        .catch((err) => {
          // console.log("err===========" + err);
  
          reject(err);
        });
    });
  }