import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Popper from "popper.js";
import Modal from "react-modal";
import ReactFormInputValidation from "react-form-input-validation";
import agent from "../agent";
import { userService } from "../Network/services/userService";
import { commonService } from "../Network/services/commonService";
import { GoogleLogin ,useGoogleLogin,googleLogout} from '@react-oauth/google';
import FacebookLoginWithButton from 'react-facebook-login';
//import FacebookLogin from 'react-facebook-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Autocomplete from "react-google-autocomplete";
import ReactPasswordToggleIcon from 'react-password-toggle-icon';

import { connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useLocation, useHistory, withRouter } from "react-router-dom";
import history from './history'
import { geolocated } from "react-geolocated";
import Geocode from "react-geocode";

import NavigationBtn from "../components/Notifications/NotificationBtn";

import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  REGISTER,
  LOGIN_PAGE_UNLOADED,
} from "../constants/actionTypes";
import { ToastContainer, toast } from "react-toastify";
const {promiseStatus} = require('promise-status-async');

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeEmail: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "email", value }),
  onChangePassword: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: "password", value }),
  onSubmit: (res) => dispatch({ type: LOGIN, payload: Promise.resolve(res) }),
  onRegisterSubmit: (res) =>
    dispatch({ type: REGISTER, payload: Promise.resolve(res) }),
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
});


let location = ""
const baseLink =""
const CLIENT_ID =
  "668464403161-rrt2gf7s6qr01qrma5ma8p3095gb6tg5.apps.googleusercontent.com";
  const { OAuth2Client } = require('google-auth-library')
 const getDecodedOAuthJwtGoogle = async token => {

  
    try {
      const client = new OAuth2Client(CLIENT_ID)
  
      const ticket = await client.verifyIdToken({
        idToken: token,
        audience: CLIENT_ID,
      })
  
      return ticket
    } catch (error) {
      return { status: 500, data: error }
    }
  }

class Header extends React.Component {



  constructor(props) {
    super();

    this.inputRef = React.createRef();
    this.inputRef1 = React.createRef();
    this.newPassword1 = React.createRef();
    this.confirmNewPassword = React.createRef();

    this.state = {
      registerModelIsOpen: false,
      registerTwoModelIsOpen: false,
      loginModelIsOpen: false,
      successPopupOpen: false,
      termsPopupIsOpen: false,
      privacyPopupIsOpen: false,
      emailOTPPopupIsOpen: false,
      mobileOTPPopupIsOpen: false,
      socialRegisterIsOpen: false,
      isSocialRegister:false,
      forgotPassIsOpen:false,
      resetPassIsOpen:false,
      isLogin: true,
      isRegisterTwo: false,
      isCompany: false,
      isService: false,
      isBoth: false,
      isChecked: false,
      email: "",
      password: "",
      confirmpassword: "",
      first_name: "",
      last_name: "",
      type:"",
      mobile: "",
      mobileOTP: "",
      emailOTP: "",
      isloginLoading: false,
      isregisterLoading: false,
      isloading: false,
      isLoggedIn: false,
      lat: "",
      long: "",
      forgotpass: "",
      otp:"",
      newPass:"",
      confirmNewpass:"",
      fields: {
        name: "",
        email: "",
        phone_number: "",
        password: "",
        confirmpassword: "",
        firstName: "",
        lastName: "",
        location: "",
        aadhar: "",
      },
      errors: {},
    };


 


    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      name: "required",
      firstName: "required",
      lastName: "required",
      email: "required|email",
      phone_number: "required|numeric|digits_between:10,12",
      password: "required",
      confirmpassword: "required",
      location: "required",
      aadhar: "required",
    });
    this.form.onformsubmit = (fields) => {
      console.log("============" + fields);
      if (this.state.isLogin) {
        //let value = agent.Auth.login(fields.email, fields.password);
        //console.log("=====================" + value);
        this.login(fields.name, fields.password);
      } else {
        if (this.state.isRegisterTwo) {
          console.log("=====================" + fields);
          this.register(fields.location, fields.aadhar);
        } else {
          this.setState((state) => ({ isSocialRegister: false }));

          this.openRegisterTwoModal();
          //this.closeRegisterModal();
          this.setState((state) => ({
            email: fields.email,
            password: fields.password,
            confirmpassword: fields.confirmpassword,
            first_name: fields.firstName,
            last_name: fields.lastName,
            mobile: fields.phone_number,
          }));
          // this.props.onRegisterSubmit(
          //   fields.firstName,
          //   fields.lastName,
          //   fields.email,
          //   fields.phone_number,
          //   fields.password
          // );
        }
      }
    };
  }
  componentWillReceiveProps() {
    console.log(
      "==========componentWillReceiveProps===========",
      this.props.auth.inProgress
    );
    // if (this.props.auth.inProgress == false) {
    //   this.closeLoginModal();
    // }
  }
 

  componentDidMount() {

 


    console.log(
      " window.localStorage.getItem()...." +
        window.localStorage.getItem("auth_token")
    );
    if (localStorage.getItem("isLoggedIn") === null) {
      this.setState((state) => ({
        isLoggedIn: false,
      }));
    }else{

      this.setState((state) => ({
        isLoggedIn: JSON.parse(window.localStorage.getItem("isLoggedIn")),
      }));
    }
    if (localStorage.getItem("isLoggedIn") === true) {
      this.checkOTPVerified();

    }


  }
   success = async (pos) => {
    
    console.log(pos)

}

 errors = (err) => {
    console.log(err)
}

  getCurrentlocation = ()=>{

    if ( navigator.permissions && navigator.permissions.query) {


      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(this.getlatlang).catch(err => console.error("error: " + err));
      } else {
        alert("Sorry Not available!");
      }
  
    }else if (navigator.geolocation) {
    
      this.getlatlang();
      }


    if ("geolocation" in navigator) {
           console.log("Available");
         } else {
           console.log("Not Available");
         }

   
  };
  getlatlang = () => {
    //then Navigation APIs
    console.log("position.coords.latitude");
    navigator.geolocation.getCurrentPosition(function (position) {
      //Your code here
      console.log("position.coords.latitude",position.coords.latitude);

     
      Geocode.setApiKey("AIzaSyDhKv7Zr8AbpBwP84oMc7mRgcqMOjmI340");

      // set response language. Defaults to english.
      Geocode.setLanguage("en");
      
      // set response region. Its optional.
      // A Geocoding request with region=es (Spain) will return the Spanish city.
      Geocode.setRegion("in");
      
      // set location_type filter . Its optional.
      // google geocoder returns more that one address for given lat/lng.
      // In some case we need one address as response for which google itself provides a location_type filter.
      // So we can easily parse the result for fetching address components
      // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
      // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
      Geocode.setLocationType("ROOFTOP");
      
      // Enable or disable logs. Its optional.
      Geocode.enableDebug();

      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city, state, country;
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  location= response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          console.log(city, state, country);
          console.log(address);
          return city
        },
        (error) => {
          console.error(error);
        }
      );


  }, function (e) {
      //Your error handling here
      console.log("position.coords.latitude",e.message);

  }, {
      enableHighAccuracy: true
  });
   this.setlocation(location);
  }
  setlocation= (city) => {
    this.setState({
      fields :{location: city},
    });
  }
  checkOTPVerified = () => {
    console.log("checking OTP Verification")
    if(window.localStorage.getItem("UserData")!=""){
      if(JSON.parse(window.localStorage.getItem("UserData")).verify_mobile!=1){
        this.openMobileOTPModal();
      }else  if(JSON.parse(window.localStorage.getItem("UserData")).verify_email!=1){
        this.openEmailOTPModal();
      }
    }
   
  }
  googlelogin = () => {
    console.log("google login clicked")
    useGoogleLogin({
      onSuccess: tokenResponse => console.log(tokenResponse),
    });
  };
     // Success Handler
     responseGoogleSuccess = (response) => {
      console.log(response);
    };
  
    // Error Handler
    responseGoogleError = (response) => {
      console.log(response);
    };
    responseFacebook = (response) => {
      console.log(response);
      var index = response.name.indexOf(' '); // Gets the first index where a space occours

      var firstname = response.name.substr(0, index);
      var lastname = response.name.substr(index+1);
 
       console.log("fb login result    ",response.name.substr(index+1))
 
       this.socialLogin(firstname,lastname,response.email,"fb_login")
    }


    socialLogin = (firstname,lastname,email,type) => {

      this.setState((state) => ({ isloginLoading: true }));
  
      const FormData = require("form-data");
    
      const form = new FormData();
      form.append("first_name",  firstname);
      form.append("last_name",  lastname);
      form.append('mobile_number',"");

      form.append("email_id",  email);
      form.append("type",  type);
   
      console.log("form data...." + form);
  
      commonService
        .CommonService("social_login",form)
        .then(async (res) => {
          if (res.status == 200) {
            if(res.is_first_time=="No"){

              toast.success(res.message, {
                position: "top-right",
                type: "success",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              });
              console.log("UserData....", res);

              window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
              window.localStorage.setItem("email", res.data[0].email_id);
              window.localStorage.setItem("mobile", res.data[0].mobile_number);
    
              window.localStorage.setItem("auth_token", res.data[0].auth_token);
              window.localStorage.setItem("isLoggedIn", true);
              window.location.reload();
    
              this.closeLoginModal();
              this.closeRegisterModal();


            }else{
               window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
              // window.localStorage.setItem("email", res.data[0].email_id);
              // window.localStorage.setItem("mobile", res.data[0].mobile_number);
    
               window.localStorage.setItem("auth_token", res.data[0].auth_token);
              // window.localStorage.setItem("isLoggedIn", true);
              this.closeLoginModal();
              this.closeRegisterModal();
              this.openSocialRegisterModal(firstname,lastname,email,type);
            }
            //this.props.onSubmit(res);
           
          } else {
            toast.success(res.message, {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          toast.success(err.toString(), {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        })
        .finally(() => {
          this.setState((state) => ({ isloginLoading: false }));
        });
    };
  

  sendEmailOTP = () => {
    this.setState((state) => ({ isloginLoading: true }));

    const FormData = require("form-data");
  
    const form = new FormData();
    form.append("email_id",  window.localStorage.getItem("email"));
    //form.append('device_token',token);
 
    console.log("form data...." + form);

    commonService
      .CommonService("send_otp_email",form)
      .then(async (res) => {
        if (res.status == 200) {
          //this.props.onSubmit(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success(err.toString(), {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        this.setState((state) => ({ isloginLoading: false }));
      });
  };



  verifyEmailOTP = () => {
    this.setState((state) => ({ isloginLoading: true }));

    const FormData = require("form-data");
  
    const form = new FormData();
    form.append("email_id",  window.localStorage.getItem("email"));
    form.append("otp", this.state.emailOTP);
    //form.append('device_token',token);
 
    console.log("form data...." + form);

    commonService
      .CommonService("verify_email",form)
      .then(async (res) => {
        if (res.status == 200) {
          //this.props.onSubmit(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          window.localStorage.setItem(
            "UserData",
            JSON.stringify(res.data[0])
          );
          this.closeEmailOTPModal();
          this.openSuccessModel();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success(err.toString(), {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        this.setState((state) => ({ isloginLoading: false }));
      });
  };

  sendMobileOTP = () => {
    this.setState((state) => ({ isloginLoading: true }));

    const FormData = require("form-data");
  
    const form = new FormData();
    form.append("mobile_number",  window.localStorage.getItem("mobile"));
    //form.append('device_token',token);

    console.log("form data...." + form);

    commonService
      .CommonService("send_otp_mobile",form)
      .then(async (res) => {
        if (res.status == 200) {
          //this.props.onSubmit(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success(err.toString(), {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        this.setState((state) => ({ isloginLoading: false }));
      });
  };
  verifyMobileOTP = () => {
    this.setState((state) => ({ isloginLoading: true }));

    const FormData = require("form-data");
  
    const form = new FormData();
    form.append("mobile_number",  window.localStorage.getItem("mobile"));
    form.append("otp", this.state.mobileOTP);
    //form.append('device_token',token);

    console.log("form data...." + form);

    commonService
      .CommonService("verify_mobile",form)
      .then(async (res) => {
        if (res.status == 200) {
          //this.props.onSubmit(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          window.localStorage.setItem(
            "UserData",
            JSON.stringify(res.data[0])
          );
          this.closeMobileOTPModal();
          this.openEmailOTPModal();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success(err.toString(), {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        this.setState((state) => ({ isloginLoading: false }));
      });
  };

  login = (email, pass) => {
    this.setState((state) => ({ isloginLoading: true }));

    const FormData = require("form-data");

    const form = new FormData();
    form.append("user_name", email);
    form.append("password", pass);
    //form.append('device_token',token);

    console.log("form data...." + form);

    userService
      .login(form)
      .then(async (res) => {
        if (res.status == 200) {
          //this.props.onSubmit(res);
          console.log("UserData....", res);

          window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
          window.localStorage.setItem("email", res.data[0].email_id);
          window.localStorage.setItem("mobile", res.data[0].mobile_number);

          window.localStorage.setItem("auth_token", res.data[0].auth_token);
          window.localStorage.setItem("isLoggedIn", true);
          window.location.reload();

          this.closeLoginModal();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success(err.toString(), {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        this.setState((state) => ({ isloginLoading: false }));
      });
  };

  register = async (location, aadhar) => {
    if (this.state.isChecked) {
      this.setState((state) => ({ isregisterLoading: true }));

      const looking_for = this.state.isBoth
        ? "projects,services"
        : this.state.isService
        ? "services"
        : "projects";
      const register_for = this.state.isCompany ? "company" : "individual";
      const FormData = require("form-data");

      const form = new FormData();
      form.append("first_name", this.state.first_name);
      form.append("last_name", this.state.last_name);
      form.append("mobile_number", this.state.mobile);
      form.append("email_id", this.state.email);
      form.append("register_for", register_for);
      form.append("looking_for[]", looking_for);
      form.append("location", location);
      if (this.state.isCompany) {
        form.append("company_name", aadhar);
      } else {
        form.append("aadhaar_number", aadhar);
      }
      form.append("password", this.state.isSocialRegister?"":this.state.password);
      form.append("confrm_password", this.state.isSocialRegister?"":this.state.confirmpassword);
      // form.append('device_token',token);
     

      commonService
        .CommonService(this.state.isSocialRegister?"social_register":"user_register",form)
        .then(async (res) => {
          if (res.status == 200) {
            // this.props.onRegisterSubmit(res);
            window.localStorage.setItem(
              "UserData",
              JSON.stringify(res.data[0])
            );
            window.localStorage.setItem("email", res.data[0].email_id);
            window.localStorage.setItem("mobile", res.data[0].mobile_number);
            window.localStorage.setItem("auth_token", res.data[0].auth_token);
            window.localStorage.setItem("isLoggedIn", true);
            window.location.reload();
            // await setAuthAsyncStorage(res);
            this.openMobileOTPModal();
          } else {
            toast.success(res.message, {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
        })
        .catch((err) => {
          toast.success(err.toString(), {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        })
        .finally(() => {
          this.setState((state) => ({ isregisterLoading: false }));
        });
    } else {
      toast.success("Please Accept the terms and Condition", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  forgotpassword = () => {
    this.setState((state) => ({ isloginLoading: true }));

    const FormData = require("form-data");
  
    const form = new FormData();
    form.append('user_name',this.state.forgotpass);

  
    //form.append('device_token',token);
 
    console.log("form data...." + form);

    commonService
      .CommonService("forgot_password",form)
      .then(async (res) => {
        if (res.status == 200) {
          //this.props.onSubmit(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        
          this.closeForgotPassModal();
          this.openResetPassModal();
      
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success(err.toString(), {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        this.setState((state) => ({ isloginLoading: false }));
      });
  };

  resetpassword = () => {
    this.setState((state) => ({ isloginLoading: true }));

    const FormData = require("form-data");
  
    const form = new FormData();
    form.append('user_name',this.state.forgotpass);
    form.append('otp',this.state.otp);
    form.append('password',this.state.newPass);
    form.append('confirm_password',this.state.confirmNewpass);

  
    //form.append('device_token',token);
 
    console.log("form data...." + form);

    commonService
      .CommonService("reset_password",form)
      .then(async (res) => {
        if (res.status == 200) {
          //this.props.onSubmit(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        
          this.closeResetPassModal();
      
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success(err.toString(), {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        this.closeResetPassModal();
        this.setState((state) => ({ isloginLoading: false }));
      });
  };


  redirect = (e) => {
    e.preventDefault()
    this.closeSuccessModal();
    this.closeRegisterModal();
    this.closeRegisterTwoModal();
    this.props.history.push("/profileEdit");
  };
  homeclick = (e) => {
    e.preventDefault()
console.log("logo click");
    this.props.history.push("/");
  };
  profileredirect = (e) => {
    e.preventDefault()

    this.props.history.push("/profile");
  };
  disputesredirect = (e) => {
    e.preventDefault()

    this.props.history.push("/disputes");
  };
  businessredirect = (e) => {
    e.preventDefault()

    this.props.history.push("/business");
  };
  myquotesRedirect = (e) => {
    e.preventDefault()

    this.props.history.push("/myquotes");
  };
  myContractRedirect = (e) => {
   e.preventDefault()
   this.props.history.push("/mycontracts");
  };

  openRegisterModal = () => {
    console.log("hhhhhh");
    this.setState((state) => ({ registerModelIsOpen: true, isLogin: false }));
    document.body.style.overflow = "hidden";
  };
  closeRegisterModal = () => {
    this.setState((state) => ({ registerModelIsOpen: false }));
    document.body.style.overflow = "unset";
  };
  openSuccessModel = () => {
    console.log("hhhhhh");
    this.setState((state) => ({ successPopupOpen: true }));
    document.body.style.overflow = "hidden";
  };
  closeSuccessModal = () => {
    this.setState((state) => ({ successPopupOpen: false }));
    document.body.style.overflow = "unset";
  };
  openTermsModel = () => {
    console.log("hhhhhh");
    this.setState((state) => ({ termsPopupIsOpen: true }));
    document.body.style.overflow = "hidden";
  };
  closeTermsModal = () => {
    this.setState((state) => ({ termsPopupIsOpen: false }));
    document.body.style.overflow = "unset";
  };

  openPrivacyModal = () => {
    console.log("hhhhhh");
    this.setState((state) => ({ privacyPopupIsOpen: true }));
    document.body.style.overflow = "hidden";
  };
  closePrivacyModal = () => {
    this.setState((state) => ({ privacyPopupIsOpen: false }));
    document.body.style.overflow = "unset";
  };

  openRegisterTwoModal = () => {
    console.log("hhhhhh");
    this.setState((state) => ({
      registerTwoModelIsOpen: true,
      isLogin: false,
      isRegisterTwo: true,
    }));
    document.body.style.overflow = "hidden";
  };
  closeRegisterTwoModal = () => {
    this.setState((state) => ({
      registerTwoModelIsOpen: false,
      isRegisterTwo: false,
    }));
    document.body.style.overflow = "unset";
  };

   showIcon = () => <i class="fa fa-eye" aria-hidden="true"></i>;
   hideIcon = () => <i class="fa fa-eye-slash" aria-hidden="true"></i>

  openLoginModal = () => {
    console.log("hhhhhh");
    this.setState((state) => ({ loginModelIsOpen: true, isLogin: true }));
    document.body.style.overflow = "hidden";
  };
  closeLoginModal = () => {
    this.setState((state) => ({ loginModelIsOpen: false }));
    document.body.style.overflow = "unset";
  };

  openMobileOTPModal = () => {
    console.log("hhhhhh");
    this.setState((state) => ({ mobileOTPPopupIsOpen: true, isLogin: true }));
    document.body.style.overflow = "hidden";
  };
  closeMobileOTPModal = () => {
    this.setState((state) => ({ mobileOTPPopupIsOpen: false }));
    document.body.style.overflow = "unset";
  };

  openEmailOTPModal = () => {
    console.log("hhhhhh");
    this.setState((state) => ({ emailOTPPopupIsOpen: true, isLogin: true }));
    document.body.style.overflow = "hidden";
  };
  closeEmailOTPModal = () => {
    this.setState((state) => ({ emailOTPPopupIsOpen: false }));
    document.body.style.overflow = "unset";
  };

  openSocialRegisterModal = (fname,lname,email,type) => {
    console.log("hhhhhh");
    this.setState((state) => ({ socialRegisterIsOpen: true,first_name:fname,last_name:lname,email,type }));
    document.body.style.overflow = "hidden";
  };
  closeSocialRegisterModal = () => {
    this.setState((state) => ({ socialRegisterIsOpen: false }));
    document.body.style.overflow = "unset";
  };



  openForgotPassModal = () => {
    console.log("hhhhhh");
    this.setState({ forgotpass: this.state.fields.name });
    this.setState((state) => ({ forgotPassIsOpen: true }));
    document.body.style.overflow = "hidden";
  };
  closeForgotPassModal = () => {
    this.setState((state) => ({ forgotPassIsOpen: false }));
    document.body.style.overflow = "unset";
  };


  openResetPassModal = () => {
    console.log("hhhhhh");
    this.setState((state) => ({ resetPassIsOpen: true }));
    document.body.style.overflow = "hidden";
  };
  closeResetPassModal = () => {
    this.setState((state) => ({ resetPassIsOpen: false }));
    document.body.style.overflow = "unset";
  };

  logout = (e) => {
    e.preventDefault();
    googleLogout();
    window.localStorage.setItem("UserData", "");
    window.localStorage.setItem("isLoggedIn", false);
    window.localStorage.setItem("auth_token", "");
    window.localStorage.setItem("email", "");
    window.localStorage.setItem("mobile", "");
    this.props.history.push("/");
    window.location.reload();

  };
  onCompanyClick = () => {
    console.log(true);

    this.setState((state) => ({ isCompany: true }));
  };

  onIndividualClick = () => {
    console.log(false);

    this.setState((state) => ({ isCompany: false }));
  };

  onProjectClick = () => {
    console.log(true);

    this.setState((state) => ({ isService: false }));
  };

  onServiceClick = () => {
    console.log(false);

    this.setState((state) => ({ isService: true }));
  };

  handleIsBoth = () => {
    console.log(false);

    this.setState((state) => ({ isBoth: !this.state.isBoth }));
  };
  handleIsCheckBox = () => {
    console.log(false);

    this.setState((state) => ({ isChecked: !this.state.isChecked }));
  };
  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(this.state.email);
  // };
  handleEmailInput = (event) => {
    console.log(event.target.value);

    this.setState({
      email: event.target.value,
    });
  };
  handlePasswordInput(event) {
    this.setState({
      password: event.target.value,
    });
  }

  render() {
    console.log("render++++++", this.props.auth.inProgress);
    return (
      <div>
     
        <div className={this.state.isLoggedIn == true ? "navbar-area" : "navbar-area before-login"}>
          <div className="main-responsive-nav">
            <div className="container">
              <div className="main-responsive-menu">
            
              {this.state.isLoggedIn == true ? (
                <div className="logo" >
                    <img  onClick={this.homeclick} src="/assets/images/newlogo.png" alt="logo" />
                </div>):null}

              </div>
            </div>
          </div>
          <div className="main-navbar">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
              {this.state.isLoggedIn == true ? (  <a className="navbar-brand" href="#" onClick={this.homeclick}>
                  <img src="/assets/images/newlogo.png" alt="logo" />
                </a>):null}
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                   {this.state.isLoggedIn == true ? (
                  <ul className="navbar-nav m-auto">
                    <li className="nav-item">
                      <a
                        href={baseLink+"/mycontracts"}
                        className="nav-link"
                       // onClick={this.myContractRedirect}
                      >
                        Jobs I posted
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href={baseLink+"/myquotes"}
                        className="nav-link"
                        //onClick={this.myquotesRedirect}
                      >
                        Jobs I applied
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href={baseLink+"/myjobs"}
                        className="nav-link"
                        // onClick={(e) => {
                        //   this.props.history.push("/myjobs");
                        // }}
                      >
                        Jobs I'm doing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href={baseLink+"/business"}
                        className="nav-link"
                      >
                        For Business
                      </a>
                    </li>
                    <li className="nav-item dropdownlinks">
                      <a href="#" className="nav-link active">
                        More
                        <i className="bx bx-chevron-down" />
                      </a>
                      <ul className="dropdown-menu  ">
                      <li className="dd-horizontal">
                          <a
                           href={baseLink+"/payment"}
                            className="nav-link active"
                           // onClick={()=>{this.props.history.push("/payment")}}
                          >
                            Payment
                          </a>
                        </li>
                        <li className="dd-horizontal">
                          <a
                            href={baseLink+"/profile"}
                            className="nav-link active"
                            //onClick={this.profileredirect}
                          >
                            Profile
                          </a>
                        </li>
                        {/* <li className="dd-horizontal">
                          <a
                            href="#"
                            className="nav-link active"
                            onClick={this.redirect}
                          >
                            Profile Edit
                          </a>
                        </li> */}
                        <li className="dd-horizontal">
                          <a
                            href="#"
                            className="nav-link active"
                            onClick={this.disputesredirect}
                          >
                            Disputes
                          </a>
                        </li>
                        {/* <li className="dd-horizontal">
                          <a
                           href={baseLink+"/business"}
                            className="nav-link active"
                          >
                            Business
                          </a>
                        </li> */}
                        <li className="dd-horizontal">
                          <a
                            href="#"
                            className="nav-link active"
                            onClick={this.logout}
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                    {/* <li className="nav-item">
                      <a
                        href="#"
                        onClick={this.openLoginModal}
                        className="nav-link"
                      >
                        Contact
                      </a>
                    </li> */}
                  </ul>):(                  <ul className="navbar-nav m-auto">
</ul>)}
                  <div className="others-options d-flex align-items-center">
                    {/* <div className="option-item">
                      <div className="search-box">
                        <i className="flaticon-search" />
                      </div>
                    </div> */}
                    {this.state.isLoggedIn == false ? (
                      <div className="option-item">
                        <div className="navbar-btn">
                          <Link
                            to="#"
                            onClick={this.openRegisterModal}
                            className="default-btn"
                          >
                            Register
                          </Link>
                          <Link
                            to="#"
                            onClick={this.openLoginModal}
                            className="default-btn"
                          >
                            Login
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="option-item">
                        <div className="navbar-btn">
                          {/* <Link
                          to="#"
                          onClick={this.openRegisterModal}
                          className="default-btn"
                        >
                          Register
                        </Link> */}
                          <Link
                            to={{ pathname: `/postjob/` }}
                            // onClick={this.logout}
                            className="default-btn"
                          >
                            Post a Job
                          </Link>
                          <NavigationBtn />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </nav>
            </div>
          </div>
         
        </div>
        <Modal
          isOpen={this.state.registerModelIsOpen}
          onRequestClose={this.closeRegisterModal}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <div>
            <div>
              <div className="modal-dialog loginpopup" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Join <span>GigFWS</span> Group
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.closeRegisterModal}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.form.handleSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name (Min 3 Characters)"
                          name="firstName"
                          onBlur={this.form.handleBlurEvent}
                          onChange={this.form.handleChangeEvent}
                          value={this.state.fields.firstName}
                        />
                        <label className="error">
                          {" "}
                          {this.state.errors.firstName
                            ? this.state.errors.firstName
                            : ""}
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name (Min 3 Characters)"
                          name="lastName"
                          onBlur={this.form.handleBlurEvent}
                          onChange={this.form.handleChangeEvent}
                          value={this.state.fields.lastName}
                        />
                        <label className="error">
                          {" "}
                          {this.state.errors.lastName
                            ? this.state.errors.lastName
                            : ""}
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email ID"
                          name="email"
                          onBlur={this.form.handleBlurEvent}
                          onChange={this.form.handleChangeEvent}
                          value={this.state.fields.email}
                        />
                        <label className="error">
                          {" "}
                          {this.state.errors.email
                            ? this.state.errors.email
                            : ""}
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Mobile Number"
                          name="phone_number"
                          onBlur={this.form.handleBlurEvent}
                          onChange={this.form.handleChangeEvent}
                          value={this.state.fields.mobile}
                        />
                        <label className="error">
                          {" "}
                          {this.state.errors.phone_number
                            ? this.state.errors.phone_number
                            : ""}
                        </label>
                      </div>
                      <div className="form-group password-sec">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          onBlur={this.form.handleBlurEvent}
                          onChange={this.form.handleChangeEvent}
                          value={this.state.fields.password}
                          ref = {this.inputRef1}

                        />
                          <ReactPasswordToggleIcon 
              inputRef={this.inputRef1} 
              showIcon={this.hideIcon}
              hideIcon={this.showIcon}
            />       
                        <label className="error">
                          {" "}
                          {this.state.errors.password
                            ? this.state.errors.password
                            : ""}
                        </label>
                      </div>
                      <div className="form-group con-password-sec">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          name="confirmpassword"
                          onBlur={this.form.handleBlurEvent}
                          onChange={this.form.handleChangeEvent}
                          value={this.state.fields.confirmpassword}
                          ref = {this.inputRef}
                        />
                        <ReactPasswordToggleIcon 
              inputRef={this.inputRef} 
              showIcon={this.hideIcon}
              hideIcon={this.showIcon}
            />       
                       
                      </div>

                    
                          
                      <button type="submit" className="btn btn-default">
                        CONTINUE
                      </button>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <p className="horizontal-line">or</p>
                    <ul className="social-login">
                    <li>
                        <div style={{display: 'flex', justifyContent: 'center',margin:'auto',width:'max-content'}}>
                    <GoogleLogin
                    style={{display: 'flex', justifyContent: 'center'}}
                     render={renderProps => (
      <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
    )}
  onSuccess={credentialResponse => {
    const realUserData = getDecodedOAuthJwtGoogle(credentialResponse.credential) // credentials === JWT token
    realUserData.then(result => {
      var index = result.getPayload().name.indexOf(' '); // Gets the first index where a space occours

     var firstname = result.getPayload().name.substr(0, index);
     var lastname = result.getPayload().name.substr(index+1);

      console.log("google login result    ",result.getPayload().name.substr(index+1))

      this.socialLogin(firstname,lastname,result.getPayload().email,"gmail_login")

    }).catch( err => console.log(err));


  }}
  onError={() => {
    console.log('Login Failed');
  }}

/></div>

                      </li>
                          <li>
                        
                          <FacebookLogin
            appId="550051726058276"
            autoLoad ={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            render={renderProps => (
              <a href="#" onClick={renderProps.onClick}>
              <img src="assets/images/facebook.png" />{" "}
              <span>Continue with Facebook</span>
            </a>
            )}
          />
                           
                          </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.registerTwoModelIsOpen}
          onRequestClose={this.closeRegisterTwoModal}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <LoadingOverlay>
            <div>
              <div>
                <div className="modal-dialog loginpopup" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        Join <span>GigFWS</span> Group
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeRegisterTwoModal}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <form onSubmit={this.form.handleSubmit}>
                      <div className="modal-body">
                        <div className="form-group">
                          <Autocomplete
                           apiKey="AIzaSyDhKv7Zr8AbpBwP84oMc7mRgcqMOjmI340"
                           onPlaceSelected={(place) => {
                             console.log(place);
                             this.setState({
                              fields :{location: place.formatted_address},
                            });
                           }}
                           options={{
                             componentRestrictions: { country: "in" },
                           }}
                            type="text"
                            className="form-control"
                            placeholder="Enter Location"
                            name="location"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.location}
                          />
                          <label className="location" onClick={
                            this.getCurrentlocation
                          }>
                            <i className="fa fa-map" aria-hidden="true" /> Use
                            Current Location
                          </label>
                          <label className="error">
                            {" "}
                            {this.state.errors.location
                              ? this.state.errors.location
                              : ""}
                          </label>
                        </div>
                        <div className="tab-section">
                          <div className="tab services-list-tab">
                            <p className="tab-title">I am</p>
                            <ul className="tabs">
                              <li>
                                <Link
                                  to="#"
                                  style={{
                                    borderColor:
                                      this.state.isCompany === false
                                        ? "#F85100"
                                        : "transparent",
                                  }}
                                  onClick={this.onIndividualClick}
                                >
                                  <span>
                                    <img src="assets/images/user.png" />{" "}
                                    Individual
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  style={{
                                    borderColor:
                                      this.state.isCompany === true
                                        ? "#F85100"
                                        : "transparent",
                                  }}
                                  onClick={this.onCompanyClick}
                                >
                                  <span>
                                    <img src="assets/images/buildings.png" />{" "}
                                    Company
                                  </span>
                                </Link>
                              </li>
                            </ul>
                            <div className="tab_content">
                              {/* <div className="tabs_item">
                                <div className="row align-items-center">
                                  <div className="col-lg-12">
                                    <div className="services-tab-content">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder={
                                            this.state.isCompany
                                              ? "GSTIN"
                                              : "Aadhaar Number (Mandatory)"
                                          }
                                          name="aadhar"
                                          onBlur={this.form.handleBlurEvent}
                                          onChange={this.form.handleChangeEvent}
                                          value={this.state.fields.aadhar}
                                          data-attribute-name={
                                            this.state.isCompany
                                              ? "GSTIN"
                                              : "Aadhaar Number"
                                          }
                                        />
                                        <label className="error">
                                          {" "}
                                          {this.state.errors.aadhar
                                            ? this.state.errors.aadhar
                                            : ""}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              {/* ) : (
                            <div className="tabs_item">
                              <div className="row align-items-center">
                                <div className="col-lg-12">
                                  <div className="services-tab-content">
                                    <form action="/action_page.php">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="GSTIN"
                                          name="gst"
                                          onBlur={this.form.handleBlurEvent}
                                          onChange={this.form.handleChangeEvent}
                                          value={this.state.fields.gst}
                                        />
                                        <label className="error">
                                          * Min 3 Characters
                                        </label>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )} */}
                            </div>
                          </div>
                          <div className="tab services-list-tab">
                            <div className="row">
                              <div className="col-6">
                                <p className="tab-title">Looking for</p>
                              </div>
                              <div className="col-6">
                                <div className="toggle-switch" tabIndex={0}>
                                  <input
                                    type="checkbox"
                                    name="my_checkbox"
                                    defaultValue="yes"
                                    id="checkbox-id"
                                    defaultChecked={false}
                                    onChange={this.handleIsBoth}
                                  />
                                  <label htmlFor="checkbox-id">
                                    <div className="area" aria-hidden="true">
                                      <div className="background">
                                        <div className="handle" />
                                      </div>
                                    </div>
                                    Both
                                  </label>
                                </div>
                              </div>
                            </div>
                            <ul className="tabs tabs1">
                              <li>
                                <Link
                                  to="#"
                                  style={{
                                    borderColor:
                                      this.state.isBoth === true
                                        ? "#F85100"
                                        : this.state.isService === false
                                        ? "#F85100"
                                        : "transparent",
                                  }}
                                  onClick={this.onProjectClick}
                                >
                                  <span>
                                    <img src="assets/images/project.png" />{" "}
                                    Projects
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="#"
                                  style={{
                                    borderColor:
                                      this.state.isBoth === true
                                        ? "#F85100"
                                        : this.state.isService === true
                                        ? "#F85100"
                                        : "transparent",
                                  }}
                                  onClick={this.onServiceClick}
                                >
                                  <span>
                                    <img src="assets/images/services.png" />{" "}
                                    Services
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="new">
                          <p>I agree with</p>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              name="checkbox"
                              id="html"
                              defaultChecked={this.state.isChecked}
                              onChange={this.handleIsCheckBox}
                            />
                            <label htmlFor="html">
                            <a href="/terms-and-conditions" target="_blank">
                              Terms and Conditions</a>
                              <br />
                              <a href="/privacy-policy" target="_blank">
                              Privacy Policy</a>
                            </label>
                          </div>
                          <button type="submit" className="btn register">
                            REGISTER NOW
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <Loader loading={this.state.isregisterLoading} />
          </LoadingOverlay>
        </Modal>

        <Modal
          isOpen={this.state.successPopupOpen}
          onRequestClose={this.closeSuccessPopup}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <div className="modal-dialog loginpopup" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Join <span>GigFWS</span> Group
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e)=>{
                    this.redirect(e)
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="success">
                  <h1 className="title">Success</h1>
                  <img src="assets/images/success.png" />
                  <p>
                    You have successfully registered to be part of the GigFWS Group
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <div className="new">
                  <button
                    className="btn register"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e)=>{
                      this.redirect(e)
                    }}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.termsPopupIsOpen}
          onRequestClose={this.closeTermsPopup}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <div className="modal-dialog loginpopup" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Terms and Conditions</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.closeTermsPopup}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="terms-condition">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <div className="new">
                  <form>
                    <p>I agree with</p>
                    <div className="form-group">
                      <input type="checkbox" id="html" />
                      <label htmlFor="html">
                        Terms and Conditions
                        <br />
                        Privacy Policy
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.privacyPopupIsOpen}
          onRequestClose={this.closeprivacyPopup}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <div className="modal-dialog loginpopup" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Privacy Policy</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.closeprivacyPopup}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="terms-condition">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <div className="new">
                  <form>
                    <p>I agree with</p>
                    <div className="form-group">
                      <input type="checkbox" id="html" />
                      <label htmlFor="html">
                        Terms and Conditions
                        <br />
                        Privacy Policy
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.loginModelIsOpen}
          onRequestClose={this.closeLoginModal}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <LoadingOverlay>
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <div>
              <div>
                <div
                  className="modal-dialog loginpopup dashboard signin"
                  role="document"
                >
                  <div className="modal-content">
                    <div>
                      <div className="modal-header">
                        <h5 className="modal-title">
                          Join <span>GigFWS</span> Group
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.closeLoginModal}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <h2>Welcome</h2>
                        <form onSubmit={this.form.handleSubmit}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email ID or Mobile Number"
                              name="name"
                              onBlur={this.form.handleBlurEvent}
                              onChange={this.form.handleChangeEvent}
                              value={this.state.fields.name}
                            />
                            <div>
                              {" "}
                              <label className="error">
                                {" "}
                                {this.state.errors.name
                                  ? this.state.errors.name
                                  : ""}
                              </label>
                            </div>
                          </div>
                          <div className="form-group password">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              name="password"
                              onBlur={this.form.handleBlurEvent}
                              onChange={this.form.handleChangeEvent}
                              value={this.state.fields.password}
                              ref={this.inputRef}
                            />
 <ReactPasswordToggleIcon 
              inputRef={this.inputRef} 
              showIcon={this.hideIcon}
              hideIcon={this.showIcon}
            />                            <label className="error">
                              {" "}
                              {this.state.errors.password
                                ? this.state.errors.password
                                : ""}
                            </label>
                          </div>
                          <div className="form-group" onClick={(e)=>{
                            e.preventDefault();
                            this.closeLoginModal();
                            this.openForgotPassModal();
                          }}>
                            <label className="forgot">Forgot Password</label>
                          </div>
                          <button type="submit" className="btn btn-default">
                            Sign in
                          </button>
                        </form>
                      </div>
                      <div className="modal-footer">
                        <p className="horizontal-line">or</p>
                        <ul className="social-login">
                        {/* <li>
                        <a href="#" onClick={() => this.googlelogin()}>
                          <img src="assets/images/google.png" />
                          <span>Continue with Google</span>
                        </a>
                  
                      </li> */}
                      <li>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                    <GoogleLogin
                    style={{display: 'flex', justifyContent: 'center'}}
                     render={renderProps => (
      <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
    )}
  onSuccess={credentialResponse => {
    const realUserData = getDecodedOAuthJwtGoogle(credentialResponse.credential) // credentials === JWT token
    realUserData.then(result => {
      var index = result.getPayload().name.indexOf(' '); // Gets the first index where a space occours

     var firstname = result.getPayload().name.substr(0, index);
     var lastname = result.getPayload().name.substr(index+1);

      console.log("google login result    ",result.getPayload().name.substr(index+1))

      this.socialLogin(firstname,lastname,result.getPayload().email,"gmail_login")

    }).catch( err => console.log(err));


  }}
  onError={() => {
    console.log('Login Failed');
  }}

/></div>

                      </li>
                          <li>
                        
<FacebookLogin
            appId="550051726058276"
            autoLoad ={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            render={renderProps => (
              <a href="#" onClick={renderProps.onClick}>
              <img src="assets/images/facebook.png" />{" "}
              <span>Continue with Facebook</span>
            </a>
            )}
          />
                           
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Loader loading={this.state.isloginLoading} />
          </LoadingOverlay>
        </Modal>

        <Modal
          isOpen={this.state.mobileOTPPopupIsOpen}
          onRequestClose={this.closeMobileOTPModal}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <LoadingOverlay>
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <div>
              <div>
                <div
                  className="modal-dialog loginpopup dashboard signin"
                  role="document"
                >
                  <div className="modal-content">
                    <div>
                      <div className="modal-header">
                        <h5 className="modal-title">
                          Join <span>GigFWS</span> Group
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.closeMobileOTPModal}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <h2>Enter OTP from your mobile</h2>
                        <form onSubmit={this.form.handleSubmit}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="OTP"
                              name="name"
                              onChange={(e) => {
                                console.log(e.target.value)
                                if(e.target.value!=null)
                                this.setState({ mobileOTP: e.target.value });
                              }}
                              value={this.state.mobileOTP}
                            />
                            
                          </div>                         
                          <div className="form-group" onClick={(e)=>{
                            this.sendMobileOTP()
                          }}>                           

                            <label className="forgot"> <a href="#">Resend OTP</a></label>
                          </div>
                          <button  className="btn btn-default" onClick={()=>{
                            this.verifyMobileOTP()
                          }} >
                            Verify
                          </button>
                        </form>
                      </div>
                      <div className="modal-footer">
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Loader loading={this.state.isloginLoading} />
          </LoadingOverlay>
        </Modal>
        <Modal
          isOpen={this.state.emailOTPPopupIsOpen}
          onRequestClose={this.closeEmailOTPModal}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <LoadingOverlay>
            {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
            <div>
              <div>
                <div
                  className="modal-dialog loginpopup dashboard signin"
                  role="document"
                >
                  <div className="modal-content">
                    <div>
                      <div className="modal-header">
                        <h5 className="modal-title">
                          Join <span>GigFWS</span> Group
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.closeEmailOTPModal}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <h2>Enter OTP from email</h2>
                        <form onSubmit={this.form.handleSubmit}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="OTP"
                              name="name"
                              onChange={(e) => {
                                if(e.target.value!=null)
                                this.setState({ emailOTP: e.target.value });
                              }}
                              value={this.state.emailOTP}
                            />
                            <div>
                              {" "}
                              
                            </div>
                          </div>
                        
                          <div className="form-group" onClick={(e)=>{
                            this.sendEmailOTP()
                          }}>
                          <label className="forgot"> <a href="#">Resend OTP</a></label>

                          </div>
                          <button className="btn btn-default" onClick={()=>{
                            this.verifyEmailOTP()
                          }} >
                            Verify
                          </button>
                        </form>
                      </div>
                      <div className="modal-footer">
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Loader loading={this.state.isloginLoading} />
          </LoadingOverlay>
        </Modal>
        <Modal
          isOpen={this.state.socialRegisterIsOpen}
          onRequestClose={this.closeSocialRegisterModal}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
                    <LoadingOverlay>

          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <div>
            <div>
              <div className="modal-dialog loginpopup" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Update <span>GigFWS</span> Profile
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.closeSocialRegisterModal}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name (Min 3 Characters)"
                          name="firstName"
                          onChange={(e) => {
                            if(e.target.value!=null)
                            this.setState({ first_name: e.target.value });
                          }}
                          value={this.state.first_name}
                        />
                        <label className="error">
                          {" "}
                          {this.state.errors.firstName
                            ? this.state.errors.firstName
                            : ""}
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name (Min 3 Characters)"
                          name="lastName"
                          onChange={(e) => {
                            if(e.target.value!=null)
                            this.setState({ last_name: e.target.value });
                          }}
                          value={this.state.last_name}
                        />
                        <label className="error">
                          {" "}
                          {this.state.errors.lastName
                            ? this.state.errors.lastName
                            : ""}
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email ID"
                          name="email"
                          onChange={(e) => {
                            if(e.target.value!=null)
                            this.setState({ email: e.target.value });
                          }}
                          value={this.state.email}
                        />
                        <label className="error">
                          {" "}
                          {this.state.errors.email
                            ? this.state.errors.email
                            : ""}
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Mobile Number"
                          name="phone_number"
                          onChange={(e) => {
                            if(e.target.value!=null)
                            this.setState({ mobile: e.target.value });
                          }}
                          value={this.state.mobile}
                        />
                        <label className="error">
                          {" "}
                          {this.state.errors.phone_number
                            ? this.state.errors.phone_number
                            : ""}
                        </label>
                      </div>
                     
                      <button type="submit" className="btn btn-default" onClick={()=>{
                                     this.setState((state) => ({ isSocialRegister: true }));
                                     this.closeSocialRegisterModal();
                                 this.openRegisterTwoModal();
                          }}  >
                        UPDATE
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader loading={this.state.isloginLoading} />
          </LoadingOverlay>
        </Modal>


        <Modal
          isOpen={this.state.forgotPassIsOpen}
          onRequestClose={this.closeForgotPassModal}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
                    <LoadingOverlay>

          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <div>
            <div>
              <div className="modal-dialog loginpopup" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Forgot <span>Password?</span> 
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.closeForgotPassModal}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter email or mobile number"
                          name="forgotpass"
                          onChange={(e) => {
                            if(e.target.value!=null)
                            this.setState({ forgotpass: e.target.value });
                          }}                          
                          value={this.state.forgotpass}
                        />
                        <label className="error">
                          {" "}
                          {this.state.errors.forgotpass
                            ? this.state.errors.forgotpass
                            : ""}
                        </label>
                      </div>
                     
                      <button type="submit" className="btn btn-default" onClick={()=>{
                                 this.forgotpassword();
                          }}  >
                        SUBMIT
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader loading={this.state.isloginLoading} />
          </LoadingOverlay>
        </Modal>

        <Modal
          isOpen={this.state.resetPassIsOpen}
          onRequestClose={this.closeResetPassModal}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
                    <LoadingOverlay>

          {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
          <div>
            <div>
              <div className="modal-dialog loginpopup reset-your-password" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Reset your <span>Password</span> 
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.closeResetPassModal}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter OTP"
                          name="otp"
                          onChange={(e) => {
                            if(e.target.value!=null)
                            this.setState({ otp: e.target.value });
                          }}
                          value={this.state.otp}
                        />
                        
                      </div>
                      <div className="form-group new-password">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="New Password"
                          name="newpassword"
                          onChange={(e) => {
                            if(e.target.value!=null)
                            this.setState({ newPass: e.target.value });
                          }}
                          value={this.state.newPass}
                          ref = {this.newPassword1}
                          />

                           <ReactPasswordToggleIcon 
                            inputRef={this.newPassword1} 
                            showIcon={this.hideIcon}
                            hideIcon={this.showIcon}
                          />   

                        
                        <label className="error">
                          {" "}
                          {this.state.errors.forgotpass
                            ? this.state.errors.forgotpass
                            : ""}
                        </label>
                      </div>
                      <div className="form-group cnew-password">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm New Password"
                          name="confirmpass"
                          onChange={(e) => {
                            if(e.target.value!=null)
                            this.setState({ confirmNewpass: e.target.value });
                          }}
                          value={this.state.confirmNewpass}
                          ref = {this.confirmNewPassword}
                        />
                        <ReactPasswordToggleIcon 
                            inputRef={this.confirmNewPassword} 
                            showIcon={this.hideIcon}
                            hideIcon={this.showIcon}
                          /> 
                        <label className="error">
                          {" "}
                          {this.state.errors.forgotpass
                            ? this.state.errors.forgotpass
                            : ""}
                        </label>
                      </div>
                      <button type="submit" className="btn btn-default" onClick={()=>{
                                 this.resetpassword();
                          }}  >
                        SUBMIT
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader loading={this.state.isloginLoading} />
          </LoadingOverlay>
        </Modal>


      </div>
    );
  }
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 1000 },
};
export default  connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
