import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { profileUpdateService } from "../../Network/services/profileUpdateService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const ReferenceUpdateView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);

  const [projectLocation, setProjectLocation] = useState("local");

  const [personalName, setpersonalName] = useState("");
  const [personalMobile, setpersonalMobile] = useState("");
  const [personalProfession, setpersonalProfession] = useState("");
  const [personalUsername, setpersonalUsername] = useState("");
  const [personalName1, setpersonalName1] = useState("");
  const [personalMobile1, setpersonalMobile1] = useState("");
  const [personalProfession1, setpersonalProfession1] = useState("");
  const [personalUsername1, setpersonalUsername1] = useState("");

  const [professionalName, setprofessionalName] = useState("");
  const [professionalMobile, setprofessionalMobile] = useState("");
  const [professionalProfession, setprofessionalProfession] = useState("");
  const [professionalUsername, setprofessionalUsername] = useState("");
  const [professionalName1, setprofessionalName1] = useState("");
  const [professionalMobile1, setprofessionalMobile1] = useState("");
  const [professionalProfession1, setprofessionalProfession1] = useState("");
  const [professionalUsername1, setprofessionalUsername1] = useState("");

  const [morePersonal, setMorePersonal] = useState(false);
  const [moreProfessional, setMoreProfessional] = useState(false);
  const [loading, setLoading] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [name, setName] = useState("");
  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);
    console.log("user====================" + JSON.stringify(user));

    setProjectLocation(user.project_take_from);
    let personal_preference_name1_array = user.personal_preference_name1.split(
      ","
    );
    let personal_preference_mobile1_array = user.personal_preference_mobile1.split(
      ","
    );
    let personal_preference_profession1_array = user.personal_preference_profession1.split(
      ","
    );
    let personal_prefe_fws_username1_array = user.personal_prefe_fws_username1.split(
      ","
    );

    console.log(personal_preference_name1_array);
    if (personal_preference_name1_array.length > 0) {
      if (personal_preference_name1_array.length > 1) {
        setpersonalName(personal_preference_name1_array[0]);
        setpersonalName1(personal_preference_name1_array[1]);
        setMorePersonal(true);
      } else {
        setpersonalName(personal_preference_name1_array[0]);
      }
    }

    if (personal_preference_mobile1_array.length > 0) {
      if (personal_preference_mobile1_array.length > 1) {
        setpersonalMobile(personal_preference_mobile1_array[0]);
        setpersonalMobile1(personal_preference_mobile1_array[1]);
        setMorePersonal(true);
      } else {
        setpersonalMobile(personal_preference_mobile1_array[0]);
      }
    }
    if (personal_preference_profession1_array.length > 0) {
      if (personal_preference_profession1_array.length > 1) {
        setpersonalProfession(personal_preference_profession1_array[0]);
        setpersonalProfession1(personal_preference_profession1_array[1]);
        setMorePersonal(true);
      } else {
        setpersonalProfession(personal_preference_profession1_array[0]);
      }
    }
    if (personal_prefe_fws_username1_array.length > 0) {
      if (personal_prefe_fws_username1_array.length > 1) {
        setpersonalUsername(personal_prefe_fws_username1_array[0]);
        setpersonalUsername1(personal_prefe_fws_username1_array[1]);
        setMorePersonal(true);
      } else {
        setpersonalUsername(personal_prefe_fws_username1_array[0]);
      }
    }

    let personal_preference_name2_array = user.personal_preference_name2.split(
      ","
    );
    let personal_preference_mobile2_array = user.personal_preference_mobile2.split(
      ","
    );
    let personal_preference_profession2_array = user.personal_preference_profession2.split(
      ","
    );
    let personal_prefe_fws_username2_array = user.personal_prefe_fws_username2.split(
      ","
    );
    if (personal_preference_name2_array.length > 0) {
      if (personal_preference_name2_array.length > 1) {
        setprofessionalName(personal_preference_name2_array[0]);
        setprofessionalName1(personal_preference_name2_array[1]);
        setMoreProfessional(true);
      } else {
        setprofessionalName(personal_preference_name2_array[0]);
      }
    }

    if (personal_preference_mobile2_array.length > 0) {
      if (personal_preference_mobile2_array.length > 1) {
        setprofessionalMobile(personal_preference_mobile2_array[0]);
        setprofessionalMobile1(personal_preference_mobile2_array[1]);
        setMoreProfessional(true);
      } else {
        setprofessionalMobile(personal_preference_mobile2_array[0]);
      }
    }
    if (personal_preference_profession2_array.length > 0) {
      if (personal_preference_profession2_array.length > 1) {
        setprofessionalProfession(personal_preference_profession2_array[0]);
        setprofessionalProfession1(personal_preference_profession2_array[1]);
        setMoreProfessional(true);
      } else {
        setprofessionalProfession(personal_preference_profession2_array[0]);
      }
    }
    if (personal_prefe_fws_username2_array.length > 0) {
      if (personal_prefe_fws_username2_array.length > 1) {
        setprofessionalUsername(personal_prefe_fws_username2_array[0]);
        setprofessionalUsername1(personal_prefe_fws_username2_array[1]);
        setMoreProfessional(true);
      } else {
        setprofessionalUsername(personal_prefe_fws_username2_array[0]);
      }
    }
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const profileupdate = () => {
    setLoading(true);
    let name =
      morePersonal == false ? personalName : personalName + "," + personalName1;
    let mobile =
      morePersonal == false
        ? personalMobile
        : personalMobile + "," + personalMobile1;
    let profession =
      morePersonal == false
        ? personalProfession
        : personalProfession + "," + personalProfession1;
    let username =
      morePersonal == false
        ? personalUsername
        : personalUsername + "," + personalUsername1;

    let professionalname =
      moreProfessional == false
        ? professionalName
        : professionalName + "," + professionalName1;
    let professionalmobile =
      moreProfessional == false
        ? professionalMobile
        : professionalMobile + "," + professionalMobile1;
    let professionalprofession =
      moreProfessional == false
        ? professionalProfession
        : professionalProfession + "," + professionalProfession1;
    let professionalusername =
      moreProfessional == false
        ? professionalUsername
        : professionalUsername + "," + professionalUsername1;

    const form = new FormData();
    form.append("project_take_from", "");

    form.append("personal_preference_name1[]", name);
    form.append("personal_preference_mobile1[]", mobile);
    form.append("personal_preference_profession1[]", profession);
    form.append("personal_prefe_fws_username1[]", username);

    form.append("personal_preference_name2[]", professionalname);
    form.append("personal_preference_mobile2[]", professionalmobile);
    form.append("personal_preference_profession2[]", professionalprofession);
    form.append("personal_prefe_fws_username2[]", professionalusername);

    profileUpdateService
      .profileUpdatecall(form)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
          window.localStorage.setItem("auth_token", res.data[0].auth_token);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          // redirect();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const redirect = () => {
    history.push("/referenceUpdate");
  };
  return (
    <section className="explore explore1">
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="#!">
                      <i className="bx bx-chevron-left" /> Back to all jobs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="onbord-section">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="title">
                John Doe George,tell us a bit more about yourself
              </h1>
              <p className="subtitle">
                Future Workforce Solutions needs the below information to help
                you better.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="board-box board-box2">
                <div className="one">
                  <div className="row">
                    <div className="col-md-12">
                      <p>Projects you would like to take from</p>
                    </div>
                  </div>
                </div>
                <div className="two">
                  <div className="row">
                    <div className="col-md-12">
                      <p>Personal References</p>
                      <p className="subtitle">
                        Add minimum two personal references
                      </p>
                    </div>
                    <div className="col-md-12">
                      <div className="box">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name
                              className="form-control"
                              required="required"
                              placeholder="Name"
                              value={professionalName}
                              onChange={(e) => {
                                setprofessionalName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name
                              className="form-control"
                              required="required"
                              placeholder="Mobile Number"
                              value={professionalMobile}
                              onChange={(e) => {
                                setprofessionalMobile(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name
                              className="form-control"
                              required="required"
                              placeholder="Profession"
                              value={professionalProfession}
                              onChange={(e) => {
                                setprofessionalProfession(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name
                              className="form-control"
                              required="required"
                              placeholder="GigFWS Username"
                              value={professionalUsername}
                              onChange={(e) => {
                                setprofessionalUsername(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <button
                          className={`btn`}
                          onClick={() => {
                            moreProfessional
                              ? setMoreProfessional(false)
                              : setMoreProfessional(true);
                          }}
                        >
                          {moreProfessional ? "Remove" : "Add"}
                        </button>
                        {moreProfessional && (
                          <div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name
                                  className="form-control"
                                  required="required"
                                  placeholder="Name"
                                  value={professionalName1}
                                  onChange={(e) => {
                                    setprofessionalName1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name
                                  className="form-control"
                                  required="required"
                                  placeholder="Mobile Number"
                                  value={professionalMobile1}
                                  onChange={(e) => {
                                    setprofessionalMobile1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name
                                  className="form-control"
                                  required="required"
                                  placeholder="Profession"
                                  value={professionalProfession1}
                                  onChange={(e) => {
                                    setprofessionalProfession1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name
                                  className="form-control"
                                  required="required"
                                  placeholder="GigFWS Username"
                                  value={professionalUsername1}
                                  onChange={(e) => {
                                    setprofessionalUsername1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="two margin-bottom">
                  <div className="row">
                    <div className="col-md-12">
                      <p>Personal References</p>
                      <p className="subtitle">
                        Add minimum two personal references
                      </p>
                    </div>
                    <div className="col-md-12">
                      <div className="box">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name
                              className="form-control"
                              required="required"
                              placeholder="Name"
                              value={personalName}
                              onChange={(e) => {
                                setpersonalName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name
                              className="form-control"
                              required="required"
                              placeholder="Mobile Number"
                              value={personalMobile}
                              onChange={(e) => {
                                setpersonalMobile(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name
                              className="form-control"
                              required="required"
                              placeholder="Profession"
                              value={personalProfession}
                              onChange={(e) => {
                                setpersonalProfession(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name
                              className="form-control"
                              required="required"
                              placeholder="GigFWS Username"
                              value={personalUsername}
                              onChange={(e) => {
                                setpersonalUsername(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <button
                          className={`btn`}
                          onClick={() => {
                            morePersonal
                              ? setMorePersonal(false)
                              : setMorePersonal(true);
                          }}
                        >
                          {morePersonal ? "Remove" : "Add"}
                        </button>
                        {morePersonal && (
                          <div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name
                                  className="form-control"
                                  required="required"
                                  placeholder="Name"
                                  value={personalName1}
                                  onChange={(e) => {
                                    setpersonalName1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name
                                  className="form-control"
                                  required="required"
                                  placeholder="Mobile Number"
                                  value={personalMobile1}
                                  onChange={(e) => {
                                    setpersonalMobile1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name
                                  className="form-control"
                                  required="required"
                                  placeholder="Profession"
                                  value={personalProfession1}
                                  onChange={(e) => {
                                    setpersonalProfession1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name
                                  className="form-control"
                                  required="required"
                                  placeholder="GigFWS Username"
                                  value={personalUsername1}
                                  onChange={(e) => {
                                    setpersonalUsername1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <button onClick={profileupdate}>Update</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceUpdateView);
