import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { JobService } from "../../Network/services/JobService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { commonService } from "../../Network/services/commonService";
import Modal from "react-modal";
import GoogleMapReact from "google-map-react";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});
const MarkerComponent = ({ text }) => (
  <div>
    <img style={{ height: 40, weight: 40 }} src="/assets/images/marker.png" />
  </div>
);

const PaymentScreen = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [loading, setLoading] = useState(false);
  const [ord_id, setOrd_id] = useState("");
  const [mapView, setMapView] = useState(false);

  var newWindow;
  var countdown;

  useEffect(() => {}, []);
  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const INTERVAL_DELAY = 1000;

  const startPaymentStatus = () => {
    countdown = setInterval(() => paymentStatus(), 2000);
  };

  const openInNewTab = (url) => {
     newWindow = window.open(url,'_self')
   // if (newWindow) newWindow.opener = null
  }

 
  const iframe = '<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="https://docs.cashfree.com/docs/create-order&embedded=true" frameborder="no" allowtransparency="true" allowfullscreen="true">See the Pen <a href="https://codepen.io/ycw/pen/JqwbQw/">fx.</a> by ycw(<a href="https://codepen.io/ycw">@ycw</a>) on <a href="https://codepen.io">CodePen</a>.</iframe>'; 

  function Iframe(props) {
    return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
  }
  useEffect(() => {
   
    // openInNewTab(myVariable.url)
    // if (myVariable.id != "") {
    //   startPaymentStatus();
    // }
  }, []);
  // const path = useReactPath();
  // React.useEffect(() => {
  //   console.log("path changes" + path);
  // }, [path]);
  const paymentStatus = () => {
    setLoading(true);
    const form = new FormData();
    form.append("order_id", ord_id);

    commonService
      .CommonService("escrow_payment_status", form)
      .then(async (res) => {
        if (res.status == 200) {
          if (res.payment_status == "SUCCESS") {
            clearInterval(countdown);
            if (newWindow != null) {
              newWindow.close();
            }
            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            history.goBack();

          } else if (res.payment_status == "PENDING") {
           
                console.log("closen window")

            //     newWindow.close();
            // clearInterval(countdown);
            // history.goBack();


            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          } else {
            if (newWindow != null) {
              newWindow.close();
            }
            clearInterval(countdown);
            history.goBack();


            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }

          // await setAuthAsyncStorage(res);
        } else {
          if (newWindow != null) {
            newWindow.close();
          }
          clearInterval(countdown);
          history.goBack();

          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        clearInterval(countdown);

        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateStatus = (id, milestone, status, amount) => {
    setLoading(true);
    const form = new FormData();
    form.append("milestone", milestone);
    form.append("milestone_status", status);
    form.append("job_id", id);
    if (amount != "") {
      form.append("amount", amount);
    }

    commonService
      .CommonService("mark_as_completed", form)
      .then(async (res) => {
        if (res.status == 200) {
          if (status == "Completed") {
            setOrd_id(res.order_id);
            // newWindow = window.open(res.payment_link);
            openInNewTab(res.payment_link)
          } else {
            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }

          // await setAuthAsyncStorage(res);
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="explore explore1">
     <h1>Payment screen loading</h1>
     <Iframe iframe={iframe} />
    </section>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 1000 },
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentScreen);
