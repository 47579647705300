import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { profileUpdateService } from "../../Network/services/profileUpdateService";
import { getConnectList, connectRequest } from "../../Network/services/connectService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";

import Modal from "react-modal";

//import images and css
import "./connect.css";
import KycVerified from "../../assets/images/kyc-verified.png";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const ConnectList = (props) => {
    
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [searchConnect, setSearchConnect] = useState("");
  const [filteredConnectedUserList, setFilteredConnectedUserList] = useState([]);
  const [connectedUserList, setConnectedUserList] = useState([]);
  
  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0)
    console.log(      
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);
    
    const form = new FormData();  
    //form.append( "type", "My_Profile" );
    form.append( "type", "All" );
    getConnectList(form)
    .then(async (res) => {
      if (res.status == 200) {
        setConnectedUserList(res.data)
      } else {
        toast.success(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      }
    })
    .catch((err) => {
      toast.success("Something went wrong! please try again later!!", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    })
    .finally(() => {
      setLoading(false);
    });
 
  }, []);

    
  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  let connectDetail = (userConnectInfo) =>{
    history.push({ 
      pathname: '/connect-detail',
      state: { userConnectInfo: userConnectInfo }
     });
  }

  let connectSubmit = (connUserId) =>{
    console.log(connUserId);
    setLoading(true);  
  
    const connect_req_form = new FormData();
    connect_req_form.append('user_id', connUserId);


    connectRequest(connect_req_form)
    .then(async (res) => {
      if (res.status == 200) {

        const items = connectedUserList;
        setConnectedUserList(
          items.map((feed) => ({
            ...feed,
            is_connected: feed.id == connUserId ? "Connected" : feed.is_connected,            
          }))
        );


        if(filteredConnectedUserList && filteredConnectedUserList.length > 0){
          const filtereditems = filteredConnectedUserList;
          setFilteredConnectedUserList(
            filtereditems.map((feed) => ({
              ...feed,
              is_connected: feed.id == connUserId ? "Connected" : feed.is_connected,            
            }))
          );
        }

        toast.success(res.message, {
          position: "top-right",
          type: "success",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });

        // window.location.reload(false);
      } else {
        toast.success(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      }
    })
    .catch((err) => {
      toast.success("Something went wrong! please try again later!!", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    })
    .finally(() => {
      setLoading(false);
    });


  }

  const searchConnects = (text) => {
    //it will show indicator at the bottom of the list when data is loading otherwise it returns null
    console.log("key text    ",text);
    var searchtxt1 = connectedUserList.filter(
      (item) => item.first_name.toString().toUpperCase().includes(text.toString().toUpperCase()),

      // item.first_name.toUpperCase().includes(text.toUpperCase()),
    );
    console.log("filterted list ",searchtxt1);
    setFilteredConnectedUserList(searchtxt1);
  };

  return (
    <section className="explore explore1 contract">
    <LoadingOverlay>
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="/profile">
                      <i className="bx bx-chevron-left" /> Back to Profile
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        
        <div className="explore-details connects">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input type="textbox" placeholder="Search Connects" onClick={(e)=>{e.preventDefault()}} className="col-md-12" onChange={(e)=>searchConnects(e.target.value)} />
              </div>
            </div>

            <div className="col-md-12">
              
              <section className="profile you-quoted share status" style={{border: "none"}}>
                <div className="container">	
                  
                  {(filteredConnectedUserList && filteredConnectedUserList.length > 0
                    ? filteredConnectedUserList
                    : connectedUserList).map((post) => (
                                  
                    <div key={post.id} className="profile-detail yorself">
                      <div className="row">

                        <div className="col-md-2 padding-0 text-center">
                          <a href="#!" className="profile-avatar"><img src={post.profile_image} /></a>
                        </div>

                        <div className="col-md-6 padding-0">
                          <ul>
                            <li>
                              
                              <h1 style={{cursor:"pointer"}} onClick={() => connectDetail(post)} >{post.first_name}</h1>
                              
                              <h6>{post.skills}</h6>                           
                              <p><span>{post.total_completed_project}</span> Projects Completed</p>
                            </li>
                          </ul>
                        </div>

                        <div className="col-md-4 padding-0 text-right">
                          <h2 className="ratings"><i className="fa fa-star" aria-hidden="true"></i> {post.user_rating}</h2>
                          { post.is_connected=="Connected" 
                            ?
                            <a href="#!" onClick={() => connectSubmit(post.id)} className="btn">Connected</a>
                            : 
                            <a className="btn" onClick={() => connectSubmit(post.id)}>
                              Connect
                            </a>
                          }
                        </div>

                      </div>
                    </div>
                    
                  ))}

                </div>

              </section>

            </div>

          </div>
        </div>
       
      </div>
      <Loader loading={loading} />
    </LoadingOverlay>
    
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectList);
