import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { commonService } from "../../Network/services/commonService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MyQuoteView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [profile, setProfile] = useState({});
  const [name, setName] = useState("");
  const [myquoteList, setMyquoteList] = useState([]);
  const [sharedJoblist, setSharedJoblist] = useState([]);
  const [myquoteErrormsg, setMyquoteErrormsg] = useState("");
  const [sharedJobErrormsg, setSharedJobErrormsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));

    getQuoteList();
    setName(user.first_name + " " + user.last_name);
    let personal_preference_name1_array = user.personal_preference_name1.split(
      ","
    );
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const redirect = () => {
    history.push("/ExperienceUpdate");
  };

  const like = (id) => {
    setLoading(true);

    const FormData = require("form-data");

    const form = new FormData();
     form.append("share_id",id)
     form.append("thumps_up","Yes")

    commonService
      .CommonService("shared_job_thumps_up", form)
      .then(async (res) => {
        if (res.status == 200) {
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);

      });
  };


  const getQuoteList = () => {
    setLoading(true);

    const FormData = require("form-data");

    const form = new FormData();

    commonService
      .CommonService("my_quotes", form)
      .then(async (res) => {
        if (res.status == 200) {
          setMyquoteList(res.data);
        } else {
          setMyquoteErrormsg(res.message)
        }
      })
      .catch((err) => {
        setMyquoteErrormsg("Something went wrong! please try again later!!")

      })
      .finally(() => {
        getSharedJobList();
      });
  };

  const getSharedJobList = () => {
    const FormData = require("form-data");

    const form = new FormData();

    commonService
      .CommonService("shared_jobs_list", form)
      .then(async (res) => {
        if (res.status == 200) {
          setSharedJoblist(res.data);
        } else {
          setSharedJobErrormsg(res.message)
        }
      })
      .catch((err) => {
        setSharedJobErrormsg("Something went wrong! please try again later!!")

      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="post submitted">
      <div className="container">
        <div className="banner paddimg-bottom">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="/">
                      <i className="bx bx-chevron-left" /> Back to all jobs
                    </a>
                  </li>
                </ul>
                <h1 className="quotes-title paddimg-bottom">
                  View Applied Jobs
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="quotes">
          <div className="tabset">
            {/* Tab 1 */}
            <input
              type="radio"
              name="tabset"
              id="tab1"
              aria-controls="marzen"
              defaultChecked
            />
            <label htmlFor="tab1">My Quotes</label>
            {/* Tab 2 */}
            <input
              type="radio"
              name="tabset"
              id="tab2"
              aria-controls="rauchbier"
            />
            <label htmlFor="tab2">Shared Jobs</label>
            <div className="tab-panels">
              <section id="marzen" className="tab-panel">
                {/*----------------------- List Start  ---------------------*/}
                {!myquoteList.size>0?(
                <h6 className="quotes-title paddimg-bottom">{myquoteErrormsg}</h6>):null}
                <LoadingOverlay>
                  {myquoteList.map((item) => (
                    <div className="post-list">
                      <Link
                        to={{
                          pathname: `/myquote/${item.quote_id}/`,
                          state: { item },
                        }}
                      >
                        <div className="post-list-icon">
                          <img src="assets/images/post-list.png" />
                        </div>
                        <div className="count">
                          <ul>
                            <li>
                              <img
                                src="assets/images/job-id.png"
                                className="job-id"
                              />{" "}
                              Job#{" "}
                              <span>
                                {" "}
                                {item.job_unique_id.substr(
                                  item.job_unique_id.indexOf(" ") + 1
                                )}
                              </span>
                            </li>
                            <li className="line">
                              Quotes Received{" "}
                              <span>{item.total_quotes_received}</span>
                            </li>
                            <li className="line">
                              Quote sent on <span>{item.created_at}</span>
                            </li>
                            <li className="line">
                              Job Posted on <span>{item.timestamp}</span>
                            </li>
                            <li className="verified">
                              <img
                                src="assets/images/verified.png"
                                className="verified"
                              />{" "}
                              Job verified by GigFWS
                            </li>
                          </ul>
                        </div>
                        <h1>{item.job_title}</h1>
                        <div className="details">
                          <ul>
                            <li>{item.job_location}</li>
                            <li className="line"> {item.payment_terms}</li>
                            <li className="line font-normal">
                            Targeted close date <span>{item.target_closed_date}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="quoted">
                          <ul>
                            <li>
                              <a href="#">
                                You Quoted{" "}
                                <img
                                  src="assets/images/rupee.png"
                                  className="rupee"
                                />{" "}
                                <span> {item.bid_amount}</span>
                              </a>
                            </li>
                            <li className="eidt">
                              {/* <a href="#" className="message">
                              <img src="assets/images/message.png" /> Message
                            </a>{" "} */}
                              <a href="#">Edit Quote</a>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {/*----------------------- List End  ---------------------*/}
                  <Loader loading={loading} />
                </LoadingOverlay>
              </section>
              <section id="rauchbier" className="tab-panel">
              {!sharedJoblist.size>0?(
                <h6 className="quotes-title paddimg-bottom">{sharedJobErrormsg}</h6>):null}
                <LoadingOverlay>
                  {/*----------------------- List Start  ---------------------*/}
                  {sharedJoblist.map((item) => (
                    <div className="post-list">
                      <div className="post-list-icon">
                        <img src="assets/images/post-list.png" />
                      </div>
                      <div className="count">
                        <ul>
                          <li>
                            <img
                              src="assets/images/job-id.png"
                              className="job-id"
                            />{" "}
                            Job#{" "}
                            <span>
                              {" "}
                              {item.job_unique_id.substr(
                                item.job_unique_id.indexOf(" ") + 1
                              )}
                            </span>
                          </li>
                          <li className="line">
                            Quotes Received{" "}
                            <span>{item.total_quotes_received}</span>
                          </li>
                          <li className="line">
                            Posted <span>{item.timestamp}</span>
                          </li>
                          <li className="verified">
                            <img
                              src="assets/images/verified.png"
                              className="verified"
                            />{" "}
                            Job verified by GigFWS
                          </li>
                        </ul>
                      </div>
                      <h1>{item.job_title}</h1>
                      <div className="details">
                        <ul>
                          <li>{item.job_location}</li>
                          <li className="line"> {item.payment_terms}</li>
                          <li className="line font-normal">
                            Budget{" "}
                            <img
                              src="assets/images/rupee.png"
                              className="rupee"
                            />{" "}
                            <span> {item.amount}</span>
                          </li>
                          <li className="line font-normal">
                          Targeted close date <span>{item.target_closed_date}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="more quoted">
                        <ul>
                          {item.skills.split(",").map((skill) => (
                            <li>
                              <a href="#">{skill}</a>
                            </li>
                          ))}

                          <li>
                            <a href="#" className="shared" onClick={(e)=>{
                              e.preventDefault();
                              like(item.share_id);
                            }}  >
                                 {item.thumps_up!="Yes" ? (
                    <img src="assets/images/unlike.png" />
                  ) : (
                    <img src="assets/images/like.png" />
                  )}
                             
                               Shared by{" "}
                              <span>{item.shared_user_name}</span>
                            </a>
                          </li>
                          {/* <li className="eidt">
                            <a href="#" className="message">
                              Send Quote
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  ))}
                  {/*----------------------- List End  ---------------------*/}
                  <Loader loading={loading} />
                </LoadingOverlay>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyQuoteView);
