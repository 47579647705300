import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { commonService } from "../../Network/services/commonService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import  ShareJob  from "../../components/ShareJob";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";
import GoogleMapReact from "google-map-react";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MarkerComponent = ({ text }) => (
  <div>
    <img style={{ height: 40, weight: 40 }} src="/assets/images/marker.png" />
  </div>
);

const RequestListView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [profile, setProfile] = useState({});
  const [name, setName] = useState("");
  const [requestedList, setRequestedList] = useState([]);
  const [awardedJoblist, setAwardedJoblist] = useState([]);

  const [loading, setLoading] = useState(false);
  const [IsClicked, setIsClicked] = useState(false);
  const [clickedData, setclickedData] = useState({});
  const [mapView, setMapView] = useState(false);
  const [openShareJob, setOpenSharejob] = useState(false);

  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));

    getQuoteList();
    setName(user.first_name + " " + user.last_name);
    let personal_preference_name1_array = user.personal_preference_name1.split(
      ","
    );
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const redirect = () => {
    history.push("/ExperienceUpdate");
  };
  const toggleModel = () =>{
    setOpenSharejob(prevCheck => !prevCheck);
  }
  const accept = () => {
    setLoading(true);

    const FormData = require("form-data");

    const form = new FormData();
    form.append("quote_id", clickedData.quote_id);

    commonService
      .CommonService("accept_quote", form)
      .then(async (res) => {
        if (res.status == 200) {
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          history.goBack();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {});
  };

  const getQuoteList = () => {
    setLoading(true);

    const FormData = require("form-data");

    const form = new FormData();
    form.append("job_id", location.state.item.id);

    commonService
      .CommonService("my_contract_request_users", form)
      .then(async (res) => {
        if (res.status == 200) {
          setRequestedList(res.data);
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {});
  };


  const openUserDetail = (user_id) => {
    history.push('/user-detail/'+user_id);
    console.log('The link was clicked.');
  }

  return (
    <section className="explore explore1 contract">
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <div onClick={back}>
                      <a href="#">
                        <i className="bx bx-chevron-left" /> Back to Jobs I posted
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
            <a href="#" className="btn" onClick={(e)=>{
                    e.preventDefault();

                    toggleModel();

              }}>
                Share this Job <img src="/assets/images/share.png" />
              </a>
            </div>
          </div>
        </div>
        <div className="explore-details">
          <div className="row">
            <div className="col-md-6">
              <p className="jobid">
                Job#{" "}
                <span>
                  {location.state.item.job_unique_id.substr(
                    location.state.item.job_unique_id.indexOf(" ") + 1
                  )}
                </span>
              </p>
              <ul className="list-one">
                <li>
                  <a href="#!">
                    {location.state.item.payment_term} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!" />
                  {location.state.item.timestamp} <span>|</span>
                </li>
                <li>
                  <a href="#!">
                    Targeted close Date {location.state.item.target_closed_date}{" "}
                    <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.item.total_quote_count} Quotes Received
                  </a>
                </li>
              </ul>
              <h1 className="title">{location.state.item.job_title}</h1>
              <ul className="list-two">
                <li>
                  <a href="#!">
                    {location.state.item.job_location} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.item.payment_term} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.item.budjet}{" "}
                    <img src="/assets/images/rupee.png" />{" "}
                    <span className="amount">
                      {" "}
                      {location.state.item.amount}{" "}
                    </span>
                  </a>
                </li>
              </ul>
              <ul className="category-list">
                {location.state.item.skills.split(",").map((skill) => (
                  <li>
                    <a href="#">{skill}</a>
                  </li>
                ))}

<li className="view-map">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setMapView(true);
                    }}
                  >
                    View Location on Map
                  </a>
                </li>
              </ul>
              <p>{location.state.item.job_description}</p>
            </div>
            <div className="col-md-6">
              <section className="profile you-quoted share status">
                {!IsClicked ? (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 padding-0">
                        <h3>
                          <span>{location.state.item.total_quote_count}</span>{" "}
                          Requests on this Job
                        </h3>
                      </div>
                    </div>
                    {requestedList.map((item) => (
                      <div className="profile-detail yorself">
                        <div className="row">
                          <div className="col-md-1 padding-0">
                            <a href={`/user-detail/${item.user_id}`} className="profile-avatar">
                            {item.profile_image!="" ? (
                                <img src={item.profile_image} width="50" height="50" />
                              ) : (
                                <img src="/assets/images/profile-avatar.png" />
                              )}
                            </a>
                          </div>
                          <div className="col-md-7 padding-0">
                            <ul>
                              <li>
                                <a href={`/user-detail/${item.user_id}`} >
                                <h1>{item.user_name}</h1>
                                
                                <h6>{item.skills}</h6>
                                </a>
                                
                                <h2 onClick={() => openUserDetail(item.user_id)}>
                                  <img
                                    src="/assets/images/rupee-blue.png"
                                    className="rupee"
                                  />{" "}
                                  <span>{item.bid_amount}/-</span>
                                </h2>
                                
                                
                                <p onClick={() => openUserDetail(item.user_id)}>Quoted | {item.job_bid_type}</p>
                                <p onClick={() => openUserDetail(item.user_id)}>
                                  <span> {item.projects_completed}</span>{" "}
                                  Projects Completed
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4 padding-0 text-center">
                            <h2 className="ratings">
                              <i className="fa fa-star" aria-hidden="true" />{" "}
                              {item.user_rating}
                            </h2>
                            <a
                              href="#"
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsClicked(true);
                                setclickedData(item);
                              }}
                            >
                              View Quote
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="container">
                    <div className="rows">
                      <div className="col-md-12 padding-0">
                        <div className="breadcrumps">
                          <ul>
                            <li>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsClicked(false);
                                }}
                              >
                                <i className="bx bx-chevron-left" /> Back
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="profile-detail yorself">
                        <div className="row">
                          <h6>Request from</h6>
                          <div className="col-md-2 padding-0">
                            <a href={`/user-detail/${clickedData.user_id}`} className="profile-avatar">
                            {clickedData.profile_image!="" ? (
                    <img src={clickedData.profile_image} width="50" height="50" />
                  ) : (
                    <img src="/assets/images/profile-avatar.png" />
                  )}
                            </a>
                          </div>
                          <div className="col-md-7 padding-0">
                            <ul>
                              <li>
                                <a href={`/user-detail/${clickedData.user_id}`}>
                                <h1>{clickedData.user_name}</h1>
                                </a>
                                <h6>{clickedData.skills}</h6>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <table>
                        <tbody>
                          <tr>
                            <th colSpan={3}>Payment Terms</th>
                          </tr>
                          <tr>
                            <td colSpan={3}>{clickedData.payment_terms}</td>
                          </tr>
                          <tr>
                            {clickedData.payment_milestone.map((item) => (
                              <td>
                                <p>{item.name}</p>
                                <h2>₹{item.amount}</h2>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                      <div className="text-center">
                        {/* <button
                          className="btn btn-outline"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsClicked(false);
                          }}
                        >
                          Decline
                        </button> */}
                        <button
                          className="btn default-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            accept();
                          }}
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={mapView}
        onRequestClose={() => setMapView(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
        <div className="modal-dialog loginpopup" role="document">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <h5 className="modal-title">
                  View Location on Map
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setMapView(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <GoogleMapReact
                  style={{ height: 400 }}
                  bootstrapURLKeys={{
                    key: "AIzaSyDhKv7Zr8AbpBwP84oMc7mRgcqMOjmI340",
                  }}
                  defaultCenter={{
                    lat: parseFloat(location.state.item.latitude),
                    lng: parseFloat(location.state.item.longitude),
                  }}
                  defaultZoom={11}
                >
                  <MarkerComponent text="My Marker" />
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ShareJob isOpen={openShareJob} toggle={toggleModel} jobId={location.state.item.id}/>

    </section>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 1000 },
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestListView);
