import ArticleList from "../ArticleList";
import React, { useState, useEffect } from "react";
import agent from "../../agent";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { JobService } from "../../Network/services/JobService";
import  ShareJob  from "../ShareJob";


const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const HomeView = (props) => {
  const [jobs, setJobs] = useState([]);
  const [openShareJob, setOpenSharejob] = useState(false);
  const [jobId, setJobId] = useState("");
  const [showFullList, setShowFullList] = useState(false);
  const [listCount, setListCount] = useState(3);
  const [categoryView, setCategoryView] = useState(false);
  const [filterView, setFilterView] = useState(false);
  const [sortView, setSortView] = useState(false);

  const [categoryData, setCategoryData] = useState([]);
  const [filteredcategoryData, setFilteredCategoryData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedfilterData, setSelectedfilterData] = useState("");
  const [selectedSortData, setSelectedSortData] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
   

  }, []);


  return (
    <div>
    <div className="preloader-area">
      <div className="spinner">
        <div className="inner">
          <div className="disc" />
          <div className="disc" />
          <div className="disc" />
        </div>
      </div>
    </div>
    <style type="text/css" dangerouslySetInnerHTML={{__html: "\n.alert.contact__msg.custom-alert.alert-success {\ndisplay: block !important;\n}\n\n.download_content {\ntext-align: center;\nmargin: 60px 0 0;\n}\nul {\ndisplay: block;\nlist-style: none;\npadding: 0;\nmargin: 0;\n}\n.baner-header-sec {\nbackground-image: linear-gradient(to right, #f3783d , #f8b459);\npadding: 10px 0px;\n}\n\n.animate-charcter {\ntext-transform: uppercase;\nbackground-image: linear-gradient( -225deg, #fab962 0%, #ffffff 29%, #ffffff 67%, #00e7ff 100% );\nbackground-size: auto auto;\nbackground-clip: border-box;\nbackground-size: 200% auto;\ncolor: #fff;\nbackground-clip: text;\ntext-fill-color: transparent;\n-webkit-background-clip: text;\n-webkit-text-fill-color: transparent;\nanimation: textclip 2s linear infinite;\ndisplay: inline-block;\nfont-size: 20px;\nwidth: 100%;\n}\n@keyframes textclip {\nto {\nbackground-position: 200% center;\n}\n}\n\nul.play-store-sec li {\ndisplay: inline-block;\n}\n" }} />
    
    <div className="navbar-area">
      <div className="main-responsive-nav">
        <div className="container">
          <div className="main-responsive-menu">
            <div className="logo">
              <a href="#!">
                <img src="https://pknvinfotech.com/assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
     
      <div className="others-option-for-responsive">
        <div className="container">
          <div className="dot-menu">
            <div className="inner">
              <div className="circle circle-one" />
              <div className="circle circle-two" />
              <div className="circle circle-three" />
            </div>
          </div>
          <div className="container">
            <div className="option-inner">
              <div className="others-options d-flex align-items-center">
                <div className="option-item">
                  <div className="search-box">
                    <i className="flaticon-search" />
                  </div>
                </div>
                <div className="option-item">
                  <div className="navbar-btn">
                    <a href="#" className="default-btn">Register</a>
                    <a href="online-booking.html" className="default-btn">Login</a>
                    <a href="#" className="default-btn" data-toggle="modal" data-target="#signin">Sign In</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="search-overlay">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="search-overlay-layer" />
          <div className="search-overlay-layer" />
          <div className="search-overlay-layer" />
          <div className="search-overlay-close">
            <span className="search-overlay-close-line" />
            <span className="search-overlay-close-line" />
          </div>
          <div className="search-overlay-form">
            <form>
              <input type="text" className="input-search" placeholder="Search here..." />
              <button type="submit"><i className="flaticon-search" /></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  
    <div className="baner-header-sec" style={{ 
    margin: '40px auto'}}>
<h5 className="animate-charcter" style={{padding:'20px 30px', textAlign: 'center', color: '#fff', fontSize: '38px', margin: '0px !important', textTransform: 'inherit'}}>GigFWS India's Most Independent Gig B2B2C Platform</h5>
</div>
    <section className="popup" style={{margin: '0px', width: '100%', maxWidth: '100%', height:'auto' }} >
      {/* 
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div>
<div class="cube"></div> */}
      <div className="orange-label" />
      <div className="white-label" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="left">
              <h2 className="suntitle animation">With the Vision of</h2>
              <h1 className="title animation">“Building and Boosting<br />Future of GiG Economy”</h1>
              <div className="row">
                <div className="col-md-1">
                  <img src="imageslan/you.png" className="you animation" />
                </div>
                <div className="col-md-11">
                  <ul className="list animation">
                    <li>Build your gig profile</li>
                    <li>Post a job contract and get the job done</li>
                    <li>Apply and bid for near by jobs</li>
                    <li>Pay for the services and get paid for the jobs</li>
                    <li>Resolve the disputes easily</li>
                    <li>Increase your regular income</li>
                  </ul>
                </div>
              </div>
              <form className="contact__form" method="post" action="mail.php">
                <div className="row">
                  <div className="col-md-12">
                    <h1>Don’t Miss Out</h1>
                  </div>
                  <div className="col-md-8">
                    {/* <input type="mail" name="" placeholder="Your email" class="form-control"> */}
                    <div className="form-group">
                      <input type="email" className="form-control" name="email" placeholder="Your email" required="required" />
                    </div>
                    <label className="responsive-hide">Your data is secured with us</label>
                  </div>
                  <div className="col-md-4 text-center">
                    {/* <div class='btn-holder'>
                          
                          <button type="submit" name="" class="btn btn-3 hover-border-3">
                            <span>Notify me</span>
                          </button>
                          
                        </div> */}
                    <button type="submit" className="button">Notify Me</button>
                    <label className="responsive-show">Your data is secured with us</label>
                  </div>
                </div>
                {/* form message */}
                {/*   <div class="row">
                                <div class="col-12">
                                    <div class="alert alert-success contact__msg custom-alert" style="display: none" role="alert">
                                        Your message was sent successfully.
                                    </div>
                                </div>
                            </div> */}
                {/* end message */}
              </form>
            </div>
          </div>
          <div className="col-md-1" />
          <div className="col-md-5">
            <div className="right">
              <a className="title">ON YOUR WAY, STAY TUNED <img src="imageslan/animated-arrow.gif" /></a>
              <div className="row social-icons">
                <ul>
                  <li><a href="https://play.google.com/store/apps/details?id=com.futureworkforce" target="_blank"><img src="imageslan/playstore.png" /></a></li>
                  <li><a href><img src="imageslan/Group 3107@2x.png" /></a></li>
                </ul>
              </div>
              {/*  <div class="round">
    <div id="cta">
        <span class="arrow primera next "></span>
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        <span class="arrow segunda next "></span>
    </div>
</div> */}
              <div className="row pages animation">
                <div className="col-md-6 text-center">
                  <img src="imageslan/page1.png" />
                </div>
                <div className="col-md-6 text-center">
                  <img src="imageslan/page2.png" />
                </div>
                <div className="col-md-6 text-center">
                  <img src="imageslan/page3.png" />
                </div>
                <div className="col-md-6 text-center">
                  <img src="imageslan/page4.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   
  </div>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 1000 },
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
