import ArticleList from "../ArticleList";
import React, { useState, useEffect } from "react";
import agent from "../../agent";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { JobService } from "../../Network/services/JobService";
import  ShareJob  from "../ShareJob";

const YourFeedTab = (props) => {
  if (props.token) {
    const clickHandler = (ev) => {
      ev.preventDefault();
      props.onTabClick("feed", agent.Articles.feed, agent.Articles.feed());
    };

    return (
      <li className="nav-item">
        <a
          href=""
          className={props.tab === "feed" ? "nav-link active" : "nav-link"}
          onClick={clickHandler}
        >
          Your Feed
        </a>
      </li>
    );
  }
  return null;
};

const GlobalFeedTab = (props) => {
  const clickHandler = (ev) => {
    ev.preventDefault();
    props.onTabClick("all", agent.Articles.all, agent.Articles.all());
  };
  return (
    <li className="nav-item">
      <a
        href=""
        className={props.tab === "all" ? "nav-link active" : "nav-link"}
        onClick={clickHandler}
      >
        Global Feed
      </a>
    </li>
  );
};

const TagFilterTab = (props) => {
  if (!props.tag) {
    return null;
  }

  return (
    <li className="nav-item">
      <a href="" className="nav-link active">
        <i className="ion-pound"></i> {props.tag}
      </a>
    </li>
  );
};

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MainView = (props) => {
  const [jobs, setJobs] = useState([]);
  const [openShareJob, setOpenSharejob] = useState(false);
  const [jobId, setJobId] = useState("");
  const [showFullList, setShowFullList] = useState(false);
  const [listCount, setListCount] = useState(3);
  const [categoryView, setCategoryView] = useState(false);
  const [filterView, setFilterView] = useState(false);
  const [sortView, setSortView] = useState(false);

  const [categoryData, setCategoryData] = useState([]);
  const [filteredcategoryData, setFilteredCategoryData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedfilterData, setSelectedfilterData] = useState("");
  const [selectedSortData, setSelectedSortData] = useState("");
  const [loading, setLoading] = useState(false);

  const [isChangePasswordModelOpen, setIsChangePasswordModelOpen] = useState(false);  
  const [attachmentLink, setattachmentLink] = useState("");  
  const [filtype, sefiltype] = useState("");  

  let openChangePasswordmodel = (aLink) => {
    let fileExt =  aLink.split(/[#?]/)[0].split('.').pop().trim();
    //alert(fileExt);

    let ext = fileExt.toLowerCase();

    if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'bmp' || ext == 'png') {
      sefiltype('image')
      //alert("image");
    }
    else {
      sefiltype('others')
      //alert("others");
    }

    setattachmentLink(aLink);
    setIsChangePasswordModelOpen(true);
  };
  let closeChangePasswordmodel = () => {
    setIsChangePasswordModelOpen(false);
  };


  let getFileType = (aLink) => {
    let fileExt =  aLink.split(/[#?]/)[0].split('.').pop().trim();
    let ext = fileExt.toLowerCase();

    if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'bmp' || ext == 'png') {
      return "image";
    }
    else {
      return "others";
    }
  }


  useEffect(() => {
    if(JSON.parse(window.localStorage.getItem("isLoggedIn")))
    {
    fetchjobcall("","","")
      console.log(
        "user====================" ,
          JSON.parse(window.localStorage.getItem("UserData"))
      );
      let user = JSON.parse(window.localStorage.getItem("UserData"));
      if(user!=null && user.categories!=null){
      setCategoryData(user.categories);
      }
    }

  }, []);
  const toggleModel = () =>{
    setOpenSharejob(prevCheck => !prevCheck);
  }
  const fetchjobcall = (sort, filter, category) => {
    setLoading(true)
    const form = new FormData();
    form.append('sort',sort);
form.append('location_filter',filter);
form.append('category',category);
    console.log('====================Request Started=================');
    JobService.getJobs(form)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          setJobs(res.data);
        } else {
          setJobs([]);
        }
      })
      .catch((err) => {})
      .finally(() => {
        //  dispatch(fetchJobFailed('Failed'));
        setLoading(false)

      });
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const searchCategory = (text) => {
    //it will show indicator at the bottom of the list when data is loading otherwise it returns null
    console.log("key text    ",text);
    var searchtxt1 = categoryData.filter(
      (item) => item.category_name.toString().toUpperCase().includes(text.toString().toUpperCase()),

      // item.first_name.toUpperCase().includes(text.toUpperCase()),
    );
    console.log("filterted list ",searchtxt1);
    setFilteredCategoryData(searchtxt1);
  };

  return (
    <section className="post">
      <div className="container">
        {/*----------------------- Most searched Jobs Start  ---------------------*/}
        <div className="most-searched-jobs">
          {/* <div className="row">
            <div className="col-md-12">
              <h1>Most searched Jobs</h1>
              <ul>
                <li>Plumber</li>
                <li>Painter</li>
                <li>Painter</li>
                <li>Painter</li>
                <li>Painter</li>
                <li>Painter</li>
                <li>Painter</li>
              </ul>
            </div>
          </div> */}
        </div>
        {/*----------------------- Most searched Jobs End  ---------------------*/}
        {/*----------------------- Post Filter Start  ---------------------*/}
        <div className="post-filter">
          <div className="row">
            <div className="col-md-6">
              {/* <p>
                Recent Posted Jobs <span>Latest post 15 minutes ago</span>
              </p> */}
            </div>
            <div className="col-md-6">
              <ul>
                <li>
                <a href="#" onClick={(e)=>{
                    e.preventDefault();
                    setSortView(true)
                  }}>
                    <img src="assets/images/noun_Sort.png" /> Sort by
                  </a>
                </li>
                <li>
                <a href="#" onClick={(e)=>{
                    e.preventDefault();
                    setFilterView(true)
                  }}>
                    <img src="assets/images/filter.png" /> Filter
                  </a>
                </li>
                <li>
                  <a href="#" onClick={(e)=>{
                    e.preventDefault();
                    setCategoryView(true)
                  }}>
                    <img src="assets/images/noun_Category.png" /> Category
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*----------------------- Post Filter End  ---------------------*/}
        <div className="row">
          <div className="col-md-12">
          <LoadingOverlay>

            {/*----------------------- List Start  ---------------------*/}
            {jobs.slice(0,listCount).map((job) => (
              <div className="post-list">
                <Link to={{ pathname: `/job/${job.id}/`, state: { job } }}>
                  <div className="post-list-icon">
                    <img src="assets/images/post-list.png" />
                  </div>
                  <div className="count">
                    <ul>
                      <li>
                        <img
                          src="assets/images/job-id.png"
                          className="job-id"
                        />{" "}
                        Job#{" "}
                        <span>
                          {job.job_unique_id.substr(
                            job.job_unique_id.indexOf(" ") + 1
                          )}-
                        </span>
                      </li>
                      <li className="line">
                        Quotes Received <span>{job.total_quotes_received}</span>
                      </li>
                      <li className="line">
                        Posted <span>{job.timestamp}</span>
                      </li>
                      {job.is_fws_verified ? (
                        <li className="verified">
                          <img
                            src="assets/images/verified.png"
                            className="verified"
                          />{" "}-
                          Job verified by GigFWS
                        </li>
                      ) : null}
                      <li className="line">
                      <a href="#" onClick={(e)=>{
                        e.preventDefault();
                        toggleModel()
                      }}>
                    <img src="assets/images/share.png" /> 
                  </a>
                      </li>
                    </ul>
                  </div>
                  <h1>{job.job_title}</h1>
                  <div className="details">
                    <ul>
                      <li>{job.job_location}</li>
                      <li className="line">{job.payment_term}</li>
                      <li className="line font-normal">
                        {job.budjet}
                        <img src="assets/images/rupee.png" className="rupee" />
                        <span>{job.amount}</span>
                      </li>
                      <li className="line font-normal">
                      Targeted close date <span>{job.target_closed_date}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="more">
                    <ul>
                      {/* <li>
                      <a href="#">Plumbing</a>
                    </li>
                    <li>
                      <a href="#">Painting</a>
                    </li>
                    <li>
                      <a href="#" className="more">
                        3 More
                      </a>
                    
                    </li> */}
                      {job.skills.split(",").map((skill) => (
                        <li>
                          <a href="#">{skill}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  </Link>
                  {job.attach_file!=null && getFileType(job.attach_file)=="image"?(
                    
                       
                  //<div className="files" onClick={() => openInNewTab(job.attach_file)}>

                  <div className="files" onClick={() => openChangePasswordmodel(job.attach_file)}>
                    <label htmlFor="file">
                      <img src="/assets/images/attachments.png" />
                      Attachement
                    </label>
                  </div> ):null
                  }

                  {job.attach_file!=null && getFileType(job.attach_file)=="others"?(
                    
                       
                    <div className="files" onClick={() => openInNewTab(job.attach_file)}>
                      <label htmlFor="file">
                        <img src="/assets/images/attachments.png" />
                        Attachement
                      </label>
                    </div> ):null
                    }
          
                  <ShareJob isOpen={openShareJob} toggle={toggleModel} jobId={job.id}/>

                
              </div>
            ))}
              <Loader loading={loading} />
                </LoadingOverlay>
            {/*----------------------- List End  ---------------------*/}
            {showFullList?null:jobs.length>3?
            <div className="row">
              <div className="col-md-12 text-center">
                <a href="#"  className="view-all-jobs" onClick={(e)=>{
                  e.preventDefault();
                  setShowFullList(true);
                  setListCount(jobs.length)
                }}>
                  View all Jobs
                </a>
              </div>
            </div>:null}
          </div>
        </div>
      </div>
      
      {/* ============================ attachment model =============================== */}
      <Modal
        isOpen={isChangePasswordModelOpen}
        onRequestClose={closeChangePasswordmodel}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
          <LoadingOverlay>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content" onClick={(e)=>{e.preventDefault()}}>
              <div className="dashboard signin">
                <div className="modal-header">
                  <h5 className="modal-title">Attachment</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeChangePasswordmodel} >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body" style={{paddingBottom: '30px'}}>
                 
                  {filtype == "image" ? 
                    <div style={{textAlign:"center"}}>
                      <img  src={attachmentLink} />
                    </div>
                  :  
                    <Link to={attachmentLink} target="_blank" download>Open or Download</Link> 
                  }
                </div>
              </div>

            </div>
          </div>
          <Loader loading={loading} />
          </LoadingOverlay>
      </Modal>
      {/* ============================ /attachment model =============================== */}


      <Modal
        isOpen={sortView}
        onRequestClose={() => setSortView(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
        <div className="modal-dialog loginpopup" role="document">
        <div className="modal-content">
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t.loginpopup.dashboard.signin .profile-detail.qualification.referance {\n    margin: 0px;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance .col-md-10.padding-0 p {\n    text-align: left !important;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance .col-md-10.padding-0 p span {\n    display: block;\n    color: #000;\n    font-size: 14px;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance .row {\n    border: 1px solid #ccc;\n    padding: 16px 10px 10px;\n    margin: 0px;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance {\n    /* padding: 0px !important; */\n    border: 0px;\n    /* margin: 46px !important; */\n    background: transparent;\n}\n\ninput.serch-friends {\n    border: 1px solid #ccc;\n    height: 42px;\n    border-radius: 7px;\n    width: 100%;\n    margin-bottom: 42px;\n    text-indent: 11px;\n}\n\ndiv#share .modal-body {\n    padding-top: 0px !important;\n}\n\n.scroll-bar-sec {\n    max-height: 400px;\n    overflow-x: scroll !important;\n}\n\n.short-by.loginpopup.dashboard.signin\n\n.short-by.loginpopup.dashboard.signin .profile-detail.qualification.referance .row {\n    border: 0px;\n}\n\n.short-by.loginpopup.dashboard.signin li {\n    list-style-type: none;\n    padding: 7px 0px;\n}\n\n.short-by.loginpopup.dashboard.signin li input[type=\"radio\"] {\n    margin-right: 5px;\n}\n\n.short-by.loginpopup.dashboard.signin .row {\n    border: 0px !important;\n}\n\n.short-by h4 {\n    background-image: url(assets/images/noun_Sort.png);\n    background-repeat: no-repeat;\n    padding-left: 40px;\n}\n" }} />
        <div className="short-by loginpopup dashboard signin">
          <div className="modal-header">
            <h5 className="modal-title">Sort <span>GigFWS</span> Jobs</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"onClick={() => setSortView(false)} >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body" style={{paddingBottom: '30px'}}>
            <div className="profile-detail qualification referance">
              <div className="col-md-12 search-frnds-sec">
                <h4>sort by</h4>
              </div>
              <div className="row" onChange={event => setSelectedSortData(event.target.value)}>
                <li>
                  <a><input type="radio" name="short" defaultValue="latest" />Latest</a>
                </li>
                <li>
                  <a><input type="radio" name="short" defaultValue="oldest"  />Oldest</a>
                </li>
                <li>
                  <a><input type="radio" name="short" defaultValue="closing soon"  />Closing Soon</a>
                </li>
              </div>
            </div>
            <button type="submit" className="btn btn-default" onClick={()=>{
              console.log(selectedfilterData)
              setSortView(false)
              fetchjobcall(selectedSortData,"","")
            }}  >Submit</button>
          </div>
        </div>
      </div>
        </div>
      </Modal>

      <Modal
        isOpen={filterView}
        onRequestClose={() => setFilterView(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
        <div className="modal-dialog loginpopup" role="document">
        <div className="modal-content">
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t.loginpopup.dashboard.signin .profile-detail.qualification.referance {\n    margin: 0px;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance .col-md-10.padding-0 p {\n    text-align: left !important;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance .col-md-10.padding-0 p span {\n    display: block;\n    color: #000;\n    font-size: 14px;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance .row {\n    border: 1px solid #ccc;\n    padding: 16px 10px 10px;\n    margin: 0px;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance {\n    /* padding: 0px !important; */\n    border: 0px;\n    /* margin: 46px !important; */\n    background: transparent;\n}\n\ninput.serch-friends {\n    border: 1px solid #ccc;\n    height: 42px;\n    border-radius: 7px;\n    width: 100%;\n    margin-bottom: 42px;\n    text-indent: 11px;\n}\n\ndiv#share .modal-body {\n    padding-top: 0px !important;\n}\n\n.scroll-bar-sec {\n    max-height: 400px;\n    overflow-x: scroll !important;\n}\n\n.filter-sec h4 {\n    background-image: url(assets/images/filter.png);\n    background-repeat: no-repeat;\n    padding-left: 35px;\n}\n\n.location-list li a {\n    display: inline-block;\n    width: 100%;\n    padding: 10px 0px;\n    border-bottom: 1px solid #ccc !important;\n}\n\n.location-list li{\n\tlist-style-type: none;\n}\n\n.filter-secinput .serch-friends {\n    border: 1px solid #ccc;\n    height: 42px;\n    border-radius: 7px;\n    width: 100%;\n    margin-bottom: 20px;\n    text-indent: 11px;\n    margin-top: 30px;\n}\n\n.filter-sec .modal-body button.btn.btn-default {\n   \n    width: 195px;\n}\n\n.filter-sec input.serch-friends {\n    margin-bottom: 20px;\n    margin-top: 30px;\n}\n" }} />
        <div className="filter-sec loginpopup dashboard signin">
          <div className="modal-header">
            <h5 className="modal-title">Filter <span>GigFWS</span> Job Locations</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"onClick={() => setFilterView(false)} >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body" style={{paddingBottom: '30px'}}>
            <div className="profile-detail qualification referance">
              <div className="col-md-12 search-frnds-sec">
                <h4>Filter</h4>
                <input type="textbox" placeholder="Enter Locations" className="serch-friends" onChange={(e)=>setSelectedfilterData(e.target.value)}/>
              </div>
              {/* <div className="location-list">
                <li>
                  <a> vellore</a>
                </li>
                <li>
                  <a>chennai</a>
                </li>
              </div> */}
            </div>
            <button type="submit" className="btn btn-default" onClick={()=>{
              console.log(selectedfilterData)
              setFilterView(false)
              fetchjobcall("",selectedfilterData,"")
            }} >Filter by Location</button>
          </div>
        </div>
      </div>
        </div>
      </Modal>


      <Modal
        isOpen={categoryView}
        onRequestClose={() => setCategoryView(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
        <div className="modal-dialog loginpopup" role="document">
        <div className="modal-content">
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\t.loginpopup.dashboard.signin .profile-detail.qualification.referance {\n    margin: 0px;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance .col-md-10.padding-0 p {\n    text-align: left !important;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance .col-md-10.padding-0 p span {\n    display: block;\n    color: #000;\n    font-size: 14px;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance .row {\n    border: 1px solid #ccc;\n    padding: 16px 10px 10px;\n    margin: 0px;\n}\n\n.loginpopup.dashboard.signin .profile-detail.qualification.referance {\n    /* padding: 0px !important; */\n    border: 0px;\n    /* margin: 46px !important; */\n    background: transparent;\n}\n\ninput.serch-friends {\n    border: 1px solid #ccc;\n    height: 42px;\n    border-radius: 7px;\n    width: 100%;\n    margin-bottom: 42px;\n    text-indent: 11px;\n}\n\ndiv#share .modal-body {\n    padding-top: 0px !important;\n}\n\n.scroll-bar-sec {\n    max-height: 400px;\n    overflow-x: scroll !important;\n}\n.catagryby.loginpopup.dashboard.signin .profile-detail.qualification.referance .row {\n    border: 0px;\n}\n\n.catagryby.loginpopup.dashboard.signin li {\n    list-style-type: none;\n    padding: 7px 0px;\n}\n\n.catagryby.loginpopup.dashboard.signin li input[type=\"radio\"] {\n    margin-right: 5px;\n}\n\n.catagryby h4 {\n    background-image: url(assets/images/noun_Category.png);\n    background-repeat: no-repeat;\n    padding-left: 35px;\n}\n\n.catagryby input.serch-friends {\n    margin-bottom: 20px;\n    margin-top: 30px;\n}\n" }} />
        <div className="catagryby loginpopup dashboard signin">
          <div className="modal-header">
            <h5 className="modal-title">Filter <span>GigFWS</span> Categories</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"onClick={() => setCategoryView(false)} >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body" style={{paddingBottom: '30px'}}>
            <div className="profile-detail qualification referance">
              <div className="col-md-12 search-frnds-sec">
                <h4>Category</h4>
                <input type="textbox" placeholder="Search by Category" className="serch-friends" onChange={(e)=>searchCategory(e.target.value)}/>
              </div>

              <div className="row" style={{height:200,overflowY: 'scroll',}} onChange={(event) => setSelectedCategory(event.target.value) }>
              {(filteredcategoryData && filteredcategoryData.length > 0
                    ? filteredcategoryData
                    : categoryData).map((cat) => (
                <li>
                  <a><input type="radio" name="catagery" defaultValue={cat.category_name} /><label htmlFor="catagery">{cat.category_name}</label></a>
                </li>
              ))}
              </div>
            </div>
            <button type="submit" className="btn btn-default" onClick={()=>{
              console.log(selectedCategory)
              setCategoryView(false)
              fetchjobcall("","",selectedCategory)
            }}>Submit</button>
          </div>
        </div>
      </div>
        </div>
      </Modal>
    </section>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 1000 },
};
export default connect(mapStateToProps, mapDispatchToProps)(MainView);
