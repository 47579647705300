import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { JobService } from "../../Network/services/JobService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { commonService } from "../../Network/services/commonService";
import Modal from "react-modal";
import GoogleMapReact from "google-map-react";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MarkerComponent = ({ text }) => (
  <div>
    <img style={{ height: 40, weight: 40 }} src="/assets/images/marker.png" />
  </div>
);

const MyJobsDetailView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [loading, setLoading] = useState(false);
  const [ord_id, setOrd_id] = useState("");
  const [mapView, setMapView] = useState(false);

  var newWindow = null;
  var countdown;

  useEffect(() => {}, []);
  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const INTERVAL_DELAY = 1000;

  // const path = useReactPath();
  // React.useEffect(() => {
  //   console.log("path changes" + path);
  // }, [path]);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const updateStatus = (id, milestone, status, amount) => {
    setLoading(true);
    const form = new FormData();
    form.append("milestone", milestone);
    form.append("milestone_status", status);
    form.append("job_id", id);

    commonService
      .CommonService("employee_mark_as_completed", form)
      .then(async (res) => {
        if (res.status == 200) {
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          history.goBack();
          // await setAuthAsyncStorage(res);
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="explore explore1">
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <div onClick={back}>
                      <a href="#">
                        <i className="bx bx-chevron-left" /> Back to Jobs I'm doing
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              {/* <a href="#!" className="btn">
                Share this Job <img src="/assets/images/share.png" />
              </a> */}
            </div>
          </div>
        </div>
        <div className="explore-details">
          <div className="row">
            <div className="col-md-7">
              <p className="jobid">
                Job#{" "}
                <span>
                  {location.state.item.job_unique_id.substr(
                    location.state.item.job_unique_id.indexOf(" ") + 1
                  )}
                </span>
              </p>
              <ul className="list-one">
                <li>
                  <a href="#!">
                    {location.state.item.payment_term} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!" />
                  POSTED {location.state.item.timestamp} <span>|</span>
                </li>
                {/* <li>
                  <a href="#!">
                    Job EXPIRY {location.state.item.target_closed_date}{" "}
                    <span>|</span>
                  </a>
                </li> */}
                <li>
                Awarded on <span>{location.state.item.awarded_date}</span>
                </li>
              </ul>
              <h1 className="title">{location.state.item.job_title}</h1>
              <ul className="list-two">
                <li>
                  <a href="#!">
                    {location.state.item.job_location} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.item.payment_term} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.item.budjet}{" "}
                    <img src="/assets/images/rupee.png" />{" "}
                    <span className="amount">{location.state.item.amount}</span>
                  </a>
                </li>
              </ul>
              <ul className="category-list">
                {location.state.item.skills.split(",").map((skill) => (
                  <li>
                    <a href="#">{skill}</a>
                  </li>
                ))}
                <li className="view-map">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setMapView(true);
                    }}
                  >
                    View Location on Map
                  </a>
                </li>
              </ul>
              <p>{location.state.item.job_description}</p>
              {location.state.item.attach_file!=null?(
                               

                               <div className="files" onClick={() => openInNewTab(location.state.item.attach_file)}>
                                         <label htmlFor="file">
                                           <img src="/assets/images/attachments.png" />
                                          Attachement
                                         </label>
                                       </div> ):null}
              {/* <a href="#!" class="btn quote">Send Quote</a> */}
            </div>
            <div className="col-md-5">
              <section className="profile you-quoted share status">
                <LoadingOverlay>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 padding-0">
                        <h3>Job Status</h3>
                      </div>
                      {/* <div className="col-md-6 padding-0">
                        <a href="#" className="message">
                          <img src="/assets/images/message.png" /> Message
                        </a>
                      </div> */}
                    </div>
                    <div className="profile-detail yorself">
                      <div className="row">
                        <div className="col-md-1 padding-0">
                          <a href="#!" className="profile-avatar">
                            <img src="/assets/images/term-payment.png" />
                          </a>
                        </div>
                        <div className="col-md-11 padding-0">
                          <ul>
                            <li>
                              <h6> {location.state.item.payment_term} </h6>
                              <h2>
                                <img
                                  src="/assets/images/rupee-blue.png"
                                  className="rupee"
                                />{" "}
                                <span>
                                  {" "}
                                  {location.state.item.bid_amount} /-
                                </span>
                              </h2>
                              <p> {location.state.item.budjet} </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {location.state.item.payment_milestone.map((value) => (
                      <div className="milestone-list">
                        <div className="row">
                          <div className="col-md-7 padding-0">
                            <h6>
                              {" "}
                              {location.state.item.payment_term ==
                              "By Milestone"
                                ? value.milestone_descriction
                                : value.name}
                            </h6>
                            <h2>
                              <img
                                src="/assets/images/rupee-blue.png"
                                className="rupee"
                              />{" "}
                              <span>{value.amount}/-</span>
                            </h2>
                            <p>{value.milestone_comment}</p>
                          </div>
                          <div className="col-md-5 padding-0">
                            <a
                              href="#"
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault();
                                if (value.status == "Start") {
                                  updateStatus(
                                    location.state.item.id,
                                    value.name,
                                    "Completed"
                                  );
                                } else if (value.status == "Completed") {
                                  updateStatus(
                                    location.state.item.id,
                                    value.name,
                                    "Request To Pay"
                                  );
                                } else if (value.status == "Request To Pay") {
                                  updateStatus(
                                    location.state.item.id,
                                    value.name,
                                    "Payment Requested"
                                  );
                                }
                              }}
                            >
                              {value.status}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Loader loading={loading} />
                </LoadingOverlay>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={mapView}
        onRequestClose={() => setMapView(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
        <div className="modal-dialog loginpopup" role="document">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <h5 className="modal-title">
                View Location on Map
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setMapView(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <GoogleMapReact
                  style={{ height: 400 }}
                  bootstrapURLKeys={{
                    key: "AIzaSyDhKv7Zr8AbpBwP84oMc7mRgcqMOjmI340",
                  }}
                  defaultCenter={{
                    lat: parseFloat(location.state.item.latitude),
                    lng: parseFloat(location.state.item.longitude),
                  }}
                  defaultZoom={11}
                >
                  <MarkerComponent text="My Marker" />
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      
    </section>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 1000 },
};
export default connect(mapStateToProps, mapDispatchToProps)(MyJobsDetailView);
