import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { commonService } from "../../Network/services/commonService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MyJobsView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [profile, setProfile] = useState({});
  const [name, setName] = useState("");
  const [postedJobList, setPostedJobList] = useState([]);
  const [awardedJoblist, setAwardedJoblist] = useState([]);
  const [postJobErrormsg, setpostJobErrormsg] = useState("");
  const [awardedJobErrormsg, setawardedJobErrormsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));

    onGoingJob();
    setName(user.first_name + " " + user.last_name);
    let personal_preference_name1_array = user.personal_preference_name1.split(
      ","
    );
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const redirect = () => {
    history.push("/ExperienceUpdate");
  };
  const onGoingJob = () => {
    setLoading(true);

    const FormData = require("form-data");

    const form = new FormData();
    form.append("job_type", 1);

    commonService
      .CommonService("explore_my_jobs", form)
      .then(async (res) => {
        if (res.status == 200) {
          setPostedJobList(res.data);
        } else {
          setpostJobErrormsg(res.message)
        }
      })
      .catch((err) => {
        setpostJobErrormsg("Something went wrong! please try again later!!")

      })
      .finally(() => {
        setLoading(false);

        alljob("");
      });
  };


  const openInNewTab = (url) => {
    window.open("http://www.google.com",'','postwindow');
    // const newWindow =window.open(url,'winname',"directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=400,height=350");
    // //const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    // if (newWindow) newWindow.opener = null
  }


  const alljob = (filter) => {
    setLoading(true);
    setAwardedJoblist([]);
    setawardedJobErrormsg("")

    const FormData = require("form-data");

    const form = new FormData();
    form.append("job_type", 2);
    form.append("filter", filter);
    commonService
      .CommonService("explore_my_jobs", form)
      .then(async (res) => {
        if (res.status == 200) {
          setAwardedJoblist(res.data);
        } else {
          setawardedJobErrormsg(res.message)

        }
      })
      .catch((err) => {
        setawardedJobErrormsg("Something went wrong! please try again later!!")

      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="post submitted">
      <div className="container">
        <div className="banner paddimg-bottom">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="/">
                      <i className="bx bx-chevron-left" /> Back to all jobs
                    </a>
                  </li>
                </ul>
                <h1 className="quotes-title paddimg-bottom">View Jobs I'm doing</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="quotes">
          <div className="tabset">
            {/* Tab 1 */}
            <input
              type="radio"
              name="tabset"
              id="tab1"
              aria-controls="marzen"
              defaultChecked
            />
            <label htmlFor="tab1">Ongoing Jobs</label>
            {/* Tab 2 */}
            <input
              type="radio"
              name="tabset"
              id="tab2"
              aria-controls="rauchbier"
            />
            <label htmlFor="tab2">All Jobs</label>
            <div className="tab-panels">
              <section id="marzen" className="tab-panel">
                {/*----------------------- List Start  ---------------------*/}
                {!postedJobList.size>0?(
                <h6 className="quotes-title paddimg-bottom">{postJobErrormsg}</h6>):null}
                <LoadingOverlay>
                  {postedJobList.map((item) => (
                    <div className="post-list">
                      <Link
                        style={{ width: "100%" }}
                        to={{
                          pathname: `/myjobsdetail/${item.id}/`,
                          state: { item },
                        }}
                      >
                        <div className="post-list-icon">
                          <img src="assets/images/post-list.png" />
                        </div>
                        <div className="count">
                          <ul>
                            <li>
                              <img
                                src="assets/images/job-id.png"
                                className="job-id"
                              />{" "}
                              Job#{" "}
                              <span>
                                {" "}
                                {item.job_unique_id.substr(
                                  item.job_unique_id.indexOf(" ") + 1
                                )}
                              </span>
                            </li>
                            <li className="line">
                              Awarded on <span>{item.awarded_date}</span>
                            </li>
                            {/* <li class="line">Quotes Received <span>398</span></li>
							<li class="line">Posted <span>15 minutes ago</span></li> */}
                            {item.is_fws_verified != null &&
                            item.is_fws_verified == true ? (
                              <li className="verified">
                                <img
                                  src="assets/images/verified.png"
                                  className="verified"
                                />{" "}
                                Verified user by GigFWS
                              </li>
                            ) : null}
                          </ul>
                        </div>
                        <h1>{item.job_title}</h1>
                        <div className="details">
                          <ul>
                            <li>{item.job_location}</li>
                            <li className="line">{item.payment_term}</li>
                            {/* <li class="line font-normal">Budget <img src="assets/images/rupee.png" class="rupee"> <span>4,90,000</span></li>
							<li class="line font-normal">Job Expiry <span>31 Jan 2021</span></li> */}
                          </ul>
                        </div>
                        <div className="quoted">
                          <ul>
                            <li>
                              <a href="#" />
                              <a href="#!" className="button">
                                {item.is_completed}
                              </a>
                            </li>
                            {/* <li className="eidt">
                              <a href="#" className="message">
                                <img src="assets/images/message-alert.png" />{" "}
                                Message
                              </a>
                            </li> */}
                          </ul>
                          {item.attach_file!=null?(
                               

                               <div className="files" onClick={() => openInNewTab(item.attach_file)}>
                                         <label htmlFor="file">
                                           <img src="/assets/images/attachments.png" />
                                          Attachement
                                         </label>
                                       </div> ):null}
                        </div>
                      </Link>
                    </div>
                  ))}
                  {/*----------------------- List End  ---------------------*/}
                  <Loader loading={loading} />
                </LoadingOverlay>
              </section>
              <section id="rauchbier" className="tab-panel">
              <Select
                    className="form-control"
                    options={[
                      {label: 'All', value: '', selected: true},
                      {label: 'Awarded', value: 'Awarded'},
                      {label: 'Completed', value: 'Completed'},
                    ]}
                    onChange={(values) => {
                      console.log(values[0].value);
                      alljob(values[0].value)
                    }}
                  />
              {!awardedJoblist.size>0?(
                <h6 className="quotes-title paddimg-bottom">{awardedJobErrormsg}</h6>):null}
                <LoadingOverlay>
                  {awardedJoblist.map((item) => (
                    <div className="post-list">
                      <Link
                        style={{ width: "100%" }}
                        to={{
                          pathname: `/myjobsdetail/${item.id}/`,
                          state: { item },
                        }}
                      >
                        <div className="post-list-icon">
                          <img src="assets/images/post-list.png" />
                        </div>
                        <div className="count">
                          <ul>
                            <li>
                              <img
                                src="assets/images/job-id.png"
                                className="job-id"
                              />{" "}
                              Job#{" "}
                              <span>
                                {" "}
                                {item.job_unique_id.substr(
                                  item.job_unique_id.indexOf(" ") + 1
                                )}
                              </span>
                            </li>
                            <li className="line">
                              Awarded on <span>{item.awarded_date}</span>
                            </li>
                            {/* <li class="line">Quotes Received <span>398</span></li>
							<li class="line">Posted <span>15 minutes ago</span></li> */}
                            {item.is_fws_verified != null &&
                            item.is_fws_verified == true ? (
                              <li className="verified">
                                <img
                                  src="assets/images/verified.png"
                                  className="verified"
                                />{" "}
                                Verified user by GigFWS
                              </li>
                            ) : null}
                          </ul>
                        </div>
                        <h1>{item.job_title}</h1>
                        <div className="details">
                          <ul>
                            <li>{item.job_location}</li>
                            <li className="line">{item.payment_term}</li>
                            {/* <li class="line font-normal">Budget <img src="assets/images/rupee.png" class="rupee"> <span>4,90,000</span></li>
							<li class="line font-normal">Job Expiry <span>31 Jan 2021</span></li> */}
                          </ul>
                        </div>
                        <div className="quoted">
                          <ul>
                            <li>
                              <a href="#" />
                              <a href="#!" className="button">
                                {item.is_completed}
                              </a>
                            </li>
                            {/* <li className="eidt">
                              <a href="#" className="message">
                                <img src="assets/images/message-alert.png" />{" "}
                                Message
                              </a>
                            </li> */}
                          </ul>
                          {item.attach_file!=null?(
                               

                               <div className="files" onClick={() => openInNewTab(item.attach_file)}>
                                         <label htmlFor="file">
                                           <img src="/assets/images/attachments.png" />
                                          Attachement
                                         </label>
                                       </div> ):null}
                        </div>
                      </Link>
                    </div>
                  ))}
                  {/*----------------------- List End  ---------------------*/}
                  <Loader loading={loading} />
                </LoadingOverlay>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyJobsView);
