import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { profileUpdateService } from "../../Network/services/profileUpdateService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import WebcamCapture from "./WebcamCapture";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const VerifyUser = (props) => {
  
  let location = useLocation();
  let history = useHistory();
  
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");


  const randomString = (length) => {
    //const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
 
  
  //get captcha video
  const getFileFromWebcam = (fileData) => {
   
    const userInfo = fileData;

    // console.log("Captcha: ");
    // console.log(userInfo[1]);
    // console.log("Captcha file: ");
    // console.log(userInfo[0]);
    history.push({ 
      pathname: '/ekycEdit',
      state: { userInfo: userInfo }
     });
  }

  const getIsCameraActive = (fileData) => {

    if(fileData=="nocamera"){
      history.push({ 
        pathname: '/profile'
       });
    }
  }
 
  useEffect(() => {
    window.scrollTo(0, 0)
    console.log(      
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);
    
 
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <section className="explore explore1">
    <LoadingOverlay>
    <div className="container">
      <div className="row">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="#!" onClick={(e) => back(e)}>
                      <i className="bx bx-chevron-left" /> Back to View demo
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section className="onbord-section">
        <WebcamCapture uname={name} captcha={randomString(6)} getFile={getFileFromWebcam} isCamera={getIsCameraActive} />
        </section>
      </div>
    </div>
     
      <Loader loading={loading} />
    </LoadingOverlay>
    
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);
