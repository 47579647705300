import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { commonService } from "../../Network/services/commonService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const BusinessView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [profile, setProfile] = useState({});
  const [openComplaints, setOpenComplaints] = useState([]);
  const [closedComplaints, setClosedComplaints] = useState([]);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [purpose, setPurpose] = useState('');
  const [where, setWhere] = useState('');
  const [company, setCompany] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [requirment, setRequirment] = useState('');

  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));

 
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const redirect = () => {
    history.push("/ExperienceUpdate");
  };
 
  const submitClick = () => {
    if (name == '') {
      toast.success("Enter valid name", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
    if (purpose == '') {
      toast.success("Enter valid purpose", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }

    if (where == '') {
      toast.success("Enter valid details", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }

    if (company == '') {
      toast.success("Enter valid company", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
    if (mobile == '') {
      toast.success("Enter valid mobilenumber", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(email) === false) {
      toast.success("Enter valid email", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
   
    if (address1 == '') {
      toast.success("Enter valid address", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
    // if(name==''){
    //   setAddress2Validation('Enter valid name')
    //   return;
    // }

    // if(name==''){
    //   setAddress3Validation('Enter valid name')
    //   return;
    // }

    if (requirment == '') {
      toast.success("Enter valid deails", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
    setLoading(true);
    const FormData = require("form-data");

    const form = new FormData();
    form.append('name',name);
    form.append('purpose',purpose);
    form.append('fws_known',where);
    form.append('phone',email);
    form.append('email',email);
    form.append('address1',address1);
    form.append('address2',address2);
    form.append('address3',"address3");
    form.append('company',company);
    form.append('post_requirement',requirment);
    commonService
      .CommonService("fws_business", form)
      .then(async (res) => {
        if (res.status == 200) {
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          history.goBack();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="explore explore1">
            <LoadingOverlay>

        <div className="container">
        <section className="contract">
        <div className="container">
          <div className="banner paddimg-bottom">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumps">
                  <ul>
                    <li><a href="/"><i className="bx bx-chevron-left" /> Back to all jobs</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
          <section className="onbord-section">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="title">For Business</h1>
                <p className="subtitle" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="board-box board-box2 post-new-job">
                  <form>
                    <div className="form-group">
                      <label>Name</label>
                      <input type="textbox" className="form-control" required="required" placeholder="Name" 
                       value={name}
                       onChange={(e) => {
                         setName(e.target.value);
                       }} />
                    </div>
                    <div className="form-group ">
                      <label>Purpose</label>
                      <input type="textbox" className="form-control" required="required" placeholder="Purpose" 
                       value={purpose}
                       onChange={(e) => {
                         setPurpose(e.target.value);
                       }} />
                    </div>
                    <div className="form-group">
                      <label>Where did you here about gigFWS?</label>
                      <input type="textbox" className="form-control" required="required" placeholder="Where did you here about gigFWS?" 
                       value={where}
                       onChange={(e) => {
                         setWhere(e.target.value);
                       }} />
                    </div>
                    <div className="form-group ">
                      <label>Add Organization / Company</label>
                      <input type="textbox" className="form-control" required="required" placeholder="Add Organization / Company" 
                       value={company}
                       onChange={(e) => {
                         setCompany(e.target.value);
                       }} />
                    </div> 
                    <div className="form-group">
                      <label>Phone</label>
                      <input type="textbox" className="form-control" required="required" placeholder="Phone" 
                       value={mobile}
                       onChange={(e) => {
                         setMobile(e.target.value);
                       }} />
                    </div>
                    <div className="form-group ">
                      <label>Email</label>
                      <input type="textbox" className="form-control" required="required" placeholder="Email" 
                       value={email}
                       onChange={(e) => {
                         setEmail(e.target.value);
                       }} />
                    </div>
                    <div className="form-group">
                      <label>Address Line 1</label>
                      <input type="textbox" className="form-control" required="required" placeholder="Address Line 1" 
                       value={address1}
                       onChange={(e) => {
                         setAddress1(e.target.value);
                       }} />
                    </div>
                    <div className="form-group ">
                      <label>Address Line 2</label>
                      <input type="textbox" className="form-control" required="required" placeholder="Address Line 2"  value={address2}
                       onChange={(e) => {
                         setAddress2(e.target.value);
                       }}
                       />
                    </div>
                    <div className="form-group">
                      <label>Post Your Requirement</label>
                      <textarea className="form-control" required="required" placeholder="Post Your Requirement" defaultValue={""}  value={requirment}
                       onChange={(e) => {
                         setRequirment(e.target.value);
                       }} />
                    </div>
                    <button onClick={(e)=>{
                      e.preventDefault();
                      submitClick();
                    }}>SUBMIT</button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Loader loading={loading} />
      </LoadingOverlay>
      </section>


  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessView);
