import { array } from "prop-types";
import React, {ReactDOM} from "react";
import Webcam from "react-webcam";

import Modal from "react-modal";

const videoStyle = {
  width: "100%",
  border: "2px solid #ccc" 
};

const kycVideoContainer = {
  width: "40%",
  margin: "0 auto",
  position:"relative"
}

const WebcamCapture = (props) => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  const [getVideo, setGetVideo] = React.useState("");

  const [isPreviewModelOpen, setIsPreviewModelOpen] = React.useState(false);

  const handleStartCaptureClick = React.useCallback(() => {
    setRecordedChunks([]);
    setGetVideo("");
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.mp4";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const previewVideo = React.useCallback(() => {
    console.log('clicked')
    if (recordedChunks.length) {
      // const blob = new Blob(recordedChunks, {
      //   type: "video/webm"
      // });
      const blob = new Blob(recordedChunks, {
        type: "video/mp4"
      });
      const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // document.body.appendChild(a);
      // a.style = "display: none";
      // a.href = url;
      // a.download = "react-webcam-stream-capture.webm";
      // a.click();


      // const userCaptchaVideoInfo = blob;
      // const tdate = new Date().getTime();
      // let videoFileName = "KycUser"+tdate;      
      // userCaptchaVideoInfo.lastModified = new Date();
      // userCaptchaVideoInfo.name = videoFileName;
      // let userVideoFile = new File([userCaptchaVideoInfo], videoFileName);
      // const myFile = new File([userVideoFile], videoFileName, {
      //     type: "video/mp4",
      // }); 

      setGetVideo(url);
      setIsPreviewModelOpen(true);

      //window.URL.revokeObjectURL(url);
      //setRecordedChunks([]);
      
    }
  }, [recordedChunks]);

  const handleViewUrl = React.useCallback(() => {
    console.log('clicked')
    if (recordedChunks.length) {
      // const blob = new Blob(recordedChunks, {
      //   type: "video/webm"
      // });
      const blob = new Blob(recordedChunks, {
        type: "video/mp4"
      });
      //const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // document.body.appendChild(a);
      // a.style = "display: none";
      // a.href = url;
      // a.download = "react-webcam-stream-capture.webm";
      // a.click();

      
      console.log(blob);
      //send captcha video to verifyUser component
      let userVideoAndCaptcha = [blob,props.captcha];
      props.getFile(userVideoAndCaptcha);
      
      //window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);



  // const cameraStatus = () => {
  //   navigator.getUserMedia({
  //     video: true
  //   }, () => {
  //     alert('has webcam')
  //   }, () => {
  //     alert('no webcam')
  //   });
  // }

  // const noCamera = () => {
  //   alert('Please use webcam to proceed further ')
  //   // props.isCamera("nocamera");
  // }

  const noCamera = React.useCallback(() => {
    alert('Please provide permission to access webcam')
    props.isCamera("nocamera");
  }, []);


  let closePreviewModelOpen = () => {
    setIsPreviewModelOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "100%",
      background: "transparent",
    },
    overlay: { zIndex: 1000 },
  };

  const videoPreviewWrapper = {
    position: "relative",
    paddingBottom: "56.25%",
    height: "0"
  };


  const videoWrapper = {
    position: "relative",
    paddingBottom: "56.25%",
    height: "0"
  };

  
  return (
    <div className="post-new-job" style={{textAlign:"center"}}>
      <div style={kycVideoContainer}>
        <Webcam audio={true} muted onUserMediaError={noCamera} ref={webcamRef} style={videoStyle} />
        <div className="kyc-captcha" style={{position: "absolute", zIndex : "111", width:"100%", bottom:"6%"}}>
          <div style={{padding:"10px", background:"rgba(0,0,0,.5)", display: "inline-block", color: "#fff"}}>
            <p style={{margin:"0", color: "#fff"}}>READ THE WORDS</p>
            <p style={{margin:"0", color: "#ccc"}}>I am {props.uname}</p>
            {props.captcha}
          </div>
        </div>
      </div>

      {capturing ? (
        <button onClick={handleStopCaptureClick}>Stop Capture</button>
      ) : (
        <button onClick={handleStartCaptureClick}>Start Capture</button>
      )}

        <br/>       
      
      {recordedChunks.length > 0 && (
        <>  
        <br/> 
        <button onClick={previewVideo}>Preview</button>&nbsp;
        <button onClick={handleViewUrl}>Upload</button>
        {/* <button onClick={handleDownload}>Download</button> */}
        </>
      )}


      <Modal
          isOpen={isPreviewModelOpen}
          onRequestClose={closePreviewModelOpen}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <div className="modal-dialog loginpopup" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Preview</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closePreviewModelOpen}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                  <div className="videoWrapper" style={videoWrapper}>
                      { console.log("getVideo") }
                      { console.log(getVideo) }
                      <video src={getVideo} controls width="100%">
                        Sorry, your browser doesn't support videos.
                      </video>
                  </div>  
              </div>
            </div>
          </div>
        </Modal>



    </div>
  );
};

export default WebcamCapture;
