import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { commonService } from "../Network/services/commonService";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";


const ShareJob = (props) => {
    const [openModel, setopenModel] = useState(false);
    const [friendsList, setFriendsList] = useState([]);
    const [filteredFriendsList, setFilteredFriendsList] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setopenModel(props.isOpen);
        getAlluser();
      }, [props]);

      const getAlluser = () => {
        setLoading(true);
        const FormData = require("form-data");
    
        const form = new FormData();
        form.append('search',"");

        commonService
          .CommonService("friends_search", form)
          .then(async (res) => {
            if (res.status == 200) {
                setFriendsList(res.data);
            } else {
             
            }
          })
          .catch((err) => {
          
          })
          .finally(() => {
            setLoading(false);

          });
      };
      const shareJobNow = () => {
        setLoading(true);
        const FormData = require("form-data");
    
        const form = new FormData();
        form.append('job_id',props.jobId);
        form.append('send_to_user_id',selectedItem);
        console.log("request ",props.jobId);

        commonService
          .CommonService("share_job", form)
          .then(async (res) => {
            if (res.status == 200) {
                toast.success(res.message, {
                    position: "top-right",
                    type: "success",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                  });
            } else {
                toast.success(res.message, {
                    position: "top-right",
                    type: "error",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                  });
            }
          })
          .catch((err) => {
            toast.success(err.toString(), {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
              });
          })
          .finally(() => {
            setLoading(false);
            props.toggle();
          });
      };
      const searchfriends = (text) => {
        //it will show indicator at the bottom of the list when data is loading otherwise it returns null
        console.log("key text    ",text);
        var searchtxt1 = friendsList.filter(
          (item) => item.first_name.toString().toUpperCase().includes(text.toString().toUpperCase()),
    
          // item.first_name.toUpperCase().includes(text.toUpperCase()),
        );
        console.log("filterted list ",searchtxt1);
        setFilteredFriendsList(searchtxt1);
      };


  return (
    <div>
         <Modal
        isOpen={props.isOpen}
        onRequestClose={() => setopenModel(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
                  <LoadingOverlay>
                  <div className="modal-dialog loginpopup" role="document">
        <div className="modal-content" onClick={(e)=>{e.preventDefault()}}>
                  <div className="loginpopup dashboard signin">
        <div className="modal-header">
          <h5 className="modal-title">Share Job to Friends</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={()=>{
              props.toggle()
            }} >
              <span aria-hidden="true">×</span>
            </button>
        </div>
        <div className="modal-body" style={{paddingBottom: '30px'}}>
          <div className="profile-detail qualification referance">
            <div className="col-md-12 search-frnds-sec">
              <input type="textbox" placeholder="Search Your Friends" onClick={(e)=>{e.preventDefault()}} className="serch-friends" onChange={(e)=>searchfriends(e.target.value)} />
            </div>
            <div className="scroll-bar-sec">
            {(filteredFriendsList && filteredFriendsList.length > 0
                    ? filteredFriendsList
                    : friendsList).map((item) => (
              <div className="row"  style={{backgroundColor:selectedItem == item.id ? '#cadeed' : '#EBEBEB'}} onClick={(e)=>{
                  e.preventDefault();
    setSelectedItem(item.id)
              }}>
                <div className="col-md-2 padding-0">
                  <img src="/assets/images/Group 3064.png" />
                </div>
                <div className="col-md-10 padding-0">
                  <p>{item.first_name}<span>{item.skills}</span></p>
                </div>
              </div>
            ))
}
             
            </div>
            <button  className="btn btn-default" onClick={(e)=>{
                e.preventDefault();
                shareJobNow();
            }}>Share Now</button>
          </div>
        </div>
      </div>

    </div>
    </div>
        <Loader loading={loading} />
          </LoadingOverlay>
      </Modal>
    </div>
  );
};
const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "100%",
      background: "transparent",
    },
    overlay: { zIndex: 1000 },
  };
export default ShareJob;