import React, { useEffect, useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { JobService } from "../../Network/services/JobService";

import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { commonService } from "../../Network/services/commonService";
import Modal from "react-modal";
import GoogleMapReact from "google-map-react";
import NewWindow from 'react-new-window'
import PaymentScreen from "./PaymentScreen";
import { render, createPortal } from "react-dom";
import { Rating } from 'react-simple-star-rating'


const EscrowStatus = () => {
  // const [job, setJob] = useState(props.state);
  let history = useHistory();
  let location = useLocation();
  let {search} = useLocation();
  let query = React.useMemo(() => new URLSearchParams(search), [search]);
 
  const [loading, setLoading] = useState(false);
  const [order_id, setorder_id] = useState("");
  const [paymentStatus, setpaymentStatus] = useState("Please Wait...");
  
  
  useEffect(() => {
    window.scrollTo(0, 0)  
    setorder_id(query.get("order_id"))
    setLoading(true);
  
    const form = new FormData();  
    form.append("order_id", query.get("order_id"));
    commonService
      .CommonService("escrow_payment_status", form)
      .then(async (res) => {

        setpaymentStatus(res.message);

        if (res.status == 200) {
          if (res.payment_status == "SUCCESS") {
            
            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            // history.goBack();
            history.push("/mycontracts");

          } else if (res.payment_status == "PENDING" || res.payment_status == "NOT_ATTEMPTED") {
           
          
          } else {
           
            // history.goBack();

            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            history.push("/mycontracts");
          }

          // await setAuthAsyncStorage(res);
        } else {
            
        //   history.goBack();

          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          history.push("/mycontracts");
        }
      })
      .catch((err) => {
        setpaymentStatus(err.message);
        toast.success(err.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
        history.push("/mycontracts");
      })
      .finally(() => {
        setLoading(false);
      });
 
  }, []);

  return (
    <section className="explore explore1">
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <div >
                      <a href="#">
                        <i className="bx bx-chevron-left" /> Back to Jobs I posted
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
            <p>
            
            </p>
            </div>
          </div>
        </div>

        <div className="explore-details">
          <div className="row">
          <p style={{textAlign:"center"}}>{paymentStatus} </p>
          </div>
        </div>
        
        
      </div>
     
      
    </section>
  );
};

export default EscrowStatus;
