import axios from 'axios';
import { API_URL } from '../constants';
import APIKit from '../APIKit';
  
  export const getConnectList = (payload) => {
    // console.log("payload==========" + JSON.stringify(payload));
  
    return new Promise(async (resolve, reject) => {
      try {
        payload.append("auth_token", window.localStorage.getItem("auth_token"));
      } catch (e) {
        // console.log("failed===========" + e);
      }
      
      APIKit.post("/fws_connected_user", payload)
        .then(async (response) => {
          try {
            // console.log("response===========" + response.status);
            // console.log(JSON.stringify(response.data.status));
            console.log("response======data=====" + response.data.status);
  
            // await setAuthAsyncStorage(response);
            resolve(response.data);
          } catch (e) {
            // console.log("err===========" + e);
  
            reject(e);
          }
        })
        .catch((err) => {
          // console.log("err===========" + err);
  
          reject(err);
        });
    });
  }

  export const connectRequest = (payload) => {
    // console.log("payload==========" + JSON.stringify(payload));
  
    return new Promise(async (resolve, reject) => {
      try {
        payload.append("auth_token", window.localStorage.getItem("auth_token"));
      } catch (e) {
        // console.log("failed===========" + e);
      }
      
      APIKit.post("/connect_fws", payload)
        .then(async (response) => {
          try {
            // console.log("response===========" + response.status);
            // console.log(JSON.stringify(response.data.status));
            console.log("response======data=====" + response.data.status);
  
            // await setAuthAsyncStorage(response);
            resolve(response.data);
          } catch (e) {
            // console.log("err===========" + e);
  
            reject(e);
          }
        })
        .catch((err) => {
          // console.log("err===========" + err);
  
          reject(err);
        });
    });
  }