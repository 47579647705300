import React from "react";

const customStyles = {
  fontWeight: "400"
};
const TermsAndConditions = () => {
    return (        
      <section class="trems-sec">
      <div class="container">
         <h4>About GigFWS (FUTUREWORKFORCE SOLUTIONS)</h4>
         
         <span style={customStyles}>“FWS” in this legal statement (these “Terms” or this “Agreement” refers to FUTUREWORKFORCE SOLUTIONS LLP (“FWS”). GigFWS is a limited liability partnership firm.</span>
         
        
       <h4>The Human Resource Freelance Marketplace as an Application on the Web, Playstore (Android) and App store (iOS)</h4>
         
         <span style={customStyles}>These terms apply to your use of the GigFWS Web application <a href="http://www.gigfws.com">www.gigfws.com</a> and GigFWS applications on play store and app store</span>
         
           <h4>Legal Disclaimer</h4>
         <span style={customStyles}>By using or accessing the GigFWS applications, you are agreeing to be bound by the following terms and conditions. Please read these Terms carefully before using the applications. If you do not accept these Terms (in whole or in part) you may not access or use the applications. Not adhering to these terms, GigFWS may, in its absolute discretion, refuse your access to the applications at any time and for any reason.</span>
         
        
       <h4>Content</h4>
         
         <span style={customStyles}>All the content on the applications and all the services enabled through it are provided “as is”, with no guarantees of completeness, accuracy or timeliness, and without representations, warranties or other contractual terms of any kind, express or implied. GigFWS does not represent or warrant that the application, the various services enabled/ provided through the application, and / or any information, software or other material downloaded from the application, will be accurate, current, uninterrupted, error-free, omission-free or free of viruses or other harmful components.</span>
         
         
         <span style={customStyles}>To an extent permitted by LAW, GigFWS disclaims all liabilities and responsibilities for any errors or omissions in the content contained on the application. GigFWS may delete any posted content that it considers, in its absolute discretion, to be in breach of these Terms or to have the potential to cause reputational, operational or other harm to GigFWS or any other person or entity.</span>
         
         
         <span style={customStyles}>Any information you submit through the application may be provided by/ through GigFWS to other GigFWS user(s), and you may subsequently be contacted by GigFWS users as per the need/ purpose. You are responsible for ensuring that any personal information submitted by or about you on the application or to the GigFWS users is accurate, complete, and up-to-date. The submission of inaccurate, false, or incomplete information may make you ineligible for opportunities or cause any relationship you may have with GigFWS or with other GigFWS users to be terminated.</span>
         
         
         <span style={customStyles}>You agree to GigFWS displaying your name on the Application in the context of GigFWS. You acknowledge that, if you post any content on the application, it does not constitute any confidential information and you consent to FWS’s use of such content for verification purpose, enabling talent opportunity and sharing and any other GigFWS official use in order to enable the better service for GigFWS users.</span>
         
         
         <span style={customStyles}>By using the application, you warrant that you own or are licensed to use the intellectual property rights in your posted content. This includes copyright in respect of any text you post, as well as the right to use or display any image or logo. You agree to indemnify GigFWS from and against any claims by third parties arising from your posted content, including any breach of intellectual property rights any third party may bring in relation to your content, unless those claims arise from GigFWS. </span>
         
         
         <span style={customStyles}>For all your Posted Content, you warrant and you must ensure:</span>
  
         <ul>
          <li><span style={customStyles}>it is not misleading, deceptive or materially inaccurate in any way, including in relation to the availability, nature, terms or conditions or any other matter relating to the services being offered or sought; and</span></li>
          <li><span style={customStyles}>it is compliant with all applicable laws and licensing requirements.</span></li>
         </ul>
  
         
         <span style={customStyles}>GigFWS may remove any Posted Content if it considers, acting reasonably, that you or any other users have breached these Terms or if, in its sole discretion, GigFWS believes that maintaining the Posted Content presents a risk to FWS’s reputation or business and operations (including the application).</span>
         
         
         <span style={customStyles}>Notwithstanding the foregoing, GigFWS does not, nor is GigFWS under any obligation or duty to monitor, consider, evaluate, assess, review, screen, censor or remove any Posted Content. GigFWS does not have (and expressly disclaims) any liability in connection with any monitoring, consideration, evaluation, assessment, review, screening, censoring or removal of Posted Content or any failure or refusal on FWS’s part to do so.</span>
         
         
         <span style={customStyles}>GigFWS reserves the right to keep your Posted Content on the application indefinitely unless removed earlier by you or by GigFWS itself for security and legal reasons. </span>
         
  
         <span style={customStyles}>By using this website, you are bound by the </span><a href="https://maps.google.com/help/terms_maps/"><span style={customStyles}>Google Maps/Google Earth Additional Terms of Service</span></a><span style={customStyles}> and </span><a href="https://policies.google.com/privacy"><span style={customStyles}>Google Privacy Policy Terms.</span></a>
         
         
         <span style={customStyles}>GigFWS DOES NOT HAVE, AND EXPRESSLY DISCLAIMS, ANY LIABILITY TO YOU IN CONNECTION WITH ANY CONTENT, INFORMATION, TEXT, GRAPHICS, IMAGES, AUDIO OR VIDEO, OR MATERIAL YOU CREATE OR UPLOAD TO YOUR PROFILES AND/OR THE APPLICATION (“POSTED CONTENT”). GigFWS DOES NOT GUARANTEE, AND MAKES NO REPRESENTATIONS IN RELATION TO THE COMPLETENESS OR ACCURACY OF ANY POSTED CONTENT.</span>
         
        
       <h4>Prohibited Content and Prohibited Activities</h4>
  
         <span style={customStyles}>Notwithstanding anything to the contrary in these Terms, you must not:</span>
         
         <ul>
          <li><span style={customStyles}>attempt to decipher, decompile, disassemble or reverse engineer any of the software or code comprising or in any way making up a part of the application (all web, android and iOS) including any algorithm used by the application;</span></li>
         
          <li style={customStyles} aria-level="1"><span style={customStyles}>use the application for any objectionable or unlawful purpose, including the posting of any threatening, defamatory, obscene, pornographic, profane or illegal material;</span></li>
         
          <li><span style={customStyles}>use the application that would result in your breaching any applicable legislation or licensing obligations (including with respect to privacy) or any obligations you may owe to third parties;</span></li>
          <li><span style={customStyles}>mislead or deceive others through any act or omission or make a false representation about your identity, including the impersonation of a real or fictitious person or using an alternative identity or pseudonym;</span></li>
          <li><span style={customStyles}>take any action that imposes an unreasonable or disproportionately large load on the application’s architecture and infrastructure, including spam or other unsolicited techniques;</span></li>
          <li><span style={customStyles}>post content which contravenes a confidentiality or non-disclosure agreement, insider trading laws, or intellectual property rights which you are not authorized to transfer to another party;</span></li>
          <li><span style={customStyles}>conduct any activity which compromises or breaches another party's patent rights, trademark, copyright or other intellectual property rights;</span></li>
          <li><span style={customStyles}>copy, collect or save information about other users including their skills, employment or education history and any other resourceful information;</span></li>
          <li><span style={customStyles}>publish advertising material or market any goods or services directly to other users;</span></li>
          <li><span style={customStyles}>introduce any virus, worm, malicious code or other program which may damage computers or other equipment or any physical or virtual properties/ equipment;</span></li>
          <li><span style={customStyles}>stalk or harass anyone;</span></li>
          <li><span style={customStyles}>attempt to disrupt or interfere with the delivery of our services through the applications;</span></li>
          <li><span style={customStyles}>use the details of other users for anything other than the use expressly permitted by those users;</span></li>
          <li><span style={customStyles}>download, access or use user details other than to fill vacant gig opportunities as contemplated by the Application or as otherwise explicitly permitted by GigFWS</span></li>
          <li><span style={customStyles}>sell, redistribute or use information contained on the Application for a commercial purpose without FWS’s prior written consent;</span></li>
          <li><span style={customStyles}>remove or alter copyright notices or other means of identification including any watermarks, as they appear on the application</span></li>
         </ul>
         
         <span style={customStyles}>You understand and agree that you are solely responsible for compliance with any laws, rules, regulations and taxation obligations that may apply to your use of application.</span>
         
         <h4>Your Account</h4>
         <span style={customStyles}>You are responsible for maintaining the confidentiality of any passwords associated with your account, monitoring all activity under the account, and assuming full responsibility for all activities that occur under your account.</span>
         
         <span style={customStyles}>When you sign up, you may be requested to provide your name, email address, work/position title, phone number, Aadhaar number or your Company PAN/ GSTIN number and such other information to enable your registration. These are your credentials for accessing the services on the application which is only available to registered members. </span>
         
         <span style={customStyles}>By/ in doing so, you agree that you:</span>
  
         <ul>
          <li><span style={customStyles}>are not impersonating any person or entity; and</span></li>
          <li><span style={customStyles}>are not violating any applicable district, state or other jurisdictional law regarding use of personal or identification information.</span></li>
         </ul>
  
         <span style={customStyles}>GigFWS may, from time to time, use any of the information you submit to verify the completeness, accuracy or truthfulness of the information you have provided on the Application. Your failure to maintain accurate, complete, and up-to-date Account information, including uploading an invalid or illegible form of identification, or your failure to provide additional information/ evidence requested by GigFWS, may result in losing your access (fully or partially) to the application. You should not authorize third parties to use your account, and you should not assign or otherwise transfer your account to any other person or entity.</span>
         
           <h4>General Eligibility</h4>
  
         <span style={customStyles}>The application is not intended for use by persons under the age of 18. You must be at least 18 years old. You may not use the application and its services, if you do not have legal authorization to work in the country in which the postings to which you apply are to be performed. If any applicable law, rule or regulation prohibits your access of the application, you may not access or use the application. </span>
         
           <h4>Awarding the Work</h4>
  
         <span style={customStyles}>GigFWS users award the work to other GigFWS users at his/ her own discretion. No work will be awarded directly by GigFWS.</span>
         
        
           <h4>Copyright Notice</h4>
         
         <span style={customStyles}>All rights reserved. No part of the materials on this Application, including but not limited to the text, graphics and html code, formulas, may be reproduced or transmitted in any form, or by any means without FWS’s written approval.</span>
         
           <h4>Continuity of Service</h4>
  
         <span style={customStyles}>GigFWS targets to provide a service that is continually available and capable of use for GigFWS users. However, GigFWS may suspend your account and your access to the application at any time and for any reason, including without cause. The reasons why GigFWS might suspend your account/ access to the application includes but not limited to:</span>
         
         <ul>
          <li style={customStyles} aria-level="1"><span style={customStyles}>your breach of these Terms;</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Using GigFWS for illegal purpose (Illegal activities as per the Indian law)</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>a legal requirement, such as an injunction or due to an investigation by police or other law enforcement agency</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>faults, service outages or other technical problems;</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>you don't use your account for an extended period;</span></li>
         
        </ul>
         <span style={customStyles}>GigFWS WILL NOT HAVE ANY LIABILITY IN CONNECTION WITH ANY SUSPENSION OF YOUR ACCOUNT OR YOUR ACCESS TO THE APPLICATION, REGARDLESS OF THE REASON FOR SUSPENSION.</span>
        
        <h4>Miscellaneous</h4>
         
         <span style={customStyles}>GigFWS may revise/ update these terms at any time in its discretion. GigFWS strongly encourage and recommend you to periodically review these terms to be updated. Your continued use of the application following changes to these Terms constitutes your agreement to the revisions.</span>
  
         
         <span style={customStyles}>You provide your consent to GigFWS to may use your personal information and data according to GigFWS Privacy policy, which are incorporated herein by this reference.</span>
         
         
         <span style={customStyles}>These Terms constitute the entire agreement between you and GigFWS in relation to its subject matter. You agree that you have not relied on any representations by GigFWS in agreeing to these Terms, except as may be set forth in these Terms.</span>
         
  
         <span style={customStyles}>The parties (here in refers as GigFWS users) involved are independent contractors with respect to each other. These Terms do not constitute and shall not be construed as constituting a partnership or joint venture among the parties hereto, or an employee-employer relationship. You agree that GigFWS has no special relationship with or fiduciary duty to you. Neither party shall have any right, power or authority to enter into any agreement for, or on behalf of, or incur any obligation or liability of, or to otherwise bind, the other party.</span>
  
         
         <span style={customStyles}>The information presented on this application should not be construed as legal, tax, accounting or any other professional advice or service. You should consult with an GigFWS or other professional advisor familiar with your particular factual situation for advice concerning specific tax or other matters before making any decision.</span>
         
         
         <span style={customStyles}>These Terms, and any non-contractual matters or obligations arising out of these Terms of the application, including (without limitation) claims arising in tort, fraud, under statute or otherwise relating to the Services, or questions relating to the scope or enforceability of this paragraph, shall be governed by, and construed in accordance with, the Indian laws and fully to be performed, therein by residents thereof. Any dispute relating to or arising out of this Agreement or the Services, directly or indirectly, shall be resolved as set forth in the sections below entitled </span><strong><i>Online Dispute Resolution System.</i></strong><span style={customStyles}> </span>
         
        
           <h4>Limitation of liability</h4>
  
         
         <span style={customStyles}>IN NO EVENT WILL GigFWS ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APPLICATION AND ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE APPLICATION OR SUCH OTHER WEBSITES/ APPLICATION OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATION OR SUCH OTHER WEBSITES/ APPLICATION, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</span>
         
         
         <span style={customStyles}>IN ACCEPTING LIABILITY AS THE LIMITED AUTHORIZED JOB ENABLMENT AND PAYMENT COLLECTION AGENT OF SELLER AND SERVICE PROVIDER AND JOB POSTER, GigFWS AND ITS AFFILIATES ASSUME NO LIABILITY FOR ANY ACTS OR OMISSIONS OF GigFWS USERS.</span>
         
         
         <span style={customStyles}>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span>
         
        
           <h4>Online Dispute Resolution System</h4>
  
         
         <span style={customStyles}>For any kind of dispute between GigFWS users (Job poster and service providers), they can raise a ticket in the application. Both the parties involved should agree to the terms and conditions mentioned here in and abide to the policies and mutual user satisfaction for smooth and quick resolution(s)/ settlements.</span>
         
         
         <span style={customStyles}>The anticipated disputes are limited and relevant to the below;</span>
         
         <ul>
          <li><span style={customStyles}>Issues in the Service quality</span></li>
          <li><span style={customStyles}>Issues in the Service timeline</span></li>
          <li><span style={customStyles}>Issues in the Service delivery</span></li>
          <li><span style={customStyles}>Issues in Setting Project Expectations</span></li>
          <li><span style={customStyles}>Issues in Payments</span></li>
          <li><span style={customStyles}>Issues in the Service Provider Payment Settlements</span></li>
          <li><span style={customStyles}>Payment failures</span></li>
          <li><span style={customStyles}>Anything related and relevant to GigFWS services (Subject to terms and conditions and User agreement)</span></li>
         </ul>
  
         
         <span style={customStyles}>Step by step process of Ticketing system (By using GigFWS application, GigFWS User agrees to the below process)</span>
         
  
         <ol>
          <li><span style={customStyles}>User raise a ticket in the tool and get a unique ticket number associated to the project ID</span></li>
          <li><span style={customStyles}>GigFWS looks into the dispute and agree or disagree on the dispute claim with valid reasons</span></li>
          <li><span style={customStyles}>Upon agreeing, GigFWS notify the relevant parties involved (Job poster, service provider, payment gateway etc.) and seek for the relevant supporting documents (Agreed project scope, screen shot of the conversations, Actual images of the work, email conversations, Screen shot of payment failure, GigFWS messages etc.)</span></li>
          <li><span style={customStyles}>Relevant parties to upload the necessary documents (format supported – PDF, WORD, Images) with in the 5-7 days in the application from the notification day</span></li>
          <li><span style={customStyles}>GigFWS looks into the supporting documents, conversations and raise &amp; request for the solution and or additional supporting documents from the party where it is required, with 3-5 days from the day documents received</span></li>
          <li><span style={customStyles}>GigFWS will work towards settling the dispute for mutual benefit of the users and ticket will be closed and settled on mutual agreement of the users. The same will be stored in the system for future reference</span></li>
          <li><span style={customStyles}>If any dispute continues and if it is with in the GigFWS service mandate and boundaries, GigFWS will work with both the parties till both the parties get proper mutual resolution</span></li>
         </ol>
  
         
         <span style={customStyles}>GigFWS Online dispute resolution system is not responsible for the below;</span>
         
  
         <ul>
          <li><span style={customStyles}>Any major financial fraudulent actives according to the Indian Law act and regulations</span></li>
          <li><span style={customStyles}>Any Physical and unphysical abuse by words or actions</span></li>
          <li><span style={customStyles}>Any dispute according to Indian Criminal Law</span></li>
          <li><span style={customStyles}>Any loss that is not covered (with in the project scope) or anticipated within the GigFWS services</span></li>
         </ul>
        
           <h4>Project/ Service Cancellation Policy</h4>
  
         
         <span style={customStyles}>The project/ service will be cancelled when we identify the below;</span>
         <ul>
          <li style={customStyles} aria-level="1"><span style={customStyles}>You are not a registered user of GigFWS but attempt to provide service or take services</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Job poster cancel the request with a valid reason</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Job poster or Service provider do attempt Fraud or Defraud, Money laundering, Unethical Behaviour and Data breach/ theft</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>If the service provider is less than 16 years old</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>You are in a jurisdiction (or citizen of a jurisdiction) that makes the provision of Our Services to You or Your use of them is illegal</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>You are violating the terms and conditions of GigFWS</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Upon User account termination or suspension as per Terms and conditions of GigFWS</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Copyright Infringement, Trademark Infringement, and violation of a third party's terms of service reported through FWS’s Intellectual Property Claims Policy</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Adult oriented services, Pornographic, Inappropriate/Obscene</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Spam, Nonsense, or Violent content</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Misleading the users</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Any activities violating the terms and conditions or prejudicial to the interest of GigFWS</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Any activities found which might bring disrepute to GigFWS</span></li>
         </ul>
        
         <h4>Payment terms</h4>
         
         <span style={customStyles}>GigFWS payment helps create transparency on the platform. It is a shared account, funded by the Job poster before starting any project work. When GigFWS payment is funded, the Service provider feels confident that funds are available and the Job poster feels secure that Project work can be reviewed to the satisfactory before making/ releasing the payment.</span>
         
  
         
         <span style={customStyles}>Funds in GigFWS payment aren’t tied to one specific job or task but to a particular GigFWS user account and can be used to make payments for any work done with respect to the job – pay for tasks, milestones. Funds can be released to either the job poster or the Service Provider upon agreement. In case of a disagreement, either party can request for Arbitration (Online Dispute Resolution system), as long as funds are available in GigFWS Payment.</span>
         
  
         
         <span style={customStyles}>GigFWS will charge </span>
         
  
         
         <h4>Refund</h4>
         
         <span style={customStyles}>GigFWS user can claim for a refund under the following circumstances:</span>
         
  
         
         <h4>Full Refund</h4>
         <ul>
          <li style={customStyles} aria-level="1"><span style={customStyles}>No response: the service provider has not responded</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Non-delivery of the service after the work acceptance</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>The Service provider lost a dispute</span></li>
         </ul>
         <h4>Partial Refund</h4>
         <ul>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Job poster and Service provider may mutually agree to a refund for reasons other than the previous clause however authorization of such refunds will be subject to a review by GigFWS</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>If project is not started or 20% completed, full or partial (after deducting 2% platform processing charges) refund will be made</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>If project is 60% or above completed, refund claim is subject to GigFWS team approval</span></li>
         </ul>
  
         <h4>No Refund</h4>
  
         <ul>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Refund claims for cancellation of jobs/ contracts depends on GigFWS user service quality (Jobs/ contracts are not eligible to be cancelled based on the quality of service/materials delivered by the service provider. Job poster may rate their experience with the service provider on the application, including the overall level of service quality received)</span></li>
         </ul>
        
       <h4>General Terms</h4>
        
        
         <ul>
          <li aria-level="1"><span style={customStyles}>Only registered users may buy and sell services on the application</span></li>
         </ul>
  
         <ul>
          <li style={customStyles} aria-level="1"><span style={customStyles}>GigFWS does not guarantee a job/work/ project, but is a platform through which service provider can showcase their talents/ skills to job poster and job poster can choose/select the service provider to award Contract/ jobs/ work/ project.</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>GigFWS users must complete his e-KYC (document &amp; bank verification) in order to receive funds in his/her bank account and avail FWS’s full service in the application.</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Job poster have to pay the project amount upfront (ESCROW ACCOUNT) including taxes in order to fully avail the services from the service provider</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Amount paid by the job poster will remain with GigFWS and will be transferred to service provider as per the payment terms agreed by both the parties involved as per the contract</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Service provider must fulfil their contract and complete the jobs as agreed, and may not cancel contract/ projects on a regular basis or without any valid reasons. Cancelling the projects/ contracts will affect service provider's reputation, earnings and ratings. Service provider may also withstand monetary penalty in any such absence</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>GigFWS users shouldn’t accept payments directly except via GigFWS application. GigFWS is not responsible for any such direct payments, if it happens out side the application and cause any trouble to the parties involved</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Job poster are granted all rights for the delivered work, unless otherwise specified by the service provider on their Scope of work/ contract. Note: some projects/ Jobs/ work may charge additional payments as per the nature of the project/ contract. However, it needs to be communicated and agreed by both the parties (here in job poster and service provider) upfront before starting the job/ Contract/ project work</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>GigFWS reserves the rights to use published delivered work contracts for the application promotional activities without any additional permission from the service provider and job poster</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Job poster has to respond to the proposal received from service provider within 7 days</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Charges will be applicable for delays when the project/ contract is not getting completed as per the scope of work/ contract agreed by job poster and service provide. However, job delayed due to any natural calamities, external environmental change factors due to nature, GigFWS operations, Government rules etc are exceptional</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Job poster and service provider can cancel the orders with a valid reason. Any refund request will be reviewed carefully and provisioned only when both the parties are agreed</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>Jobs/ contracts are not eligible to be cancelled based on the quality of service/materials delivered by the service provider. Job poster may rate their experience with the service provider on the application, including the overall level of service quality received. On special case by case scenario, provision of refund claims will be enabled by GigFWS, if the GigFWS user experience is not up to the mark set by the GigFWS. Customer value is the first priority for us</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>GigFWS has no obligation to check whether users are using the Services in accordance with the User Agreement, Job contracts/ scope of works</span></li>
          <li style={customStyles} aria-level="1"><span style={customStyles}>GigFWS shall have the right to request additional documents and additional information from you for the purpose of Your registration on the application and at the time when additional verification required for You to use GigFWS services, Cash Services and solving disputes</span></li>
         </ul>
        
         <h4>GOVERNING LAW &amp; JURISDICTION</h4>
  
         <span style={customStyles}>This “Terms and Conditions” along with your use of the application is governed by and construed in accordance with the laws of India.</span>
         
        
         <h4>Contact us</h4>
  
        <span style={customStyles}>If you have any questions or concerns regarding the Terms of Use or for any technical support on the application, please contact us at </span><a href="mailto:support@gigfws.com"><span style={customStyles}>support@gigfws.com</span></a><span style={customStyles}>.</span>
      </div>
    </section>
  
      );
}

export default TermsAndConditions;

