import axios from "axios";
import { addToken } from "../APIKit";
import { API_URL } from "../constants";

import APIKit from "../APIKit";

function login(payload) {
  console.log("payload===========" + JSON.stringify(payload));

  return new Promise((resolve, reject) => {
    console.log("payload===========" + JSON.stringify(payload));

    APIKit.post("user_login", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));
          console.log(JSON.stringify(response.data));
          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err=====+======", e);
          reject(e);
        }
      })
      .catch((err) => {
        console.log("err=====++======", err);
        reject(err);
      });
  });
}
function register(payload) {
  console.log("payload===========" + JSON.stringify(payload));

  return new Promise((resolve, reject) => {
    console.log("payload===========" + JSON.stringify(payload));

    APIKit.post("/user_register", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));
          console.log(JSON.stringify(response.data));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}
function socialLogin(payload) {
  console.log("payload===========" + JSON.stringify(payload));

  return new Promise((resolve, reject) => {
    console.log("payload===========" + JSON.stringify(payload));

    APIKit.post("/social_login", payload)
      .then(async (response) => {
        console.log("response===========" + response);

        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));
          console.log(JSON.stringify(response.data));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err====++=======" + err);

        reject(err);
      });
  });
}
function socialRegister(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      // const userStorage = await getAuthAsyncStorage();
      // if (userStorage.token) {
      //   payload.append("auth_token", userStorage.token);
      // } else {
      // }
      console.log("success===========" + JSON.stringify(payload));
    } catch (e) {
      console.log("failed===========" + e);
    }
    APIKit.post("/social_register", payload)
      .then(async (response) => {
        try {
          console.log(JSON.stringify(response));
          console.log(JSON.stringify(response.data));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function mailVerification(payload) {
  console.log("payload===========" + JSON.stringify(payload));

  return new Promise((resolve, reject) => {
    console.log("payload======verify_email=====" + JSON.stringify(payload));

    APIKit.post("/verify_email", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function phoneVerification(payload) {
  console.log("payload====verify_mobile=======" + JSON.stringify(payload));

  return new Promise((resolve, reject) => {
    console.log("payload===========" + JSON.stringify(payload));

    APIKit.post("/verify_mobile", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function resendMailOTP(payload) {
  console.log("payload=====send_otp_email======" + JSON.stringify(payload));

  return new Promise((resolve, reject) => {
    console.log("payload===========" + JSON.stringify(payload));

    APIKit.post("/send_otp_email", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function resendPhoneOTP(payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise((resolve, reject) => {
    console.log("payload===========" + JSON.stringify(payload));

    APIKit.post("/send_otp_mobile", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function forgotPassword(payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise((resolve, reject) => {
    console.log("payload===========" + JSON.stringify(payload));

    APIKit.post("/forgotPassword", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

async function logout(getState) {
  return new Promise((resolve, reject) => {
    const currentState = getState();
    const { token } = currentState.auth;
    axios
      .get(`${API_URL}/logout`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then(async (response) => {
        resolve(response);
        // await resetAuthAsyncStorage();
      })
      .catch((err) => reject(err));
  });
}

export const userService = {
  login,
  logout,
  register,
  mailVerification,
  phoneVerification,
  resendMailOTP,
  resendPhoneOTP,
  forgotPassword,
  socialRegister,
  socialLogin,
};
