import React from "react";


const customStyles = {
  fontWeight: "400"
};
const About = () => {
    return (        
      <section class="trems-sec">
	    <div class="container">
		    <h3>About</h3>
            <p>GigFWS is an emerging gig economy talent marketplace platform in India, which enables any one to log in and create their gig user profile. Once the user profile and basic e-kyc is completed, the features are endless.</p>
            <p>As a gig, you can post new jobs as per your requirement and apply for job contracts as per your skills and location. Earnings have no boundaries here and no limitation of work to one employer. Disputes can be solved then and there in the app. The platform also enables you to connect with multiple other gig workers.</p>
            <p>What are you waiting for? Join us now and be part of the Future Workforces.</p>
	    </div> 
  </section>


      );
}

export default About;

