import ProfileUpdateView from "./ProfileUpdateView";
import React from "react";
import { connect } from "react-redux";

const Promise = global.Promise;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

class ProfileUpdate extends React.Component {
  componentWillMount() {
    // const tab = this.props.token ? "feed" : "all";
    // const articlesPromise = this.props.token
    //   ? agent.Articles.feed
    //   : agent.Articles.all;
    // this.props.onLoad(
    //   tab,
    //   articlesPromise,
    //   Promise.all([agent.Tags.getAll(), articlesPromise()])
    // );
  }

  componentWillUnmount() {
    //this.props.onUnload();
  }

  render() {
    return (
      <div className="home-page">
        <div className="container page">
          <div className="row">
            <ProfileUpdateView />

            {/* <div className="col-md-3">
              <div className="sidebar">

                <p>Popular Tags</p>

                <Tags
                  tags={this.props.tags}
                  onClickTag={this.props.onClickTag} />

              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate);
