import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { commonService } from "../../Network/services/commonService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const PaymentWithdrawView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [profile, setProfile] = useState({});
  const [name, setName] = useState("");
  const [invoiceList, setIvoiceList] = useState([]);
  const [walletAmount, setWalletAmount] = useState("");
  const [amount, setAmount] = useState("");

  const [loading, setLoading] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);

  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));

    setName(user.first_name + " " + user.last_name);
    let personal_preference_name1_array = user.personal_preference_name1.split(
      ","
    );
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const redirect = () => {
    history.push("/ExperienceUpdate");
  };
  const getWalletAmount = () => {
    setWalletLoading(true)
    const FormData = require("form-data");

    const form = new FormData();

    commonService
      .CommonService("wallet", form)
      .then(async (res) => {
        if (res.status == 200) {
          setWalletAmount(res.wallet)
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setWalletLoading(false)
      });
  };
  const getQuoteList = () => {
    setLoading(true);

    const FormData = require("form-data");

    const form = new FormData();

    commonService
      .CommonService("withdraw_payment_history", form)
      .then(async (res) => {
        if (res.status == 200) {
          setIvoiceList(res.data);
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);

      });
  };

  
  return (
    <section className="explore explore1">
        <div className="container">
          <div className="banner">
            <div className="row">
              <div className="col-md-4">
                <div className="breadcrumps">
                  <ul>
                    <li><a href="#" onClick={(e)=>{
                   e.preventDefault();
                   history.goBack();
                }}><i className="bx bx-chevron-left" /> Back</a></li>
                  </ul>
                </div>
              </div>
            
            </div>
          </div>
          <section className="onbord-section">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="title">Enter Amount to Withdraw</h1>
                <p className="subtitle" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="board-box board-box2 post-new-job">
                  <form>
                    <div className="form-group">
                      <label>Amount</label>
                      <input type="textbox" className="form-control" required="required" placeholder="Amount"  value={amount}
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }} />
                    </div>
                    <button onClick={()=>{
                      if(amount!=""){
                        history.push("/payout", { data: amount });

                      }
 
                    }}>SUBMIT</button> <button>CANCEL</button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentWithdrawView);
