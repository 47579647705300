import axios from "axios";
import APIKit from "../APIKit";

function CommonService(api, payload) {
  console.log("payload======send_otp_mobile=====" + JSON.stringify(payload));

  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }

    APIKit.post("/" + api, payload, {
      headers: {
        "content-type": "multipart/form-data", // do not forget this
      },
    })
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response.data.status));
          console.log("response======data=====" + response.data.status);

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

export const commonService = {
  CommonService,
};
