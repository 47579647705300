import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { commonService } from "../../Network/services/commonService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const DisputesView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [profile, setProfile] = useState({});
  const [name, setName] = useState("");
  const [openComplaints, setOpenComplaints] = useState([]);
  const [closedComplaints, setClosedComplaints] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openIssue, setOpenIssue] = useState(false);
  const [alljobId, setAllJobId] = useState([]);
  const [attachement, setAttachement] = useState("");
  const [attachementFileName, setattachementFileName] = useState("");
  const [fileValidtion, setFilevalidation] = useState("");
  const [jobIdValidation, setjobIdValidation] = useState('');
  const [issueValidation, setIssueValidation] = useState('');
  const [detailsValidation, setDetailsValidation] = useState('');
  const [jobId, setJobId] = useState('');
  const [issue, setIssue] = useState('');
  const [details, setDetails] = useState('');
  const [issueloader, setIssueLoader] = useState(false);
  const [openErrormsg, setOpenErrormsg] = useState("");
  const [closedErrormsg, setClosedErrormsg] = useState("");

  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));

    getOpenComplaints();
    setName(user.first_name + " " + user.last_name);
    let personal_preference_name1_array = user.personal_preference_name1.split(
      ","
    );
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };
  const onFilesChange = (files) => {
    let file = files[0];

    setAttachement({
      name: file.name,
      uri: file.preview.url,
      type: file.type,
    });
  };

  const handleFileSelect = (event) => {
    let ufile = event.target.files[0];
    setAttachement(ufile);
    setattachementFileName(ufile.name);
    
  }

  const onFilesError = (error, file) => {
    toast.success(error.message, {
      position: "top-right",
      type: "error",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });
  };
  const redirect = () => {
    history.push("/ExperienceUpdate");
  };
  
  const submitIssue = () => {
   
    console.log(jobId);
    if (jobId == '') {
      toast.success('Please select a JobId', {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
    if (issue == '') {
      toast.success('Please select an Issue', {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
    if (details == '') {
      toast.success('Please enter valid details', {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return;
    }
    setIssueLoader(true);
    const FormData = require("form-data");

    const form = new FormData();
    form.append('job_id',jobId);
    form.append('complaint',issue);
    form.append('details',details);

    if (attachement != '') {
    form.append('attach_file',attachement);
    }

    commonService
      .CommonService("raise_complaint", form)
      .then(async (res) => {
        if (res.status == 200) {
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          setOpenIssue(false);
          setJobId('');
          setIssue('');
          setDetails('');
          setAttachement("");
          setattachementFileName("");
          history.push({ 
            pathname: '/disputes'
           });
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setIssueLoader(false);

      });
    

  }

  const getAllJobId = () => {

    const FormData = require("form-data");

    const form = new FormData();

    commonService
      .CommonService("get_all_job_id", form)
      .then(async (res) => {
        if (res.status == 200) {
          setAllJobId(res.data);
        } else {
         
        }
      })
      .catch((err) => {
      
      })
      .finally(() => {
      });
  };
  const getOpenComplaints = () => {
    setLoading(true);

    const FormData = require("form-data");

    const form = new FormData();

    commonService
      .CommonService("open_complaint_history", form)
      .then(async (res) => {
        if (res.status == 200) {
          setOpenComplaints(res.data);
        } else {
           setOpenErrormsg(res.message)
        }
      })
      .catch((err) => {
        setOpenErrormsg("Something went wrong! please try again later!!")

      })
      .finally(() => {
        getClosedComplaints();
      });
  };

  const getClosedComplaints = () => {
    const FormData = require("form-data");

    const form = new FormData();

    commonService
      .CommonService("closed_complaint_history", form)
      .then(async (res) => {
        if (res.status == 200) {
          setClosedComplaints(res.data);
        } else {
          setClosedErrormsg(res.message)
        }
      })
      .catch((err) => {
        setClosedErrormsg("Something went wrong! please try again later!!")

      })
      .finally(() => {
        setLoading(false);
        getAllJobId();
      });
  };
  return (
    <section className="post contract">
        <div className="container">
          <div className="banner paddimg-bottom">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <div onClick={back}>
                      <a href="#">
                        <i className="bx bx-chevron-left" /> Back to all jobs
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <a href="#!" className="btn" onClick={()=>{
                setOpenIssue(true)
              }}>
              <img src="/assets/images/share.png" /> Raise an Issue 
              </a>
            </div>
          </div>
            
          </div>
          
          <div className="quotes">
            <div className="tabset">
              {/* Tab 1 */}
              <input type="radio" name="tabset" id="tab1" aria-controls="marzen" defaultChecked />
              <label htmlFor="tab1">Issue</label>
              {/* Tab 2 */}
              <input type="radio" name="tabset" id="tab2" aria-controls="rauchbier" />
              <label htmlFor="tab2">Closed</label>
              <div className="tab-panels">
                <section id="marzen" className="tab-panel all-jobs">
                  {/*----------------------- List Start  ---------------------*/}
                  {!openComplaints.size>0?(
                <h6 className="quotes-title paddimg-bottom">{openErrormsg}</h6>):null}
                  <LoadingOverlay>
                  {openComplaints.map((item) => (
                    <div className="post-list">
                      {/* <Link
                        to={{
                          pathname: `/myquote/${item.quote_id}/`,
                          state: { item },
                        }}
                      > */}
                    <div className="post-list-icon">
                      <img src="assets/images/post-list.png" />
                    </div>
                    <div className="count">
                      <ul>
                        {/* <li><img src="assets/images/job-id.png" class="job-id"> Disputes# <span>87536</span></li> */}
                        <li>
                          
                        Dispute#{" "}
                            <span>
                              {" "}
                              {item.dispute_id.substr(
                                item.dispute_id.indexOf(" ") + 1
                              )}
                            </span>
                          </li>
                        {/* <li class="line">Dispute# <span>JH87536</span></li> */}
                        {/* <li class="line">Posted <span>15 minutes ago</span></li> */}
                        {/* <li class="verified"><img src="assets/images/verified.png" class="verified"> Verified user by GigFWS</li> */}
                      </ul>
                    </div>
                    <h1>{item.job_title}</h1>
                    <div className="details">
                      <ul>
                        <li>Job awarded to <span>J{item.awarded_to_user_name}</span></li>
                        <li className="line font-normal">Requested for <span>{item.complaint}</span></li>
                        <li className="line font-normal">Issue Raised on <span>{item.created_at}</span></li>
                      </ul>
                    </div>
                    <div className="quoted">
                      <ul>
                        <li><a href="#" /><a href="#!" className="button">Open</a></li>
                        {/* <li className="eidt"><a href="#" className="message">View</a></li> */}
                      </ul>
                    </div>
                    {/* </Link> */}
                  </div>
                  ))}
                  {/*----------------------- List End  ---------------------*/}
                  <Loader loading={loading} />
                </LoadingOverlay>
                </section>
                <section id="rauchbier" className="tab-panel all-jobs">
                  {/*----------------------- List Start  ---------------------*/}
                  {!closedComplaints.size>0?(
                <h6 className="quotes-title paddimg-bottom">{closedErrormsg}</h6>):null}
                  <LoadingOverlay>
                  {closedComplaints.map((item) => (
                    <div className="post-list">
                      {/* <Link
                        to={{
                          pathname: `/myquote/${item.quote_id}/`,
                          state: { item },
                        }}
                      > */}
                    <div className="post-list-icon">
                      <img src="assets/images/post-list.png" />
                    </div>
                    <div className="count">
                      <ul>
                        {/* <li><img src="assets/images/job-id.png" class="job-id"> Disputes# <span>87536</span></li> */}
                        <li>
                          
                        Dispute#{" "}
                            <span>
                              {" "}
                              {item.dispute_id.substr(
                                item.dispute_id.indexOf(" ") + 1
                              )}
                            </span>
                          </li>
                        {/* <li class="line">Dispute# <span>JH87536</span></li> */}
                        {/* <li class="line">Posted <span>15 minutes ago</span></li> */}
                        {/* <li class="verified"><img src="assets/images/verified.png" class="verified"> Verified user by GigFWS</li> */}
                      </ul>
                    </div>
                    <h1>{item.job_title}</h1>
                    <div className="details">
                      <ul>
                        <li>Job awarded to <span>J{item.awarded_to_user_name}</span></li>
                        <li className="line font-normal">Requested for <span>{item.complaint}</span></li>
                        <li className="line font-normal">Issue Raised on <span>{item.created_at}</span></li>
                      </ul>
                    </div>
                    <div className="quoted">
                      <ul>
                        <li><a href="#" /><a href="#!" className="button danger">Closed</a></li>
                        {/* <li className="eidt"><a href="#" className="message">View</a></li> */}
                        </ul>
                        </div>
                    {/* </Link> */}
                  </div>
                  ))}
                  {/*----------------------- List End  ---------------------*/}
                  <Loader loading={loading} />
                </LoadingOverlay>
                  {/*----------------------- List End  ---------------------*/}
                </section>
              </div></div></div></div>
              <Modal
        isOpen={openIssue}
        onRequestClose={() => setOpenIssue(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
                  <LoadingOverlay>

        <div className="modal-dialog loginpopup" role="document">
        <div className="modal-content">
        <div className="loginpopup dashboard signin profile onbord-section" style={{padding: '0px'}}>
          <div className="modal-header">
            <h5 className="modal-title">Raise An Issue</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"  onClick={()=>{
              setOpenIssue(false)
            }} >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {/* <h2>Raise An Issue</h2> */}
            <form action="#">
              <div className="inner post-a-new-job">
              <div className="form-group">
                <Select
                    className="form-control"
                    options={alljobId}
                    placeholder="Invoice No / Jobs"
                    onChange={(values) => {
                      console.log(values);
                      setJobId(values[0].labell);
                    }}
                  />
                  {/* <select className="form-control">
                    <option selected="selected">Invoice No / Jobs</option>
                  </select> */}
                </div>
                <div className="form-group">
                <Select
                    className="form-control"
                    options={[
                      {
                        label: 'Cancel Project / Service',
                        value: 'Cancel Project / Service',
                      },
                      {label: 'Not satisfactory', value: 'Not satisfactory'},
                      {label: 'Not Completed', value: 'Not Completed'},
                      {label: 'Not Available', value: 'Not Available'},
                      {
                        label: 'Job provider didn’t turn up',
                        value: 'Job provider didn’t turn up',
                      },
                      {
                        label: 'Job poster didn’t respond',
                        value: 'Job poster didn’t respond',
                      },
                      {label: 'Payment related', value: 'Payment related'},
                    ]}
                    placeholder="Issue"
                    onChange={(values) => {
                      console.log(values);
                      setIssue(values[0].value);

                    }}
                  />
                  {/* <select className="form-control">
                    <option selected="selected">Invoice No / Jobs</option>
                  </select> */}
                </div>
               
                <div className="form-group">
                  <textarea name className="form-control" placeholder="Provide more details about the Job."   value={details}
                      onChange={(e) => {
                        setDetails(e.target.value);
                      }} />
                </div>
                <div className="form-group">
                  <label className="filelabel">
                    <i className="fa fa-paperclip" aria-hidden="true" />
                    <span className="title">
                      Add File
                    </span>
                    <input type="file" 
                    style={{ display: "none"}}
                    className="files-dropzone" 
                    onChange={handleFileSelect} 
                    onError={onFilesError}
                    accepts={["image/png", ".pdf"]}
                    multiple={false}
                    maxFileSize={1800000}
                    minFileSize={0}
                    />

                    {/* <Files
                    className="files-dropzone"
                    onChange={onFilesChange}
                    onError={onFilesError}
                    accepts={["image/png", ".pdf"]}
                    multiple={false}
                    maxFileSize={1800000}
                    minFileSize={0}
                    clickable
                  >
                 
                  </Files> */}
                  </label>
                  <label className="attche-ent-image-sec">{attachementFileName}</label>

                </div>
                <button  onClick={(e)=>{
                  e.preventDefault();
                  submitIssue()}} className="btn btn-default">SUBMIT</button>
              </div>
            </form>
          </div>
        </div>
      </div>
        </div>
        <Loader loading={issueloader} />
          </LoadingOverlay>
      </Modal>
              </section>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 1000 },
};
export default connect(mapStateToProps, mapDispatchToProps)(DisputesView);
