import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { JobService } from "../../Network/services/JobService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
import { usePlacesWidget } from "react-google-autocomplete";
import Geocode from "react-geocode";
import Modal from "react-modal";



const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const PostJobView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [scopefile, setScopefile] = useState({ name: "" });
  const [scopeValidation, setScopeValidation] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setselectedCategory] = useState("");
  const [budget, setBudget] = useState("");
  const [paymentterms, setPaymentterms] = useState("");
  const [jobtitle, setJobTitle] = useState("");
  const [about, setAbout] = useState("");
  const [joblocation, setLocation] = useState("");
  const [tags, setTags] = useState([]);
  const [targetDate, setTargeDate] = useState("");
  const [taketheWork, setTakethework] = useState("");
  const [addSkillstate, setAddSkillstate] = useState("");

  const [amount, setAmount] = useState("");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [jobtitleValidation, setJobTitleValidation] = useState("");
  const [selectedCategoryValidation, setselectedCategoryValidation] = useState(
    ""
  );
  const [locationValidation, setLocationValidation] = useState("");
  const [takeworkValidation, setTakeworkValidation] = useState("");
  const [budgetValidation, setBudgetValidation] = useState("");
  const [closedateValidation, setClosedateValidation] = useState("");
  const [paymenttermsValidation, setPaymenttermsValidation] = useState("");
  const [amountValidation, setAmountValidation] = useState("");
  const [skillsValidation, setSkillsValidation] = useState("");
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [pickerValue, setPickerValue] = useState(1);
  const [fileValidtion, setFilevalidation] = useState("");
  const [attachement, setAttachement] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [selected, setSelected] = useState("By Terms");



  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);
    user.categories.map((item) => {
      console.log("loop started", item);
      categoryData.push({
        label: item.category_name,
        value: item.category_name,
      });
      // setCategoryData([...categoryData,{label: item.category_name, value: item.category_name}])
    });
  }, []);

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey:"AIzaSyDhKv7Zr8AbpBwP84oMc7mRgcqMOjmI340",
    onPlaceSelected: (place) => {
      console.log(place);
      setLocation(place.formatted_address)
    }
  });
 const onCompletion = (e) => {
    e.preventDefault();
    setSelected("By Completion");
  };
  const onbyTerms = (e) => {
    e.preventDefault();
    setSelected("By Terms");
  };
  const onMilestone = (e) => {
    e.preventDefault();
    setSelected("By Milestone");
  };

  const getCurrentlocation = ()=>{

    if ( navigator.permissions && navigator.permissions.query) {


      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(getlatlang).catch(err => console.error("error: " + err));
      } else {
        alert("Sorry Not available!");
      }
  
    }else if (navigator.geolocation) {
    
      getlatlang();
      }


    if ("geolocation" in navigator) {
           console.log("Available");
         } else {
           console.log("Not Available");
         }

   
  };
  const getlatlang = () => {
    //then Navigation APIs
    console.log("position.coords.latitude");
    navigator.geolocation.getCurrentPosition(function (position) {
      //Your code here
      console.log("position.coords.latitude",position.coords.latitude);

     
      Geocode.setApiKey("AIzaSyDhKv7Zr8AbpBwP84oMc7mRgcqMOjmI340");

      // set response language. Defaults to english.
      Geocode.setLanguage("en");
      
      // set response region. Its optional.
      // A Geocoding request with region=es (Spain) will return the Spanish city.
      Geocode.setRegion("in");
      
      // set location_type filter . Its optional.
      // google geocoder returns more that one address for given lat/lng.
      // In some case we need one address as response for which google itself provides a location_type filter.
      // So we can easily parse the result for fetching address components
      // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
      // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
      Geocode.setLocationType("ROOFTOP");
      
      // Enable or disable logs. Its optional.
      Geocode.enableDebug();

      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city, state, country;
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  setLocation(city);
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          console.log(city, state, country);
          console.log(address);
          return city
        },
        (error) => {
          console.error(error);
        }
      );


  }, function (e) {
      //Your error handling here
      console.log("position.coords.latitude",e.message);

  }, {
      enableHighAccuracy: true
  });
  }
 

  function onFilesChange(event) {
    let file = event.target.files[0];

    console.log(event.target.files[0])
    if (file.size > 10e6) {
      //setFilevalidation("File Size issue");
      toast.success("File size must be less than 2MB", {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
      return;
    }
 setAttachement(event.target.files[0]);
  }



  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };
 

  const onFilesError = (error, file) => {
    console.log("file")

    setFilevalidation(error.message);
  };
  const handletagChange = (tags) => {
    setTags(tags);
  };
  const options = [
    { name: "Swedish", value: "sv" },
    { name: "English", value: "en" },
    {
      type: "group",
      name: "Group name",
      items: [{ name: "Spanish", value: "es" }],
    },
  ];
  const resetValidation = () => {
    setJobTitleValidation("");
    setselectedCategoryValidation("");
    setLocationValidation("");
    setTakeworkValidation("");
    setBudgetValidation("");
    setClosedateValidation("");
    setPaymenttermsValidation("");
    setAmountValidation("");
    setSkillsValidation("");
  };
  const submitquote = () => {
console.log(moment(new Date(targetDate)).format("DD-MM-yyyy"))
    let validationerrors = "no";
    setLoading(true);
    resetValidation();
    console.log(moment(new Date(targetDate)).format("MM-DD-yyyy"));
    if (jobtitle == "" && jobtitle.length <= 3) {
      setJobTitleValidation("Please Enter valid jobtitle");
      validationerrors = "yes";
      //return;
    }
    if (selectedCategory == "") {
      setselectedCategoryValidation("Please select a category");
      validationerrors = "yes";
      //return;
    }
    if (joblocation == "") {
      setLocationValidation("Please enter a valid location");
      validationerrors = "yes";
      //return;
    }
    if (taketheWork == "") {
      setTakeworkValidation("Please enter a valid Take the work");
      validationerrors = "yes";
      //return;
    }
    if (tags.length == 0) {
      setSkillsValidation("Please add valid skills");
      validationerrors = "yes";
      //return;
    }
    if (budget == "") {
      setBudgetValidation("Please select a budget");
      validationerrors = "yes";
      //return;
    }
    if (budget == "Fixed Price") {
      if (amount == "") {
        setAmountValidation("Please enter a valid amount");
        validationerrors = "yes";
        //return;
      }
    } else {
      if (fromAmount == "") {
        setAmountValidation("Please enter a valid range from amount");
        validationerrors = "yes";
        //return;
      }
      if (toAmount == "") {
        setAmountValidation("Please enter a valid range to amount");
        validationerrors = "yes";
        //return;
      }
      setAmount(fromAmount + " to " + toAmount);
      console.log(fromAmount + " to " + toAmount);
    }

    if (targetDate == "") {
      setClosedateValidation("Please select a Targeted close date");
      validationerrors = "yes";
      //return;
    }
    if (paymentterms == "") {
      setPaymenttermsValidation("Please select a Payment Terms");
      validationerrors = "yes";
      //return;
    }

    if(validationerrors == "yes"){
      setLoading(false);
      return;
    }

    const skillsString = tags.join(",");
    console.log("skillsString    ", skillsString);
    const form = new FormData();
    form.append("job_title", jobtitle);
    form.append("category", selectedCategory);
    form.append("job_description", about);
    form.append("job_location", joblocation);
    form.append("skills[]", skillsString);
    form.append(
      "take_work",
      moment(new Date(taketheWork)).format("DD-MM-yyyy")
    );
    form.append("budjet", budget);
    form.append(
      "target_closed_date",
      moment(new Date(targetDate)).format("MM-DD-yyyy")
    );
    form.append("payment_term", paymentterms);
    form.append("amount", budget == "Fixed Price"?amount:fromAmount + " to " + toAmount);

    //if (attachement.name != "") {
      form.append("attach_file", attachement);
   // }

    JobService.postjob(form)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          history.goBack();


        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="explore explore1">
      <LoadingOverlay>
        <div className="container">
          <div className="banner">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumps">
                  <ul>
                    <li>
                      <div onClick={back}>
                        <a href="#">
                          <i className="bx bx-chevron-left" /> Back to all jobs
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="explore-details post-new-job">
            <div className="row">
              <div className="col-md-7">
                <h1>Post a New Job</h1>
                <p>
                  Future Workforce Solutions needs the below information to help
                  you better.
                </p>
                <div className="form-group">
                  <input
                    type="text"
                    name
                    className="form-control"
                    required="required"
                    placeholder="*Job Title"
                    value={jobtitle}
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                    }}
                  />
                  <label className="error">{jobtitleValidation}</label>
                </div>
                <div className="form-group">
                  {/* <select className="form-control">
                    <option selected="selected">*Category</option>
                  </select> */}

                  <Select
                    className="form-control"
                    options={categoryData}
                    placeholder="Category"
                    onChange={(values) => setselectedCategory(values[0].value)}
                  />
                  <label className="error">{selectedCategoryValidation}</label>
                </div>
                <div className="form-group">
                  <textarea
                    name
                    className="form-control"
                    placeholder="Provide more details about the Job."
                    defaultValue={""}
                    value={about}
                    onChange={(e) => {
                      setAbout(e.target.value);
                    }}
                  />
                  <label className="error"></label>
                </div>
                <div className="form-group">
                  <input
                    ref={ref}
                    type="text"
                    name
                    className="form-control"
                    required="required"
                    placeholder="*Add Location"
                    value={joblocation}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                    <label className="location" onClick={
                            getCurrentlocation
                          }>
                            <i className="fa fa-map" aria-hidden="true" /> Use
                            Current Location
                          </label>
                  <label className="error">{locationValidation}</label>
                </div>
                <div className="form-group">
                  {/* <select className="form-control">
                    <option selected="selected">*Targeted Close Date</option>
                  </select> */}
                  <DatePicker
                    className="form-control"
                    selected={taketheWork}
                    minDate={new Date()}
                    onChange={(date) => setTakethework(date)}
                    placeholderText="*Take the work"
                  />
                </div>
                <div className="form-group">
                  {/* <select className="form-control">
                    <option selected="selected">*Budget</option>
                  </select> */}
                  <TagsInput
                    className="form-control"
                    value={tags}
                    placeholder="Add a Skill"
                    onChange={handletagChange}
                    inputProps={{
                      placeholder: "Add skill",
                    }}
                  />
                  <label className="error">{skillsValidation}</label>
                </div>
                <div className="form-group">
                  <Select
                    className="form-control"
                    options={[
                      { label: "Fixed Price", value: "Fixed Price" },
                      { label: "Price Range", value: "Price Range" },
                    ]}
                    placeholder="*Budget"
                    onChange={(values) => {
                      console.log(values);
                      setBudget(values[0].value);
                    }}
                  />
                  <label className="error">{budgetValidation}</label>
                </div>
                {budget == "Fixed Price" ? (
                  <div className="form-group">
                    <input
                      type="text"
                      name
                      className="form-control"
                      required="required"
                      placeholder="*Amount (INR)"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                    <label className="error">{amountValidation}</label>
                  </div>
                ) : (
                  <div className="form-group">
                    <input
                      type="text"
                      name
                      style={{
                        margin: "0 auto",
                        width: "50%",
                        float: "left",
                      }}
                      className="form-control"
                      required="required"
                      placeholder="*From Amount (INR)"
                      value={fromAmount}
                      onChange={(e) => {
                        setFromAmount(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      name
                      style={{
                        margin: "0 auto",
                        width: "50%",
                        float: "left",
                      }}
                      className="form-control"
                      required="required"
                      placeholder="*To Amount (INR)"
                      value={toAmount}
                      onChange={(e) => {
                        setToAmount(e.target.value);
                      }}
                    />
                    <label className="error">{amountValidation}</label>
                  </div>
                )}
                <div className="form-group">
                  {/* <select className="form-control">
                    <option selected="selected">*Targeted Close Date</option>
                  </select> */}
                  <DatePicker
                    className="form-control"
                    selected={targetDate}
                    minDate ={taketheWork}
                    onChange={(date) => setTargeDate(date)}
                    placeholderText="*Targeted Close Date"
                  />
                  <label className="error">{closedateValidation}</label>
                </div>
                <div className="form-group">
                  <Select
                    className="form-control"
                    options={[
                      {
                        label: "GigFWS Term Payment",
                        value: "FWS Term Payment",
                      },
                      {
                        label: "Milestone Payment",
                        value: "Milestone Payment",
                      },
                      { label: "On Completion", value: "On Completion" },
                    ]}
                    placeholder="*Payment Terms"
                    onChange={(values) => {
                      console.log(values);
                      setOpenPopup(true)
                      setPaymentterms(values[0].value);
                    }}
                  />
                  <label className="error">{paymenttermsValidation}</label>
                </div>

                <div className="form-group">
                 
                    <label className="filelabel">
                      <i className="fa fa-paperclip" aria-hidden="true" />
                      <span className="title">{attachement.name==null?"Add File":attachement.name}</span>
                      <input
                        className="FileUpload1"
                        id="FileInput"
                        name="booking_attachment"
                        type="file"
                        onChange={onFilesChange}
                        onError={onFilesError}
                        accept="image/*,.pdf"
                        accepts={["image/png", "image/jpg",".pdf"]}
                        maxFileSize={1800000}
                        minFileSize={0}
                        clickable
                  
                      />
                    </label>
                    {/* <label className="error">{attachement.name}</label> */}

                  <label className="error">{fileValidtion}</label>
                </div>
                <button onClick={submitquote}>Post Job</button>
              </div>
              <div className="col-md-5"></div>
            </div>
          </div>
        </div>
        <Loader loading={loading} />
      </LoadingOverlay>
      <Modal
        isOpen={openPopup}
        onRequestClose={() => setOpenPopup(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
        <div className="modal-dialog loginpopup" role="document">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <h5 className="modal-title">
                Payment Terms
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setOpenPopup(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
              <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\tul.payment-terms li{\n\t\tdisplay: inline-block;\n\t}\n\n\t ul.payment-terms li a {\n    border: 1px solid #CBCBCB;\n    height: 135px;\n    display: block;\n    padding: 20px 10px;\n    text-align: center;\n    border-radius: 10px;\n    width: 130px;\n}\n\nul.payment-terms li a span {\n    display: block;\n    font-size: 14px;\n    color: #434343;\n    margin: 8px 0 0;\n}\n\n.payemnt-terms-sec p {\n    font-size: 14px;\n    font: normal normal medium 14px/25px Poppins;\nletter-spacing: 0px;\ncolor: #434343;\nopacity: 1;\n}\n\nul.payment-terms {\n    text-align: center;\n}\n.payemnt-terms-sec button {\n    background: #FC5200 0% 0% no-repeat padding-box;\n    border-radius: 4px;\n    opacity: 1;\n    border: 0px;\n    color: #fff;\n    padding: 8px 30px;\n    margin: 30px 0px\n}\n\n" }} />
        <div className="modal-body payemnt-terms-sec">
          <div className="col-md-12">
            <ul className="payment-terms ">
              <li>
              <Link
                        to="#"
                        style={{
                          borderColor:
                            selected === "By Terms" ? "#F85100" : "#cbcbcb",
                        }}
                        onClick={onbyTerms}
                      ><img src="/assets/images/terms.png" /><span>By FWS Term</span></Link>
              </li>
              <li>
              <Link
                        to="#"
                        style={{
                          borderColor:
                            selected === "By Milestone" ? "#F85100" : "#cbcbcb",
                        }}
                        onClick={onMilestone}
                      ><img src="/assets/images/milestone.png" /><span>By Milestone</span></Link>
              </li>
              <li>
              <Link
                        to="#"
                        style={{
                          borderColor:
                            selected === "By Completion"
                              ? "#F85100"
                              : "#cbcbcb",
                        }}
                        onClick={onCompletion}
                      ><img src="/assets/images/completion.png" /><span>By Completion</span></Link>
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            {selected=="By Terms"?(<p>From the overall service price, you will prepay/ escrow 20% before
              starting the work, 60% once the work is started and 20% soon after
              the work is completed. Please note, we will pay the ‘job doer’
              only after receiving your confirmation in the regular intervals
              and once the required % of work is completed. Your payment will be
              safe with us and will be refunded if no work is performed.
            </p>):selected=="By Milestone"?
            (<p>Milestone based payments are customizable according to your need.
              You/ job doer can set the milestone and it’s payment by dividing
              the job into pieces or by period (weekly, monthly) or by any
              nature according to your need. You have to prepay/ escrow the set
              amount for each milestone to begin the work. The payment will be
              released to job doer once each milestone is completed. Your
              payment will be safe with us and will be refunded if no work is
              performed.</p>):(<p>You have to prepay/ escrow the agreed full amount and the same
              will be released to job doer once the job is completed. Your
              payment will be safe with us and will be refunded if no work is
              performed.</p>)}
            
          </div>
          <div className="col-md-12" style={{textAlign: 'center'}}>
            <button  onClick={() => setOpenPopup(false)}>Close</button>
          </div>
        </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 1000 },
};
export default connect(mapStateToProps, mapDispatchToProps)(PostJobView);
