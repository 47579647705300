import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  return (
    <div>
          <section className="popup-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 ">
            <ul className="nav">
              <li style={{display: 'block', width: '100%'}}><a href="/about" target="_blank">About US</a></li>
              <li style={{display: 'block', width: '100%'}}><a href="/terms-and-conditions" target="_blank">Terms and Condition</a></li>
              <li style={{display: 'block', width: '100%'}}><a href="/privacy-policy" target="_blank">Privacy Policy</a></li>
            </ul>
          </div>
          <div className="col-md-4 center">
            <p className="mail"><span style={{color: '#fff'}}>Call Us: <i className="fa fa-whatsapp" aria-hidden="true" /></span> <a href="tel:+9620400919">+91-9620400919</a></p>
            <p className="mail"><span>Mail Us: </span> <a href="mailto:support@gigfws.com">support@gigfws.com</a></p>
            <p className="mail" style={{top: '11px !important', position: 'relative'}}><span>Copyright GigFWS @ 2022. All Right Reserved </span></p>
          </div>
          <div className="col-md-4 ">
            <p className="social"><span>Address: </span>
              <a>34, A, Malaimedu, M.G.R Nagar, BHEL post, Ranipet 632406, Vellore, Tamilnadu </a>
            </p>
            <p className="social"><span>Follow Us: </span>
              <a href="https://www.facebook.com/GIGFWS/" target="_blank"><i className="fa fa-facebook" aria-hidden="true" /></a>
              <a href="https://twitter.com/gigfws" target="_blank"><i className="fa fa-twitter" aria-hidden="true" /></a>
              <a href="https://www.linkedin.com/company/gigfws" target="_blank"><i className="fa fa-linkedin-square" aria-hidden="true" /></a>
              <a href="https://www.youtube.com/channel/UCilBzr_1gIy8pF0pJFq2nsQ/featured" target="_blank"><i className="fa fa-youtube-play" aria-hidden="true" /></a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="go-top" onClick={scrollToTop}>
      <i className="flaticon-up-arrow" />
    </div>
      {/* <footer >
       
       <div className="footer-stripe" >
       <div className="container">
         <div className="row">
          
         <div className="col-md-5 footer-links">
           <ul>
       <li><a href="/business">For Business <span>|</span></a></li> <li><a href="/terms-and-conditions">Terms and Condition <span>|</span></a></li> <li><a href="/privacy-policy">Privacy Policy</a></li>
       </ul>
       </div>

       <div className="col-md-5 copy-rights">
         <p >Copyright GigFWS @ 2022. All Right Reserved</p>
       </div>
       <div className="col-md-2 socila-icons">
        <ul>
         <li>
<a href="https://www.facebook.com/GIGFWS/" target={"_blank"}> <img
                                   src="/assets/images/Group 3311.png"
                                   className="social-icons"
                                 /></a>
         </li>
         <li>
<a href="https://www.youtube.com/channel/UCilBzr_1gIy8pF0pJFq2nsQ/featured" target={"_blank"}> <img
                                   src="/assets/images/Group 3312.png"
                                   className="social-icons"
                                 /></a>
         </li>
         <li>
<a href="https://www.linkedin.com/company/74172662/" target={"_blank"}> <img
                                   src="/assets/images/Group 3314.png"
                                   className="social-icons"
                                 /></a>
         </li>
        </ul>
       </div>
       </div>
       </div>
       </div>
     </footer>
      <div className="go-top">
        <i className="flaticon-up-arrow" />
  </div>*/}
    </div>
  );
};

export default Footer;
