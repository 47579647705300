import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../../constants/actionTypes";
import { profileUpdateService } from "../../../Network/services/profileUpdateService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import ImageCropper from "../../ImageCropper";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import Modal from "react-modal";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";
const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const ProfileUpdateView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [nativeLocation, setNativeLocation] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [qualification, setQualification] = useState("10th or Below 10th");
  const [about, setAbout] = useState("");
  const [addSkillstate, setAddSkillstate] = useState("");
  const [skills, setSkills] = useState([]);
  const [addTSkillstate, setAddTSkillstate] = useState("");
  const [tskills, setTSkills] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("Male");
  const [webLink, setWebLink] = useState("");
  const [isProfilePicModelOpen, setIsProfilePicModelOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [profileImage, setProfileImage] = useState(undefined);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(
      "user====================" +
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);
    setProfileImage(
      user.profile_image == ""
        ? "assets/images/profile-upload.png"
        : user.profile_image
    );
    setAge(user.age);
    setNativeLocation(user.native_location);
    setCurrentLocation(user.location);
    setQualification(user.qualification);
    setAbout(user.about_description);
    let tempskills = user.skills;
    console.log(tempskills);

    console.log(tempskills.split(","));

    setTags(tempskills.split(","));
    setWebLink(user.web_link);
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };
  const handletagChange = (tags) => {
    setTags(tags);
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log(
        "croppedImage   ",
        URL.createObjectURL(event.target.files[0])
      );

      setImageToCrop(event.target.files[0]);
      setProfileImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "100%",
      background: "transparent",
    },
    overlay: { zIndex: 1000 },
  };
  let openProfilePicmodel = () => {
    setIsProfilePicModelOpen(true);
  };
  let closeProfilePicModel = () => {
    setIsProfilePicModelOpen(false);
  };
  let selectedPic = () => {
    setIsProfilePicModelOpen(false);
    var urlCreator = window.URL || window.webkitURL;
    var file = new File([croppedImage], "profile_picture.jpg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    console.log("croppedImage   ", file);
    setProfileImage(croppedImage);
  };
  const redirect = () => {
    history.push("/ExperienceUpdate");
  };
  const updateProfile = (e) => {
    e.preventDefault();
    setLoading(true);

    const skillsString = tags.join(",");
    console.log("skillsString    ", skillsString);
    const FormData = require("form-data");

    const form = new FormData();

    form.append("qualification", qualification);
    form.append("age", age);
    form.append("gender", gender);
    form.append("native_location", nativeLocation);
    form.append("skills[]", skillsString);
    form.append("about_description", about);
    form.append("web_link", webLink);

    form.append("location", currentLocation);
    var file = new File([imageToCrop], "profile_image.jpg", {
      lastModified: new Date().getTime(),
      webkitRelativePath: "",
      type: "image/jpeg",
    });
    // if (profileImage != "") {
    form.append("profile_image", imageToCrop);
    // }
    //form.append("profile_image", imageToCrop);
    console.log("fdfdfdfdfd", file);
    for (var pair of form.entries()) {
      console.log(pair[0] + ", ", pair[1]);
    }
    profileUpdateService
      .profileUpdatecall(form)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
          window.localStorage.setItem("auth_token", res.data[0].auth_token);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          redirect();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="explore explore1">
      <LoadingOverlay>
        <div className="container">
          <div className="banner">
            <div className="row">
              <div className="col-md-4">
                <div className="breadcrumps">
                  <ul>
                    <li>
                      <a href="#!">
                        <i className="bx bx-chevron-left" /> Back to all jobs
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <a href="#!" className="btn">
                  Share this Job <img src="assets/images/share.png" />
                </a>
              </div>
            </div>
          </div>
          <section className="onbord-section">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="title">
                  {name},tell us a bit more about yourself
                </h1>
                <p className="subtitle">
                  Future Workforce Solutions needs the below information to help
                  you better.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="board-box">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="profile-pic-wrapper">
                          <div
                            className="pic-holder"
                            //onClick={openProfilePicmodel}
                          >
                            {/* uploaded pic shown here */}

                            <img
                              id="profilePic"
                              className="pic"
                              src={profileImage}
                            />

                            <label className="upload-file-block">
                              <div className="text-center">
                                <div className="mb-2">
                                  <i className="fa fa-camera fa-2x" />
                                </div>
                                <div className="text-uppercase">
                                  <input
                                    className="uploadProfileInput"
                                    type="file"
                                    onChange={onImageChange}
                                    name="profile_pic"
                                    id="newProfilePhoto"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                  />
                                  Update <br /> Profile Photo
                                </div>
                              </div>
                            </label>
                          </div>
                          <p className="text-info text-center small">
                            Add Profile Photo
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name
                            className="form-control"
                            required="required"
                            placeholder="*Name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name
                            className="form-control"
                            placeholder="*Age"
                            value={age}
                            onChange={(e) => {
                              setAge(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <Select
                            className="form-control"
                            options={[
                              { label: "Male", value: "male", selected: true },
                              { label: "Female", value: "female" },
                              { label: "Transgender", value: "transgender" },
                              {
                                label: "Wish not to answer",
                                value: "Wish not to answer",
                              },
                            ]}
                            defaultValue={{ label: "Male", value: "male" }}
                            placeholder={gender}
                            onChange={(values) => {
                              console.log(values);
                              setGender(values[0].value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name
                            className="form-control"
                            placeholder="*Native Location"
                            value={nativeLocation}
                            onChange={(e) => {
                              setNativeLocation(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name
                            className="form-control"
                            placeholder="*Current Location"
                            value={currentLocation}
                            onChange={(e) => {
                              setCurrentLocation(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name
                            className="form-control"
                            placeholder="Weblink"
                            value={webLink}
                            onChange={(e) => {
                              setWebLink(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <Select
                            className="form-control"
                            options={[
                              {
                                label: "10th or Below 10th",
                                value: "10th or Below 10th",
                                selected: true,
                              },
                              { label: "12th Pass", value: "12th Pass" },
                              { label: "Diploma", value: "Diploma" },
                              { label: "Graduate", value: "Graduate" },
                              {
                                label: "Post Graduate",
                                value: "Post Graduate",
                              },
                              { label: "PhD", value: "PhD" },
                            ]}
                            placeholder={qualification}
                            onChange={(values) => {
                              console.log(values);
                              setQualification(values[0].value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          {/* <select className="form-control">
                    <option selected="selected">*Budget</option>
                  </select> */}
                          <TagsInput
                            className="form-control"
                            value={tags}
                            placeholder="Add a Skill"
                            onChange={handletagChange}
                            inputProps={{
                              placeholder: "skills",
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="textarea">About Yourself</label>
                          <textarea
                            name
                            className="form-control"
                            placeholder="Provide more details about the Job."
                            defaultValue={""}
                            value={about}
                            onChange={(e) => {
                              setAbout(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button onClick={updateProfile}>Post Job</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Modal
          isOpen={isProfilePicModelOpen}
          onRequestClose={closeProfilePicModel}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <div className="modal-dialog loginpopup" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Profile Picture Select</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeProfilePicModel}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="terms-condition">
                  <div className="col-md-4">
                    <a href="#!">
                      <label
                        htmlFor="newProfilePhoto"
                        className="upload-file-block"
                      >
                        <input
                          className="uploadProfileInput"
                          type="file"
                          onChange={onImageChange}
                          name="profile_pic"
                          id="newProfilePhoto"
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                        Select Picture
                        <img src="assets/images/attachement" />
                      </label>
                    </a>
                  </div>
                  <div>
                    <ImageCropper
                      imageToCrop={imageToCrop}
                      onImageCropped={(croppedImage) =>
                        setCroppedImage(croppedImage)
                      }
                    />
                  </div>
                  <button onClick={selectedPic}>Upload</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Loader loading={loading} />
      </LoadingOverlay>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdateView);
