import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { profileUpdateService } from "../../Network/services/profileUpdateService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";

import Modal from "react-modal";

//import images
import KycVerified from "../../assets/images/kyc-verified.png";
import { data } from "jquery";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const KycLanguage = (props) => {
  
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [demoVideo, setDemoVideo] = useState("");
  const [isChangePasswordModelOpen, setIsChangePasswordModelOpen] = useState(false);

  const [kycSubmitBtn,setKycSubmitBtn] = useState("ADD KYC")
  const [demoVideoList, setDemoVideoList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
    console.log(      
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);

    setLoading(true);
  
    const form = new FormData();  

    profileUpdateService
      .kycSampleVideo(form)
      .then(async (res) => {
        if (res.status == 200) {
          setDemoVideoList(res.data);
          console.log("=================== sample videos ====================");
          console.log(res.data);
          
        
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
 
  }, []);

  const getPreviewVideoUrl = videoUrl => {
    let vUrl = videoUrl; 
    vUrl = vUrl.replace("/watch", "/embed");
    return vUrl + "&autoplay=0&showinfo=0&controls=0&autohide=1";
  }

  const getVideoUrl = videoUrl => {
    let vUrl = videoUrl; 
    vUrl = vUrl.replace("/watch", "/embed");
    return vUrl;
  }

  const showDemoVideo = (videoUrl) => {   
    setLoading(true);

    let vUrl = videoUrl; 
    vUrl = vUrl.replace("/watch", "/embed");

    setDemoVideo(vUrl)
    setIsChangePasswordModelOpen(true);
    setLoading(false);

  };

  // const getKycSampleVideo = () => {   
  //   setLoading(true);

  //   const form = new FormData();  

  //   profileUpdateService
  //     .kycSampleVideo(form)
  //     .then(async (res) => {
  //       if (res.status == 200) {
             
  //         let vUrl = res.data[0].video_url; 
  //         vUrl = vUrl.replace("/watch", "/embed");
  //         setDemoVideo(vUrl)

  //         console.log("Demo video:"+ res.data[0].video_url)
  //         console.log("After updating video:"+ vUrl)
  //         setIsChangePasswordModelOpen(true);
                
  //       } else {
  //         toast.success(res.message, {
  //           position: "top-right",
  //           type: "error",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: 0,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       toast.success("Something went wrong! please try again later!!", {
  //         position: "top-right",
  //         type: "error",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: 0,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "100%",
      background: "transparent",
    },
    overlay: { zIndex: 1000 },
  };

  const videoPreviewWrapper = {
    position: "relative",
    paddingBottom: "56.25%",
    height: "0"
  };

  const videoPreviewWrapperIframe = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "11"
  };

  const videoOverlay = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "1111"
  }

  const videoWrapper = {
    position: "relative",
    paddingBottom: "56.25%",
    height: "0"
  };

  const videoWrapperIframe = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "999999999999"
  };
  
  let openChangePasswordmodel = () => {
    setIsChangePasswordModelOpen(true);
  };
  let closeChangePasswordmodel = () => {
    setIsChangePasswordModelOpen(false);
  };

  return (
    <section className="explore explore1">
    <LoadingOverlay>
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="#!" onClick={(e) => back(e)}>
                      <i className="bx bx-chevron-left" /> Back to Profile
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="onbord-section">
			
			<div className="row">
				<div className="col-md-12 text-center">
					<h1 className="title">Select language to preview demo video</h1>
					<p className="subtitle"></p>
				</div>
			</div>

			<div className="row">
				<div className="col-md-12">
					
					<div className="board-box board-box2 post-new-job">

						<form style={{textAlign : "center"}}>
							<a  data-toggle="modal" data-target="#videoselect">
								<img src={KycVerified} />
							</a>
              {/* <div className="form-group" style={{marginTop: "50px"}} >
                <a onClick={getKycSampleVideo}>English</a>
              </div>    */}
              {demoVideoList &&
                demoVideoList.map((post) => (
                              
                <div className="row" key={post.id} style={{marginTop: "50px"}}>
                  <div className="col-md-3">
                      
                      <div className="videoPreviewWrapper" style={videoPreviewWrapper} onClick={() => showDemoVideo(post.video_url)} >
                      <div style={videoOverlay}></div>
                        <iframe style={videoPreviewWrapperIframe} width="200" src={getPreviewVideoUrl(post.video_url)} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                      
                      </div>
                  </div>
                  <div className="col-md-8">                    
                      <a onClick={() => showDemoVideo(post.video_url)}>{post.language}</a>
                  </div>
                 
                </div>
                
              ))}                  
              <Link to="/verifyUser"><button>ADD KYC</button></Link>
						</form>


					</div>

				</div>
			</div>


      <Modal
          isOpen={isChangePasswordModelOpen}
          onRequestClose={closeChangePasswordmodel}
          style={customStyles}
          contentLabel="Example Modal"
          scrollable={true}
        >
          <div className="modal-dialog loginpopup" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">GigFWS demo video</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeChangePasswordmodel}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="terms-condition">
                  
                  <div className="videoWrapper" style={videoWrapper}>
                    <iframe style={videoWrapperIframe} width="560" height="349" src={demoVideo} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    
                    {/* <iframe width="900" height="506" src="https://www.youtube.com/embed/Ym0xd_GEDD4?list=PLLLnhvth0bFXNPrMNX_wMssFka6kn0QML" title="GigFWS KYC Demo_English" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                  </div>               
                  
                </div>
              </div>
            </div>
          </div>
        </Modal>

		</section>
      </div>
      <Loader loading={loading} />
    </LoadingOverlay>
    
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KycLanguage);
