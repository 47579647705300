import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { profileUpdateService } from "../../Network/services/profileUpdateService";
import { getConnectList, connectRequest } from "../../Network/services/connectService";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";

import Modal from "react-modal";

//import images and css
import "./connect.css";
import profileAvatar from "../../assets/images/profile-avatar.png";
import yourSelf from "../../assets/images/yourself.png";
import nounJob from "../../assets/images/noun_job.png";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const UserDetail = (props) => {
  
  let location = useLocation();
  let history = useHistory();
  const { id } = useParams();
 
  //console.log(location, " useLocation Hook");
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [connectDetails, setConnectDetails] = useState("");
  const [connectBtnName, setConnectBtnName] = useState("");
  
  useEffect(() => {
    window.scrollTo(0, 0)
    
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name); 
    
    setConnectBtnName (
      <a onClick={() => connectSubmit(id)} className="btn btn-default">Connect Now</a>
    )
    
    //================= get user detail ====================
    const getUserDetail = new FormData();
    getUserDetail.append( "user_id", id );
    let userInfo = "";
    profileUpdateService
    .profile(getUserDetail)
    .then(async (res) => {
      if (res.status == 200) {
        
        userInfo = res.data[0];
        // console.log("---------------- userinfo ----------------")
        // console.log(userInfo)
        if(userInfo.is_connected == "Connected"){
          setConnectBtnName (
            <a onClick={() => connectSubmit(userInfo.id)} className="btn btn-default">Connected</a>
          )
        } 
        setConnectDetails(userInfo);

      } else {
        
      }
    })
    .catch((err) => {
      toast.success("Something went wrong! please try again later!!", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    })
    .finally(() => {
      //setLoading(false);
    });
    //================= /get user detail ===================
     
 
  }, []);

    
  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  let connectSubmit = (connUserId) =>{
    console.log(connUserId);
    setLoading(true);  
  
    const connect_req_form = new FormData();
    connect_req_form.append('user_id', connUserId);

    connectRequest(connect_req_form)
    .then(async (res) => {
      if (res.status == 200) {
        console.log("result status:"+res.status);
       
        toast.success(res.message, {
          position: "top-right",
          type: "success",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });

        setConnectBtnName (
          <a onClick={() => connectSubmit(connectDetails.id)} className="btn btn-default">Connected</a>
        )

      } else {
        toast.success(res.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      }
    })
    .catch((err) => {
      toast.success("Something went wrong! please try again later!!", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    })
    .finally(() => {
      setLoading(false);
    });


  }

  return (
    <section className="profile">
    <LoadingOverlay>
      <div className="container">
        
        <div className="row">
          <div className="col-md-4">
            {/* <div className="breadcrumps">
              <ul>
                <li>
                  <a href="/connect" >
                    <i className="bx bx-chevron-left" /> Back to Connect List
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h1 className="title">GigFWS Connects</h1>
          </div>
        </div>

        <div className="profile-detail margin-left">
          <div className="row">

            <div className="col-md-1 padding-0">
              <a href="#!" className="profile-avatar photo">
                {connectDetails.profile_image ? (
                  <img src={connectDetails.profile_image} style={{width:"100px"}} width="50" height="50" />
                ) : (
                  <img src={profileAvatar} />
                )}
                {connectDetails.is_kyc_updated == "1" ? (
                  <span>
                    Verified by <span>GigFWS</span>
                  </span>
                ) : null}
                
                {/* <img src="assets/images/profile-avatar.png" /> */}
                {/* <span>Verified by <span>GigFWS</span></span> */}
              </a>
            </div>

            <div className="col-md-11 padding-0">
              <ul>
                <li><a href="#!">{connectDetails.first_name}</a></li>
                <li className="line"><a href="#!">{connectDetails.total_connects}<span>Connects</span></a></li>
                <li className="line"><a href="#!">{connectDetails.user_rating} out of 5<span>Review</span></a></li>
                <li>
                  { connectBtnName }
                
                </li>
                
              </ul>
            </div>

          </div>
        </div>

        <div className="profile-detail yorself">
          <div className="row">

            <div className="col-md-1 padding-0">
              <a href="#!" className="profile-avatar"><img src={yourSelf} /><span>About Yourself</span></a>
            </div>

            <div className="col-md-11 padding-0">
              <ul>
                <li><img src={nounJob} /><a href="#!">{connectDetails.skills},<span> {connectDetails.total_experience} Years Expert</span></a></li>
                <li className="line"><img src="assets/images/salary.png" /><a href="#!"><img src="assets/images/rupee.png" className="rupee" /> {connectDetails.amount_per_hour}<span>Per Hour</span></a></li>
                <li className="line"><img src="assets/images/arrows.png" /><a href="#!">{connectDetails.total_inprogress_project}<span>Projects In Progress</span></a></li>
                <li className="line"><img src="assets/images/start-up.png" /><a href="#!">{connectDetails.total_completed_project}<span>Projects Completed</span></a></li>
                
              </ul>
            </div>

          </div>
        </div>

        

        <div className="profile-detail qualification referance description">
          <div className="row">
            <div className="col-md-12"></div>
              <div className="courses-details-desc">
                <ul className="nav nav-tabs" id="myTab" role="tablist">

                <li className="nav-item">
                  <a className="nav-link active" id="overview-tab" data-bs-toggle="tab" href="#overview" role="tab" aria-controls="overview">Description</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="curriculum-tab" data-bs-toggle="tab" href="#curriculum" role="tab" aria-controls="curriculum">Qualification</a>
                </li>

                </ul>

                <div className="tab-content" id="myTabContent">

                  <div className="tab-pane fade show active" id="overview" role="tabpanel">
                  {connectDetails.about_description}
                  </div>

                  <div className="tab-pane fade" id="curriculum" role="tabpanel">
                  {connectDetails.qualification}
                  </div>

        
                  </div>

              </div>
          </div>
        </div>
        
        
       
      </div>
      <Loader loading={loading} />
    </LoadingOverlay>
    
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
