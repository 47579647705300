import axios from "axios";
import { API_URL } from "../constants";
import APIKit from "../APIKit";

function getJobs(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      payload.append("auth_token", window.localStorage.getItem("auth_token"));
    } catch (e) {
      console.log("failed===========" + e);
    }
    APIKit.post("/explore_jobs", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}
function cancelQuote(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = window.localStorage.getItem("auth_token");
      if (token) {
        payload.append("auth_token", token);
      } else {
      }
      console.log("success===========" + JSON.stringify(payload));
    } catch (e) {
      console.log("failed===========" + e);
    }
    APIKit.post("/cancel_quote", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}
function submitquote(payload, isupdate) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = window.localStorage.getItem("auth_token");
      if (token) {
        payload.append("auth_token", token);
      } else {
      }
      console.log("success===========" + JSON.stringify(payload));
    } catch (e) {
      console.log("failed===========" + e);
    }
    console.log(isupdate);
    APIKit.post(isupdate == true ? "/update_quote" : "/submit_quote", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

function postjob(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = window.localStorage.getItem("auth_token");
      if (token) {
        payload.append("auth_token", token);
      } else {
      }
      console.log("success===========" + JSON.stringify(payload));
    } catch (e) {
      console.log("failed===========" + e);
    }
    APIKit.post("/post_job", payload)
      .then(async (response) => {
        try {
          console.log("response===========" + response.status);
          //console.log("response==========="+response.data.password)
          console.log(JSON.stringify(response));

          // await setAuthAsyncStorage(response);
          resolve(response.data);
        } catch (e) {
          console.log("err===========" + e);

          reject(e);
        }
      })
      .catch((err) => {
        console.log("err===========" + err);

        reject(err);
      });
  });
}

export const JobService = {
  getJobs,
  submitquote,
  postjob,
  cancelQuote
};
