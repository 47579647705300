import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { profileUpdateService } from "../../Network/services/profileUpdateService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-tagsinput/react-tagsinput.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-dropdown-select";

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

const MyResumeEditView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  
  const [loading, setLoading] = useState(false);
  const { isOpen, setIsOpen } = useState(false);
  const [name, setName] = useState("");
  const [certificate, setCertificate] = useState();
  const [resume, setResume] = useState();

  const [certlist, setCertlist] = useState();
  const [resumelist, setResumelist] = useState();

  useEffect(() => {
    console.log(      
        JSON.parse(window.localStorage.getItem("UserData"))
    );
    let user = JSON.parse(window.localStorage.getItem("UserData"));
    setName(user.first_name + " " + user.last_name);
    setCertificate(
      user.certificates == ""
        ? ""
        : user.certificates
    );
    setResume(
      user.resume == ""
        ? ""
        : user.resume
    );
   
    let resumeFile = "";
    if(user.resume!==""){
      let resumeFilename = user.resume.split('/').pop();
      let resumeExt = resumeFilename.split('.').pop();

      if(resumeExt != "jpg" && resumeExt != "jpeg" && resumeExt != "jfif" && resumeExt != "pjpeg" && resumeExt != "pjp" && resumeExt != "png" && resumeExt != "svg" && resumeExt != "webp" && resumeExt != "tif" && resumeExt != "tiff"){
        resumeFile = (    
          <div className="indent mt-3">                 
             <a href={user.resume} target="_blank" ><i class="bi-globe"></i> {resumeFilename}</a>             
          </div>
        )
      }
      else{
        resumeFile = (    
          <div className="indent mt-3">   
            <a href={user.resume} target="_blank" >     
              <img              
                src={user.resume}
                width="200"
              />
            </a>
          </div>
        )
      }

    }
    setResumelist(resumeFile);

    //get certificates
    let certificates = "";
    if(user.certificates_upload!==""){
      console.log("----certificates_upload----")
      console.log(user.certificates_upload)
      certificates = user.certificates_upload.map((item, index) => 
        {
          let filename = item.certificate.split('/').pop();
          let ext = filename.split('.').pop();          

          if(ext != "jpg" && ext != "jpeg" && ext != "jfif" && ext != "pjpeg" && ext != "pjp" && ext != "png" && ext != "svg" && ext != "webp" && ext != "tif" && ext != "tiff"){
            
            return (    
              <div className="indent mt-3" key={index}>
                 <a href={item.certificate} target="_blank" ><i class="bi-globe"></i> {item.certificate.split('/').pop()}</a> 
                 <span style={{cursor:"pointer", color:"red"}} name={item.certificate} onClick={handleCertificateRemoveItem}> {item.certificate=="" ? "" : "x" } </span>
              </div>
            )
          }
          else{
            return (    
              <div className="indent mt-3" key={index}>  
                {/* <img src={item.certificate} width="100" />  */}
                <a href={item.certificate} target="_blank" >
                  <img
                    alt="that wanaka tree"
                    src={item.certificate}
                    width="200"
                  />
                </a>
                <span style={{cursor:"pointer", color:"red"}} name={item.certificate} onClick={handleCertificateRemoveItem}> {item.certificate=="" ? "" : "x" } </span>
              </div>
            )
          }

        }
       
      )
    } 
    setCertlist (certificates);
 console.log("===========certificates===========")
 console.log(certificates)
  }, []);

  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const profileupdate = (e) => {
    e.preventDefault();
    setLoading(true);
      

    const form = new FormData();
    form.append('certificates[]', certificate);
    form.append('resume', resume);

    profileUpdateService
      .profileUpdatecall(form)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
          window.localStorage.setItem("auth_token", res.data[0].auth_token);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          history.goBack();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const redirect = () => {
    history.push("/myresumeEdit");
  };

  function certificateChange(event) {
    setCertificate(event.target.files[0])
    console.log(event.target.files[0]);
  }

  function resumeChange(e) {
    setResume(e.target.files[0])
    console.log("resume file=============");
    console.log(e.target.files[0]);
  }

  
  //remove certificates and resume
  const handleCertificateRemoveItem = (e) => {
    const certName = e.target.getAttribute("name")
    setLoading(true);
      

    const form = new FormData();   
    form.append('certificate', certName);

    profileUpdateService
      .removeCertificate(form)
      .then(async (res) => {
        if (res.status == 200) {
          // await setAuthAsyncStorage(res);
          window.localStorage.setItem("UserData", JSON.stringify(res.data[0]));
          window.localStorage.setItem("auth_token", res.data[0].auth_token);
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          window.location.reload(false);
          // history.goBack();
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        toast.success("Something went wrong! please try again later!!", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });

   };

  return (
    <LoadingOverlay>
      <section className="explore explore1">
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <a href="#!" onClick={(e) => back(e)}>
                      <i className="bx bx-chevron-left" /> Back to Profile
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="onbord-section">
          <div className="row">
            <div className="col-md-12 text-center">
            <h1 className="title">{name}, tell us a bit more about yourself</h1>
            <p className="subtitle">GigFWS needs the below information to help you better.</p>
            </div>
          </div>
          <div className="row">
				<div className="col-md-12">
					
					<div className="board-box board-box2 post-new-job">

						<form>
              <div className="wrapper" style={{textAlign: "center"}}>
                  <div className="form-group" style={{borderBottom: "1px solid #dfdfdf",
paddingBottom: "50px"}}>
                      <label style={{marginBottom: "30px"}}>Certificates Upload (Optional)</label>
                      <input type="file" className="custom-file-input" onChange={certificateChange} />
                      {certlist}
                  </div>
                  <div className="form-group" style={{borderBottom: "1px solid #dfdfdf",
paddingBottom: "50px", borderBottom: "1px solid #dfdfdf",
paddingBottom: "50px"}}>
                      <label style={{marginBottom: "30px"}}>Resume Upload (Optional)</label>
                      <input type="file" className="custom-file-input" onChange={resumeChange} />                      
                      <div className="mt-3">
                        {resumelist}
                      </div>
                  </div>
                  <div className="col-md-12" style={{textAlign: "center", paddingTop: "40px"}}>
                  <button onClick={profileupdate} className="btn btn-default">SUBMIT</button>
                  </div>
              </div>

				        </form>

					</div>

				</div>
			</div>
        </section>
      </div>
    </section>
    <Loader loading={loading} />
    </LoadingOverlay>
    
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyResumeEditView);
