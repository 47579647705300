import React, { useEffect, useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { CHANGE_TAB } from "../../constants/actionTypes";
import { JobService } from "../../Network/services/JobService";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Files from "react-files";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { commonService } from "../../Network/services/commonService";
import Modal from "react-modal";
import GoogleMapReact from "google-map-react";
import NewWindow from 'react-new-window'
import PaymentScreen from "./PaymentScreen";
import { render, createPortal } from "react-dom";
import { Rating } from 'react-simple-star-rating'

const mapStateToProps = (state) => ({
  ...state.articleList,
  //tags: state.home.tags,
  //token: state.common.token,
});

const mapDispatchToProps = (dispatch) => ({
  onTabClick: (tab, pager, payload) =>
    dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});
const MarkerComponent = ({ text }) => (
  <div>
    <img style={{ height: 40, weight: 40 }} src="/assets/images/marker.png" />
  </div>
);

const AwardedJobDetailView = (props) => {
  // const [job, setJob] = useState(props.state);
  let location = useLocation();
  let history = useHistory();
  console.log("props++++++++++++++++++++++", location);
  const [loading, setLoading] = useState(false);
  const [ord_id, setOrd_id] = useState("");
  const [mapView, setMapView] = useState(false);
  const [isOpen, setOpenState] = useState(false);
  const open = useCallback(() => setOpenState(true));
  const close = useCallback(() => setOpenState(false));
  const [newWindow, setNewWindow] = useState();
  const [msgPopup, setMsgPopup] = useState(false);
  const [mobile, setMobile] = useState("");
  const [ratingView, setRatingView] = useState(false);
  const [rating, setRating] = useState(location.state.item.user_rating*20) // initial rating value
  var countdown;
  let check = ""

  useEffect(() => {}, []);
  let back = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const INTERVAL_DELAY = 1000;

  const startPaymentStatus = () => {
    countdown = setInterval(() => paymentStatus(), 2000);
  };

  const openInNewTab = (url) => {
     newWindow = window.open(url)
   // if (newWindow) newWindow.opener = null
  }

  const handleRating = (rate) => {
    console.log(rate/20)
    setRating(rate/20)
    // other logic
    setRatingView(false)
    ratingCall(rate/20);
   }

  const NewWindow = ({ children, close }) => {
    const newWindow1 = useMemo(() =>
      window.open(
        "about:blank",
        "newWin",
        `width=400,height=300,left=${window.screen.availWidth / 2 -
          200},top=${window.screen.availHeight / 2 - 150}`
      )
    );
    newWindow1.onbeforeunload = () => {
      close();
    };
    useEffect(() => () => newWindow1.close());
    return createPortal(children, newWindow1.document.body);
  };
  useEffect(() => {
    if (ord_id != "") {
     startPaymentStatus();
    }
  }, [ord_id]);
  // const path = useReactPath();
  // React.useEffect(() => {
  //   console.log("path changes" + path);
  // }, [path]);

  const ratingCall = (rate) => {
    setLoading(true);
    const form = new FormData();
    form.append("user_rating", rate);
    form.append("user_id", location.state.item.awarded_to_user_id);

    commonService
      .CommonService("leave_rating", form)
      .then(async (res) => {
        if (res.status == 200) {
          toast.success(res.message, {
            position: "top-right",
            type: "success",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        } else {
          

          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {

        toast.success(err.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const paymentStatus = () => {
    setLoading(true);
    const form = new FormData();
    form.append("order_id", ord_id);

    commonService
      .CommonService("escrow_payment_status", form)
      .then(async (res) => {
        if (res.status == 200) {
          if (res.payment_status == "SUCCESS") {
            clearInterval(countdown);
              newWindow.close();
            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
            history.goBack();

          } else if (res.payment_status == "PENDING" || res.payment_status == "NOT_ATTEMPTED") {
           
          
          } else {
              newWindow.close();
            clearInterval(countdown);
            history.goBack();


            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }

          // await setAuthAsyncStorage(res);
        } else {
            newWindow.close();
          clearInterval(countdown);
          history.goBack();

          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        clearInterval(countdown);

        toast.success(err.message, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateStatus = (id, milestone, status, amount) => {
    setLoading(true);
    const form = new FormData();
    form.append("milestone", milestone);
    form.append("milestone_status", status);
    form.append("job_id", id);
    if (amount != "") {
      form.append("amount", amount);
    }

    commonService
      .CommonService("mark_as_completed_new", form)
      .then(async (res) => {
        if (res.status == 200) {
          if (status == "Completed") {

            // ======== PK:old ======/
            // var newWindow = window.open(res.payment_link);
            // setNewWindow(newWindow)
            //  check= "b";
            // setOrd_id(res.order_id);
            //  back();
            // ======== /PK:old ======/
            // ======== PK:new ======/
            setOrd_id(res.order_id);
            const paymentSessionId = res.payment_link; 
            const cashfree = new window.Cashfree(paymentSessionId);
            cashfree.redirect();
            
            back();
            // ======== /PK:new ======/
         

            
           // newWindow = window.open(res.payment_link, '_blank').focus();
            // var thisIsAnObject = {id:res.order_id,url:res.payment_link};
            // const newWindow = window.open("/paymentScreen",'_blank')
            // newWindow.myVariable = thisIsAnObject;
             //open();
             //history.push("/paymentScreen",{id:res.order_id,url:res.payment_link})
            //openInNewTab(res.payment_link)
          } else {
            toast.success(res.message, {
              position: "top-right",
              type: "success",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }

          // await setAuthAsyncStorage(res);
        } else {
          toast.success(res.message, {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
      })
      .catch((err) => {
        // toast.success(err.message, {
        //   position: "top-right",
        //   type: "error",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: 0,
        // });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <section className="explore explore1">
      <div className="container">
        <div className="banner">
          <div className="row">
            <div className="col-md-4">
              <div className="breadcrumps">
                <ul>
                  <li>
                    <div onClick={back}>
                      <a href="#">
                        <i className="bx bx-chevron-left" /> Back to Jobs I posted
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              
            </div>
          </div>
        </div>
        <div className="explore-details">
          <div className="row">
            <div className="col-md-7">
              <p className="jobid">
                Job#{" "}
                <span>
                  {location.state.item.job_unique_id.substr(
                    location.state.item.job_unique_id.indexOf(" ") + 1
                  )}
                </span>
              </p>
              <ul className="list-one">
                <li>
                  <a href="#!">
                    {location.state.item.payment_term} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!" />
                  POSTED {location.state.item.timestamp} <span>|</span>
                </li>
                <li>
                  <a href="#!">
                  Targeted close Date {location.state.item.target_closed_date}{" "}
                    <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {" "}
                    {location.state.item.total_quote_count} Quotes Received
                  </a>
                </li>
              </ul>
              <h1 className="title">{location.state.item.job_title}</h1>
              <ul className="list-two">
                <li>
                  <a href="#!">
                    {location.state.item.job_location} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.item.payment_term} <span>|</span>
                  </a>
                </li>
                <li>
                  <a href="#!">
                    {location.state.item.budjet}{" "}
                    <img src="/assets/images/rupee.png" />{" "}
                    <span className="amount">{location.state.item.amount}</span>
                  </a>
                </li>
              </ul>
              <ul className="category-list">
                {location.state.item.skills.split(",").map((skill) => (
                  <li>
                    <a href="#">{skill}</a>
                  </li>
                ))}
               <li className="view-map">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setMapView(true);
                    }}
                  >
                    View Location on Map
                  </a>
                </li>
              </ul>
              <p>{location.state.item.job_description}</p>

              {/* <a href="#!" class="btn quote">Send Quote</a> */}
            </div>
            <div className="col-md-5">
              <section className="profile you-quoted share status">
                <LoadingOverlay>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 padding-0">
                        <h3>Job Status</h3>
                      </div>
                      {location.state.item.contact_flag == '1' ? (
                      <div className="col-md-6 padding-0">
                        <a href="#" className="message" onClick={(e)=>{
                          e.preventDefault();
                          setMobile(location.state.item.employee_mobile);
                          setMsgPopup(true);
                        }}>
                          <img src="/assets/images/message.png" /> Message
                        </a>
                      </div>  ) : null}
                    </div>

                    <div className="row">
          <div className="col-md-12 padding-0">
            <h3>Job Awarded to</h3>
          </div>
        </div>
        <div className="profile-detail yorself awarded">
          <div className="row">
            <div className="col-md-12 padding-0">
            {/* <Link to={`/user-detail/${location.state.item.awarded_to_user_id}`}></Link> */}
                <a href={`/user-detail/${location.state.item.awarded_to_user_id}`} className="profile-avatar">
              
                {location.state.item.profile_image!="" ? (
                    <img src={location.state.item.profile_image}  />
                  ) : (
                    <img src="/assets/images/profile-avatar.png" />
                  )}
                </a>
              <ul>
                <li>
                  <a href={`/user-detail/${location.state.item.awarded_to_user_id}`} >
                  <h3>{location.state.item.job_awarded_to}</h3>
                  </a>
                  <h6>{location.state.item.skills}</h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
       
<div className="text-right ratings">
        <h3 >Leave rating</h3> 
        <Rating onClick={handleRating} ratingValue={rating} /* Available Props */ /></div>
      </div>


                    <div className="profile-detail yorself">
                      <div className="row">
                        <div className="col-md-1 padding-0">
                          <a href="#!" className="profile-avatar">
                            <img src="/assets/images/term-payment.png" />
                          </a>
                        </div>
                        <div className="col-md-11 padding-0">
                          <ul>
                            <li>
                              <h6> {location.state.item.payment_term} </h6>
                              <h2>
                                <img
                                  src="/assets/images/rupee-blue.png"
                                  className="rupee"
                                />{" "}
                                <span>
                                  {" "}
                                  {location.state.item.bid_amount} /-
                                </span>
                              </h2>
                              <p> {location.state.item.budjet} </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
     
                    {location.state.item.payment_milestone.map((value) => (
                      <div className="milestone-list">
                        <div className="row">
                          <div className="col-md-7 padding-0">
                            <h6>
                              {" "}
                              {location.state.item.payment_term ==
                              "By Milestone"
                                ? value.milestone_descriction
                                : value.name}
                            </h6>
                            <h2>
                              <img
                                src="/assets/images/rupee-blue.png"
                                className="rupee"
                              />{" "}
                              <span>{value.amount}/-</span>
                            </h2>
                            <p>{value.milestone_comment}</p>
                          </div>
                          <div className="col-md-5 padding-0">
                            <a
                              href="#"
                              className="btn"
                              onClick={(e) => {
                                e.preventDefault();
                                if (value.status == "Escrow") {
                                  updateStatus(
                                    location.state.item.id,
                                    value.name,
                                    "Completed",
                                    value.amount
                                  );
                                } else if (value.status == "Completed") {
                                  updateStatus(
                                    location.state.item.id,
                                    value.name,
                                    "Release Fund",
                                    value.amount
                                  );
                                } else if (value.status == "Release Fund") {
                                  updateStatus(
                                    location.state.item.id,
                                    value.name,
                                    "Payment Funded",
                                    value.amount
                                  );
                                }
                              }}
                            >
                              {value.status}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Loader loading={loading} />
                </LoadingOverlay>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={mapView}
        onRequestClose={() => setMapView(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
        <div className="modal-dialog loginpopup" role="document">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <h5 className="modal-title">
                View Location on Map
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setMapView(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <GoogleMapReact
                  style={{ height: 400 }}
                  bootstrapURLKeys={{
                    key: "AIzaSyDhKv7Zr8AbpBwP84oMc7mRgcqMOjmI340",
                  }}
                  defaultCenter={{
                    lat: parseFloat(location.state.item.latitude),
                    lng: parseFloat(location.state.item.longitude),
                  }}
                  defaultZoom={11}
                >
                  <MarkerComponent text="My Marker" />
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={msgPopup}
        onRequestClose={() => setMsgPopup(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
        <div className="modal-dialog loginpopup" role="document">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <h5 className="modal-title">
                   <span>GigFWS</span> 
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setMsgPopup(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body" style={{paddingBottom: '30px'}}>
        <h5 className="mobile-nuber-sec">It would be grateful if you reach me at <span>{mobile}</span> number.</h5>
      </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={ratingView}
        onRequestClose={() => setRatingView(false)}
        style={customStyles}
        contentLabel="Example Modal"
        scrollable={true}
      >
        <div className="modal-dialog loginpopup" role="document">
          <div className="modal-content">
            <div>
              <div className="modal-header">
                <h5 className="modal-title">
                   <span>Rating</span> 
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setRatingView(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body" style={{paddingBottom: '30px'}}>
              <Rating onClick={handleRating} ratingValue={rating} /* Available Props */ />
                    </div>
            </div>
          </div>
        </div>
      </Modal>
      {isOpen && (
        <NewWindow close={close}>
          Example <button onClick={close}>Close</button>
        </NewWindow>
      )}
    </section>
  );
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  overlay: { zIndex: 1000 },
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AwardedJobDetailView);
