import axios from "axios";

// Create axios client, pre-configured with baseURL
let APIKit = axios.create({
 // baseURL: "https://theprojectsdemo.com/PHP/FWS/api/",
  baseURL: "https://adm.gigfws.com/api/",
  timeout: 99999999999999999999,
});

APIKit.interceptors.request.use((request) => {
  console.log("request time", new Date().toLocaleString());

  console.log("Starting Request", JSON.stringify(request, null, 2));
  return request;
});

APIKit.interceptors.response.use((response) => {
  console.log("Response time", new Date().toLocaleString());

  console.log("Response:", JSON.stringify(response, null, 2));
  return response;
});

export default APIKit;
